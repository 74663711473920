import { SET_LEAD } from '../constants';

export function setLeadAction(value) {
  return {
    type: SET_LEAD,
    payload: {
      value,
    },
  };
}
