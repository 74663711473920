const replaceText = (text, appState, options = {}) => {
  let newText = text;
  const findCity = '{{$commonCity ?? \'\'}}';
  const findCity2 = '{{$commonCity ?? \'City\'}}';
  const regexCity = /\{\{\$commonCity \?\? \'\'\}\}/gi; // `/${findCity}/g`; // new RegExp(findCity, 'g');
  const regexCity2 = /\{\{\$commonCity \?\? \'City\'\}\}/gi; // `/${findCity2}/g`; // new RegExp(findCity2, 'g');
  const regexCity3 = /\{\{\$metaCity\}\}/gi;
  const regexCity4 = /\{\{\$city\}\}/gi;
  const regexBrand = /\{\{\$urlBrand\}\}/gi;
  const regexcarBrand = /\{\{\$brand\}\}/gi;
  const regexCarModel = /\{\{\$carDetails\}\}/gi;
  const regexModel = /\{\{\$model\}\}/gi;
  const regexFuel = /\{\{\$fuel\}\}/gi;
  const regexcarBrandModel = /\{\{\$carBrandModelDetails\}\}/gi;
  const regexcarBrandModelWithCar = /\{\{\$carBrandModelDetailsWithCar\}\}/gi;
  const regexcarBrandModelMlyWithCar = /\{\{\$carBrandModelDetailsMlyWithCar\}\}/gi;
  const regexStoreLocation = /\{\{\$storeLocation\}\}/gi;
  const regexStoreAddress = /\{\{\$storeAddress\}\}/gi;
  const regexAccCategory = /\{\{\$Category\}\}/gi;
  const regexAccCarBrand = /\{\{\$Brand\}\}/gi;
  const regexAccCarModel = /\{\{\$Model\}\}/gi;
  const regexServiceType = /\{\{\$service\}\}/gi;
  const regexSubZone = /\{\{\$subZone\}\}/gi;
  const regexService = /\{\{\$subService\}\}/gi;
  const regexSubZoneCity = /\{\{\$metaSubZoneCity\}\}/gi;

  if (
    options.cityReplace
    && options.cityReplace === 'region'
    && appState.selectedRegion
    && appState.selectedRegion.name
  ) {
    newText = newText
      .replace(regexCity, appState.selectedRegion.name)
      .replace(regexCity2, appState.selectedRegion.name)
      .replace(regexCity3, appState.selectedRegion.name);
  } else {
    newText = newText
      .replace(regexCity, appState.city.name)
      .replace(regexCity2, appState.city.name)
      .replace(regexCity3, appState.city.name === 'Kuala Lumpur' ? 'Kuala Lumpur (KL)' : appState.city.name);
  }
  if (options.category) {
    newText = newText
      .replace(regexAccCategory, options.category);
  }
  if (options.brand) {
    newText = newText
      .replace(regexAccCarBrand, options.brand);
  }
  if (options.model) {
    newText = newText
      .replace(regexAccCarModel, options.model);
  }
  newText = newText.replace(
    regexBrand,
    appState.cardetails.brand ? appState.cardetails.brand.name : 'Car',
  );
  newText = newText.replace(
    regexcarBrand,
    appState.cardetails.brand ? appState.cardetails.brand.name : 'Car',
  );
  newText = newText.replace(
    regexCarModel,
    appState.cardetails.model ? appState.cardetails.model.name : 'Car',
  );
  newText = newText.replace(
    regexModel,
    appState.cardetails.model ? appState.cardetails.model.name : 'Car',
  );
  newText = newText.replace(
    regexFuel,
    appState.cardetails.fuel ? appState.cardetails.fuel.name : 'Car',
  );
  newText = newText.replace(
    regexcarBrandModel,
      appState.cardetails.model ?  `${appState.cardetails.brand.name} ${appState.cardetails.model.name} `:
      appState.cardetails.brand ? appState.cardetails.brand.name : 'Car',
  );
  newText = newText.replace(
    regexcarBrandModelWithCar,
      appState.cardetails.fuel ? `${appState.cardetails.brand.name} ${appState.cardetails.model.name} ${appState.cardetails.fuel.name}` :
      appState.cardetails.model ?  `${appState.cardetails.brand.name} ${appState.cardetails.model.name} Car`:
      appState.cardetails.brand ? `${appState.cardetails.brand.name} Car` : 'Car',
  );
  newText = newText.replace(
    regexcarBrandModelMlyWithCar,
      appState.cardetails.fuel ? `${appState.cardetails.brand.name} ${appState.cardetails.model.name} ${appState.cardetails.fuel.name}` :
      appState.cardetails.model ?  `${appState.cardetails.brand.name} ${appState.cardetails.model.name} Car`:
      appState.cardetails.brand ? `${appState.cardetails.brand.name} Car` : 'Car',
  );
  newText = newText.replace(
    regexSubZoneCity,
      (appState.selectedRegion && appState.selectedRegion.name) ? `${appState.selectedRegion.name}, ${appState.city.name}` : 
      (appState.city && appState.city.name) ? appState.city.name : 'City',
  );
  newText = newText.replace(
    regexStoreLocation,
    appState.storeLocation ? appState.storeLocation : '',
  );
  newText = newText.replace(
    regexStoreAddress,
    appState.storeList && appState.storeList.address && appState.storeList.address_line_0?appState.storeList.address_line_0:''
  );
  newText = newText.replace(
    regexServiceType,
    (appState.serviceType && appState.serviceType.name) ? appState.serviceType.name : 'Service',
  );
  newText = newText.replace(
    regexSubZone,
    (appState.selectedRegion && appState.selectedRegion.name) ? appState.selectedRegion.name : 'Region',
  );
  newText = newText.replace(
    regexService,
    (appState.service && appState.service.name) ? appState.service.name : 'Service',
  );
  newText = newText.replace(
    regexCity4,
    (appState.city && appState.city.name) ? appState.city.name : 'City',
  );

  return newText;
};
export default replaceText;
