// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".KMHn0{background-color:#fff;padding:1rem;margin-bottom:5rem;margin-top:1rem}.KMHn0>div:first-child{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between}[data-faq-expanded=true]{margin-bottom:1rem;cursor:pointer}.KMHn0 h3{margin:0;font-weight:600;font-size:18px;font-size:1.125rem;line-height:24px;line-height:1.5rem;color:#1a1a1a}", ""]);
// Exports
exports.locals = {
	"faqWrapper": "KMHn0"
};
module.exports = exports;
