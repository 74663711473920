import React from 'react';
import { connect } from 'react-redux';
import { checkDisplayConfig } from '../../constants/displayConfigurations';

const DisplayWrapper = (props) => {
  const { section } = props;
  const { displayConfigurations } = props;
  if (!section || !displayConfigurations) {
    return <>{props.children}</>;
  }
  const display = checkDisplayConfig(section, displayConfigurations);
  if (display) {
    return <>{props.children}</>;
  }
  return <></>;
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DisplayWrapper);
