// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1AS4R{background:#fff;border:.5px solid rgba(0,0,0,.04);-webkit-box-shadow:0 1px 4px rgba(0,0,0,.15);box-shadow:0 1px 4px rgba(0,0,0,.15);border-radius:4px;padding:.5rem 1rem .5rem .5rem;display:-ms-flexbox;display:flex;grid-gap:8px;gap:8px;grid-gap:.5rem;gap:.5rem;-ms-flex-align:center;align-items:center;max-width:-webkit-fit-content;max-width:-moz-fit-content;max-width:fit-content;z-index:2;letter-spacing:.5px}._1AS4R ._2Xxnn{width:11.2px;width:.7rem;height:11.2px;height:.7rem;background-color:orange;border:4px solid #fff;border-radius:99px}._1AS4R span{font-weight:500;font-size:12px;font-size:.75rem;line-height:16px;line-height:1rem;color:#666}._3AS1l{background-color:orange!important}._1Zvgf{background-color:#5195e0!important}._2EIDY{background-color:#e73c33!important}", ""]);
// Exports
exports.locals = {
	"itemWrapper": "_1AS4R",
	"dot": "_2Xxnn",
	"warning": "_3AS1l",
	"good": "_1Zvgf",
	"danger": "_2EIDY"
};
module.exports = exports;
