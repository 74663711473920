/*
 Author : Rishabh Tyagi
 This component will contain the "Monsoon Service" section as in
  https://app.zeplin.io/project/5da0780780829f083448125a/screen/5da07e90a303922fd8070ede
*/
import React, { useState, useEffect } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { connect } from 'react-redux';
import s from './css/MonsoonResponsive.css';
import { getMonsoonService } from '../../../_services/api/monsoonServiceApi';
import BottomSlider from '../../InsuranceServicesHelpers/BottomSlider/BottomSlider';
import MonsoonServiceCarousel from './MonsoonServiceCarousel';

const MonsoonService = (props) => {
  useStyles(s);

  const [monsoonData, setMonsoonData] =useState({
    formAPI: false,
    data: props.home?.monsoonData,
  });

  const carExists = props.cardetails?.brand && props.cardetails?.model && props.cardetails?.fuel;

  useEffect(() => {
    if(!carExists) {
      // console.log("car is not set");
      setMonsoonData({
        formAPI: false,
        data: props.home?.monsoonData,
      });
      return;
    }
    
    if(carExists) {
      getMonsoonService(
        props.city?.id,
        props.cardetails?.fuel?.car_type_id,
      ).subscribe((res) => {
          if(res.data) {
            setMonsoonData({
              formAPI: true,
              data: res.data,
            });
          }
        }, (err) => {
          console.error(err);
        })
    }
    else {
      console.log("car is not setting")
    }
  }, [props.city, props.cardetails, props.user]);

  return (
  <>
    <BottomSlider
      popularServices = {1}
      scrollButtons
      alignItem={{alignItems: 'inherit'}}
      content={monsoonData?.data?.data?.map((item, index) => ( 
        <MonsoonServiceCarousel
          key={item.weather_service_name}
          articleImage={item.weather_service_url}
          popularServices={1}
          serviceText={item.weather_service_name}
          deeplink={item.deeplink}
          serviceData ={item}
          index={index}
        />    
      ))}
    />
  </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MonsoonService);
