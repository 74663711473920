// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3XPPQ{padding:.5rem 1rem;background-color:#fff;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;position:fixed;bottom:0;left:0;width:100%;-webkit-box-sizing:border-box;box-sizing:border-box}._3XPPQ>button{background-color:#e73c33;border-radius:5px;padding:1rem;border:none;width:100%}._3XPPQ>button>div{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;color:#fff;width:100%}._3XPPQ span{font-weight:600;font-size:16px;font-size:1rem;line-height:24px;line-height:1.5rem}.q8vvm{opacity:.7}", ""]);
// Exports
exports.locals = {
	"actionWrapper": "_3XPPQ",
	"disableBtn": "q8vvm"
};
module.exports = exports;
