// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2J8mJ{display:grid;grid-template-rows:auto;font-family:Gilroy,sans-serif;padding:15px}._10q-3{width:24px;height:24px;-o-object-fit:contain;object-fit:contain;margin-bottom:8px}._3t1VT{position:relative}.DPxck{display:grid;grid-template-rows:auto;font-family:Gilroy,sans-serif;margin:4px .625rem 0 0}._3kJOh{max-width:230px;max-width:14.375rem;min-width:230px;min-width:14.375rem}.gXmbo{width:278px;width:17.375rem;height:214px;height:13.375rem;border-radius:8px;cursor:pointer;-o-object-fit:cover;object-fit:cover}.imIE-{color:#000;font-size:16px;font-weight:600;line-height:1.54;letter-spacing:.34px;max-width:192px;max-width:12rem;min-width:144px;min-width:9rem}@media only screen and (min-width:769px){._10q-3{width:30px;height:30px}.imIE-{font-weight:600;font-size:18px}}", ""]);
// Exports
exports.locals = {
	"container": "_2J8mJ",
	"gridImage": "_10q-3",
	"positionRelative": "_3t1VT",
	"padding": "DPxck",
	"item": "_3kJOh",
	"popularServicesImage": "gXmbo",
	"guaranteeTitle": "imIE-"
};
module.exports = exports;
