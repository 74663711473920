import React, { useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { connect } from 'react-redux';
import ModalSelector from './ModalSelector';
import s from './ModalSelector.css';
import { slugify } from '../../utilities';
import generate from '../../serverRoutes/urlGenerators/newUrl';
import PlaceholderImage from '../PlaceholderImages/PlaceholderImage';
import defaultCarImage from './default_car.png';
import { deviceTypes } from '../../_services/device';

const CarBrandSelector = (props) => {
  const handleClick = () => {};

  const brandSelectionHandler = (brand) => {
    props.onBrandSelect(brand);
    props.goToNext();
  };
  useStyles(s);
  const [searchText, setSearchText] = useState('');
  const searchHandle = (e) => {
    setSearchText(e.target.value);
  };
  const search = (text) => text.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;

  // Show brands that are available while applying coupon for flipkart page, else available gomechanic brands
  const brandsData = props.isFlipkartScreen ? props.availableFlipkartCouponBrands : props.allBrands;
  return (
    <ModalSelector
      searchPlaceholder="Search Brands"
      onSearchChange={searchHandle}
      noOverlay={props.noOverlay}
      title="Select Manufacturer"
      hide={props.hide}
      backClickHandler={props.goToPrev}
      desktopCarSelection={props.desktopCarSelection}
      initialCarDetails={props.initialCarDetails}
      noBackButton={props.noBackButton}
      stopBackgroundScroll={props.device === deviceTypes.MOBILE}
      accessoriesCar={props.accessoriesCar}
    >
      {/* <ManufacturerSelectorComponent {...props} /> */}
      <>
        <div
          className={s.root}
          style={props.noOverlay && !props.fixedHeight ? { height: 'auto' } : {}}
        >
          {/* {console.log('hello all brands here ')} */}

          <div className={s.mainContainer}>
            {brandsData.filter((brand) => search(brand.name)).map((brand, idx) => (
              <a
                key={brand.id ?? idx}
                href={
                `${props.apiEndpoints.frontend_serverWithProtocol}${generate({
                  city: props.city,
                  subCity: props.selectedRegion || null,
                  serviceType: props.serviceType || props.servicesTypesList[0],
                  brand,
                })}`
              }
                style={{
                  textDecoration: 'none',
                }}
              >
                <div
                  className={s.childrenContainer}
                  style={props.noOverlay ? { marginBottom: '0' } : {}}
                  onClick={(e) => { e.preventDefault(); e.stopPropagation(); brandSelectionHandler(brand); }}
                  key={slugify(brand.name + brand.id)}
                >
                  <PlaceholderImage 
                    placeHolderImage={defaultCarImage} 
                    loading="lazy" 
                    src={ props.isNigerianRequest || props.isMalaysiaRequest ? brand.icon : 
                      `https://gomechprod.blob.core.windows.net/gm-retail-app/thumbnails/brand-${brand.id}.jpeg`
                    } 
                    className={s.childImage} 
                    alt={brand.name} 
                  />
                  {/* {console.log('brand here ', brand.name)} */}
                  <div className={s.childName}>{brand.name}</div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </>
    </ModalSelector>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CarBrandSelector);
