import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import s from './css/main.css';
import Map from './Map';
import noScrollBody from '../../../_services/bodyNoScrollForModals';

const WorkshopMapPopup = props => {
  useStyles(s);

  useEffect(() => {
    noScrollBody(false);
    return () => {
      noScrollBody(true);
    };
  }, []);

  return (
    <>
      <div onClick={props.hide} className={s.overlay} />
      <div className={classNames(s.modaldialog, s.modalheight)}>
        <div className={s.popBox}>
          <div className={s.flexBox}>
            <div className={s.heading}>
              GoMechanic Workshops Near You
            </div>
            <div className={s.closeIns}>
              <img src="/icons/ins-close.png" onClick={props.hide} />
            </div>
          </div>
          <Map 
            mapProps={props.workshopData} 
            city={props.city} 
            cardetails={props.cardetails}
            ervicesTypesList={props.servicesTypesList}
          />
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: action => dispatch(action),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkshopMapPopup);
