// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3z6DX{-webkit-box-sizing:border-box;box-sizing:border-box;background-color:#fff;padding:1rem;margin-top:1rem;font-weight:400}._3z6DX h3{font-weight:600;font-size:18px;font-size:1.125rem;line-height:24px;line-height:1.5rem;color:#1a1a1a;margin:0 0 1rem}._1yvQv{display:grid;grid-auto-columns:1fr;grid-auto-flow:column;background-color:#fef9f8;grid-gap:5px;gap:5px;margin-block:24px;margin-block:1.5rem}._2DaUr{background-color:#e73c33;color:hsla(0,0%,100%,.8980392156862745);border-radius:5px;-webkit-box-shadow:0 0 2px rgba(0,0,0,.14),0 2px 2px rgba(0,0,0,.12),0 1px 3px rgba(0,0,0,.2);box-shadow:0 0 2px rgba(0,0,0,.14),0 2px 2px rgba(0,0,0,.12),0 1px 3px rgba(0,0,0,.2)}._2DaUr,._3s6i4{text-align:center;padding:.5rem 0;font-weight:600;font-size:14px;font-size:.875rem;line-height:20px;line-height:1.25rem}._3s6i4{color:rgba(0,0,0,.6)}.c4JFe{width:100%}._3Sceu{border:1px solid #e6e6e6;border-radius:4px;width:100%}._2zVSA{border-bottom:1px solid #e6e6e6}._6m_G_,._2zVSA{padding:1rem;width:100%;-webkit-box-sizing:border-box;box-sizing:border-box}._6m_G_{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between}._3lqER{font-weight:600;font-size:18px;font-size:1.125rem;line-height:24px;line-height:1.5rem;color:#000}._3NCrH{font-size:14px;font-size:.875rem;line-height:20px;line-height:1.25rem}._3NCrH,.Ohwnq{margin-top:4px;color:#666}.Ohwnq{font-size:12px;font-size:.75rem;line-height:16px;line-height:1rem}._1Q6oe{width:80px}._3n66h{margin-top:1rem;font-size:14px;font-size:.875rem;line-height:16px;line-height:1rem;color:#666;font-weight:500}", ""]);
// Exports
exports.locals = {
	"coverageWrapper": "_3z6DX",
	"tabsContainer": "_1yvQv",
	"tabActive": "_2DaUr",
	"tabInactive": "_3s6i4",
	"activeCoverageComponent": "c4JFe",
	"activeCoverageInnerComponent": "_3Sceu",
	"upperBox": "_2zVSA",
	"lowerBox": "_6m_G_",
	"text1": "_3lqER",
	"text2": "_3NCrH",
	"text3": "Ohwnq",
	"carImage": "_1Q6oe",
	"validityText": "_3n66h"
};
module.exports = exports;
