import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import useStyle from 'isomorphic-style-loader/useStyles';
import css from './HeaderCart.css';

import cart from './cart.png';
import { getCartItemList } from '../../accessoriesUtility';
import { accessoriesCheckoutProgressEvent, viewAccCartGtmEvent } from '../../gtm/gtmServices';
import autoUtmHistory from '../../_services/autoUtmHistory';
import history from '../../history';
import { deviceTypes } from '../../_services/device';

const HeaderCart = (props) => {
  useStyle(css);

  const {
    userAccessoriesCart, device, shakeIcon, user, initialQueryString,
  } = props;

  const onCartIconClick = () => {
    if (userAccessoriesCart && userAccessoriesCart.order_lines
        && userAccessoriesCart.order_lines.length
    ) {
      const items = getCartItemList(userAccessoriesCart.order_lines);
      const value = userAccessoriesCart.total;
      accessoriesCheckoutProgressEvent({
        value, items, checkout_step: 1, option: 'Inititate Checkout', email: user.email, phoneNumber: user.mobile,
      });
      viewAccCartGtmEvent(
        user.id,
        items,
        value,
        'top',
        'Header',
      );
      autoUtmHistory(history).push(`/accessories-cart?${initialQueryString}`);
    }
  };

  const getCartCount = () => {
    let count = 0;
    if (userAccessoriesCart && userAccessoriesCart.order_lines && userAccessoriesCart.order_lines.length) {
      count = userAccessoriesCart.order_lines.reduce((qtyCount, item) => (item.free_item ? qtyCount : item.quantity + qtyCount), 0);
    }
    return count;
  };

  return (
    <div
      className={classnames(css.cartContainer, shakeIcon ? css.shakeIt : null)}
      onClick={onCartIconClick}
    >
      <div className={css.cartCount}>
        {getCartCount()}
      </div>
      <div className={css.cartIcon}>
        <img src={cart} alt="cart" />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderCart);
