/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { connect } from 'react-redux';
import styles from './NewTimeSelectorV2.css';
import { setOrderDateTime } from '../../actions/orderDateTimeAction';
import { dayNames } from '../../utilities';
import setToastConfig from '../../actions/setToastConfig';
import { setTimeSelect } from '../../actions/setTimeSelect';
import { dateGtmEvent, timeGtmEvent } from '../../gtm/gtmServices';

const NewTimeSelectorV2 = (props) => {
  useStyles(styles);

  const { orderDateTime, TimeSelect } = props;
  const setOrderDateTimeFn = (obj) => {
    props.action(setOrderDateTime(obj));
  };

  const datesToShow = props.slots && props.slots?.map(
      (slot) => ({
        date: new Date(slot.date).getDate(),
        day: dayNames[new Date(slot.date).getDay()],
        value: slot.date,
        timeSlots: slot.time_slots,
        is_date_enable: slot.is_date_enable,
        text: slot.text,
        meridiem_time_slots: slot.meridiem_time_slots ?? []
        // .filter((timeSlot) => timeSlot.is_enable)
        // ,
      }),
    );

  if (!orderDateTime.date || !orderDateTime.time) {
    return <></>;
  }

  const SlotLength = () => {
    const x = orderDateTime.timeSlots.length;
    return x;
  };

  return (
    <>
      <div className={styles.container}>
        <div className={`${styles.horizontalInfiniteDate}`}>
          {datesToShow && datesToShow?.map((i) => {
            if (orderDateTime.date === i.value) {
              return (
                <div style={{ marginRight: '0.875rem' }} className={`${styles.date} ${styles.dateSelected}`}>
                  <span>{i.date}</span>
                  <span>{i.day}</span>
                </div>
              );
            }

            return (
              <div style={{ marginRight: '0.875rem' }}>
                <div
                className={`${i.is_date_enable === false ? styles.disableDate : styles.date}`}
                onClick={() => {
                  if (!i.is_date_enable) {
                    props.action(setToastConfig({show: true, text: 'This date is completely Booked'}));
                    return;
                  }
                  // check if selected time exists for current selected date 
                  // console.log(i.timeSlots.filter((slot) => slot.is_enable));
                  props.action(setTimeSelect(null));
                  if (i.timeSlots.filter((slot) => slot.is_enable).reduce(
                    (acc, timeSlot) => (
                      orderDateTime.time === timeSlot.time ? true : acc), false, 
                  )) {
                    console.log('slot exists for this date ');
                    setOrderDateTimeFn({
                      ...orderDateTime,
                      date: i.value,
                      timeSlots: i.timeSlots,
                      meridiem_time_slots: i.meridiem_time_slots ?? [],
                      amount: i.timeSlots.filter(slot=>slot.is_enable).reduce(
                        (acc, timeSlot) => (
                          orderDateTime.time === timeSlot.time ? timeSlot.amount : acc), null,
                      ),
                    });
                  } else {
                    console.log('slot not exists for this date ');
                    setOrderDateTimeFn({
                      ...orderDateTime,
                      date: i.value,
                      meridiem_time_slots: i.meridiem_time_slots ?? [],
                      time: i.timeSlots.filter((slot) => slot.is_enable || !slot.is_enable)[0].time,
                      timeSlots: i.timeSlots,
                      amount: i.timeSlots.filter(slot=>slot.is_enable || !slot.is_enable)[0].amount || null,
                    });
                  }
                  dateGtmEvent(
                    props?.user?.id, i.value, props?.cardetails?.model?.id, props?.isAMCUser,
                  );
                }}
              >
                <span>{i.date}</span> 
                <span>{i.day}</span> 
              </div>
              
                {i.text && 
                <div className={styles.holidayText}>
                  {i.text}
                </div>
                }

              </div>
            );
          })}
        </div>

        <div className={styles.slotText}>
          Time Slot
          <span className={styles.slotCount}>({SlotLength()} slots available)</span>
        </div>
        {orderDateTime?.meridiem_time_slots ? 
        <div>
        {orderDateTime.meridiem_time_slots?.map((item, index) => {
          return (
            <>
              <div className={styles.slotTitle}>
                <img src={item?.icon} alt='slots' className={styles.slotIcon} />
                <span>{item?.title}</span>
              </div>
              <div className={styles.horizontalInfinite}>
                {item.slots.map(i => (
                  <div
                    onClick={() => {
                      if (!i.is_enable) { 
                        props.action(setToastConfig({show: true, text: 'This time slot is completely Booked'}));
                        return; 
                      } 
                      setOrderDateTimeFn({ ...orderDateTime, time: i.time, amount: i.amount }); 
                      props.action(setTimeSelect(i.time));
                      if(!props.source) {
                      const offSetTop = document.getElementById('payment-method-container').offsetTop;
                      setTimeout(() => {
                        window.scrollTo({
                          top: offSetTop - 50,
                          left: 0,
                          behavior: 'smooth',
                        });
                      }, 250);
                      timeGtmEvent(
                        props?.user?.id, props?.cardetails?.model?.id, i.time, item?.title, props?.isAMCUser
                      );
                    }}}
                    className=
                      {`${styles.time} 
                      ${TimeSelect === i.time ? 
                        styles.timeSelected 
                        : null}`
                      }
                  >
                    <span className={styles.slotIabel}>{i.label}</span>
                    {i.text ? 
                    <div
                    style={{ color: i.amount ? `${i.color_code}` : '#3A9B47', background: '#ebf5e7', width: '100%', paddingBlock: '4px'}}
                    className={styles.timeslotOfferText}
                  >
                    {i.text}
                  </div>
                  : null  
                  }
                    
                  </div>
                ))}
            </div>
            </>
          );
        })} 

        
      </div> 
      :
      <div className={styles.horizontalInfinite}>
      {orderDateTime.timeSlots.map((i, index) => {
          // if (orderDateTime.time === i.time) {
          //   return (
          //     <div className={`${styles.time} ${styles.timeSelected}`}>
          //       {i.label}
          //     </div>
          //   );
          // }
        return (
          <>
          <div
            onClick={() => {
              if (!i.is_enable) { 
                props.action(setToastConfig({show: true, text: 'This time slot is completely Booked'}));
                return; 
              } 
              setOrderDateTimeFn({ ...orderDateTime, time: i.time, amount: i.amount }); 
              props.action(setTimeSelect(i.time));
              if(!props.source) {
              const offSetTop = document.getElementById('payment-method-container').offsetTop;
              setTimeout(() => {
                window.scrollTo({
                  top: offSetTop - 50,
                  left: 0,
                  behavior: 'smooth',
                });
              }, 250);
              timeGtmEvent(
                props?.user?.id, props?.cardetails?.model?.id, i.time, i.label, props?.isAMCUser
              );
            }}}
            className=
              {`${styles.time} 
              ${TimeSelect === i.time ? 
                styles.timeSelected 
                : null}`
              }
          >
            {i.label}
            <div
              style={{color: i.amount ? `${i.color_code}` : '#3A9B47'}}
              className={styles.timeslotOfferText}
            >
              {i.text}
            </div>
          </div>
          </>
        );
      })} 

      
    </div>
      }
        

      </div>

    </>
  );
};
function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewTimeSelectorV2);
