/**
 * Author: Patanjali Kumar (patanjalikumar@gomechanic.in)
 * Supervision : Mr. Chandra Chowdhury <chandrachowdhury@gomechanic.in>
 *
 * Please try to read code thoroughly before contacting for any query.
 *
 * React
 * Zeplin URL : https://app.zeplin.io/project/5da0780780829f083448125a/screen/5da07a54d2d3af23439013ad
 *
 * This file contains code for CHECK PRICE form on homepage
 *
 * Copyright ©  2019-present GoMechanic, LLC. All rights reserved.
 *
 */

import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import s from './PcHomeForm.css';
import Rating from '../Rating/Rating';
import DesktopSideFloater from '../DesktopSideFloater/DesktopSideFloater';
import SelectorMain from '../ModalSelectors/SelectorMain';
import { getBrandName, getFuelName, getModelName } from '../../_services/carDetailGetters';
import CitySelectorDesktop from '../CitySelectorDesktop/CitySelectorDesktop';
import { setActiveServiceType } from '../../actions/serviceType';
import history from '../../history';
import generate from '../../serverRoutes/urlGenerators/newUrl';
import { createLeads } from '../../_services/api/leads';
import { setLeadAction } from '../../actions/lead';
import { retailSelectCarEvent, tapCheckPriceGtmEvent } from '../../gtm/gtmServices';
import { deviceTypes } from '../../_services/device';
import { cityIdsForMandatoryPhoneNum } from '../../utilities';
import setToastConfig from '../../actions/setToastConfig';
import { getQueryStringValue } from '../../utilities';
import { createEnquiryLead } from '../../_services/api/createEnquiryLead';
import autoUtmHistory from '../../_services/autoUtmHistory';

const PcHomeForm = (props) => {
  useStyles(s);
  const [carSelection, setCarSelection] = useState(false);
  const [inputs, setInputs] = useState({
    mobile: '',
  });
  useEffect(
    () => {
      if (props.user?.mobile) {
        setInputs({ ...inputs, mobile: props.user?.mobile });
      }
    },
    [props.user],
  );
  const [submitting, setSubmitting] = useState(false);
  const [citySelectorShow, setCitySelectorShow] = useState(false);


  const handleInputChange = (e) => {
    const temp = {};
    if(props.isMalaysiaRequest) {
      if (isNaN(event.target.value) || event.target.value.length > 11) { return false; }
    } else {
      if (isNaN(event.target.value) || event.target.value.length > 10) { return false; }
    } 
    temp[e.target.name] = e.target.value;
    setInputs({ ...inputs, ...temp });
  };

  const formSubmit = (e) => {
    if (isNaN(inputs.mobile) || inputs.mobile.length !== 10) {
    }
    setSubmitting(true);
    e.preventDefault();
  };

  const handleGetEnquiry = () => {
    let utm_events = {};
    let location = props.allCities?.find(city => city?.sitemap_name == props.params?.region);
    try {
      utm_events=props.initialQueryString?.split('&').map(item => item.split('='))
    }catch(err){
    }
    utm_events = Object.fromEntries(utm_events);
    let payload = {
      "utm_campaign": utm_events.utm_campaign ?? '',
      "utm_term": utm_events.utm_term ?? '',
      "gclid": utm_events.gclid ?? '',
      "utm_content": utm_events.utm_content ?? '',
      "utm_match": utm_events.utm_match ?? '',
      "utm_keyword": utm_events.utm_keyword ?? '',
      "utm_adgroup": utm_events.utm_adgroup ?? '',
      "utm_medium": utm_events.utm_medium ?? '',
      "utm_source": props.accessoriesEnquiryPageRender ? (utm_events.utm_source ?? 'accessories_enquiry') : "organic",
      "selected-car": (!props.cardetails?.brand?.name || !props.cardetails?.model?.name) ? null : `${props.cardetails?.brand?.name} ${props.cardetails?.model?.name} ${props.cardetails?.fuel?.name}`,
      "brand": props.cardetails?.brand?.name || "NA",
      "model": props.cardetails?.model?.name || "NA",
      "service": "Ask Service",
      "userName": "customer",
      "email": "NA",
      "mobileNo": inputs?.mobile,
      "location": location?.name ?? props.city?.sitemap_name,
      "location_id": location?.id ?? props.city?.id,
      "channel":"Website",
      "lead_type": props.accessoriesEnquiryPageRender ? 'accessories_retail_app' : "general",
      "brand_id": props.cardetails?.brand?.id ?? null,
      "model_id": props.cardetails?.model?.id ?? null,
      "fuel_id": props.cardetails?.fuel?.id ?? 1
    }
    setSubmitting(true);
    createEnquiryLead(payload).subscribe(res => {
      payload.lead_id = res?.data?.lead_id;
      payload.isFacebookUTM = payload?.utm_source?.substr(0,3) == 'fb_' ? 1 : 0;
      props.accessoriesEnquiryPageRender ? history.push('/accessories-thank-you',{payload}) : history.push('/enquiry-thank-you',{payload});
    }, err => console.log(err));
    return;
  }

  const handlerCheckPricesFree = () => {
    let utm_events = {};
    try {
      utm_events=props.initialQueryString?.split('&').map(item => item.split('='))
    } catch(err){
    }
    utm_events = Object.fromEntries(utm_events);
    const pagepath = window ? window.location.href : null;
    let payload = {
      "utm_campaign": utm_events.utm_campaign ?? '',
      "utm_term": utm_events.utm_term ?? '',
      "gclid": utm_events.gclid ?? '',
      "utm_content": utm_events.utm_content ?? '',
      "utm_match": utm_events.utm_match ?? '',
      "utm_keyword": utm_events.utm_keyword ?? '',
      "utm_adgroup": utm_events.utm_adgroup ?? '',
      "utm_medium": utm_events.utm_medium ?? '',
      "utm_source": utm_events.utm_source ?? '',
      "car_type_id": props.cardetails?.fuel?.car_type_id,
      "name": props.user?.name ||'customer',
      "mobile": inputs?.mobile,
      "location": props.city?.name,
      "channel":"Website",
      "source_id": 15,
      "utm_link": pagepath
    }

    if(submitting)
      return;
    if(props.isMalaysiaRequest) {
      if ((isNaN(inputs.mobile) || !(inputs.mobile.length == 9 || inputs.mobile.length == 10 || inputs.mobile.length == 11))) {
        props.action(setToastConfig({ show: true, text: 'Please enter your phone number.' }));
        return;
      }
    } else {
      if ((isNaN(inputs.mobile) || inputs.mobile.length !== 10)) {
        props.action(setToastConfig({ show: true, text: 'Please enter your phone number.' }));
        return;
      }
    }
    // create lead if current page is enquiry page and return
    if(props.enquiryPageRender || props.accessoriesEnquiryPageRender){
      if(!props.cardetails?.brand || !props.cardetails?.model || !props.cardetails?.fuel){
        props.action(setToastConfig({
          showTick: false,
          show: true,
          text: 'Please select your car.',
        }));
        return
      }
      handleGetEnquiry();
      return;
    }

    if (props.cardetails?.model && props.cardetails?.brand && props.cardetails?.fuel) {
      if(!props.storeLocator) {
        props.loader(true);
      }
      props.action(setActiveServiceType(props.servicesTypesList[0]));
      autoUtmHistory(history).push(
        generate({
          city: props.city,
          subCity: props.selectedRegion || null,
          serviceType: props.servicesTypesList[0],
          brand: props.cardetails?.brand,
          model: props.cardetails?.model,
          fuel: props.cardetails?.fuel,
        }),
      );
      if (!props.lead && inputs.mobile
        && !isNaN(inputs.mobile) && inputs.mobile.length === 10) {
        createLeads(payload).subscribe(
          (res) => {
            if (res.status) props.action(setLeadAction(res.data));
          }, console.error,
        );
      }
      tapCheckPriceGtmEvent(
        props?.user?.id, 'home page',
        props.cardetails?.fuel.name,
        props.cardetails?.model.name,
        props.cardetails?.model.id,
        props.cardetails?.brand.name,
        props.cardetails?.brand.id,
        inputs.mobile,
      );
    } else {
      alert('Please select your car.');
    }
  }

  return (
    <>
      {citySelectorShow && (props.device == deviceTypes.TABLET
      || props.device == deviceTypes.DESKTOP) ? (
        <CitySelectorDesktop hide={() => setCitySelectorShow(false)} storeLocator={props.storeLocator}/>
        ) : null}

      <DesktopSideFloater
        heightFitContent
        style={{
          gridColumnStart: 2,
          gridColumnEnd: 3,
          gridRowStart: 1,
          gridRowEnd: 4,
          marginBottom: '30px',
        }}
        isSideFloaterActive={props.storeLocator}
      >
        {carSelection ? (
          <div
            className={s.containerWhite}
            style={carSelection ? { padding: '24px', height: '400px' } : {}}
          >
            <SelectorMain hide={() => setCarSelection(false)} noOverlay />
          </div>
        ) : null}

        {!carSelection ? (
          <>
            <div className={s.containerWhite}>
              {props.enquiryPageRender || props.accessoriesEnquiryPageRender ? 
              <div className={s.iconList}>
                {props.iconList?.map((item, index) => <p key={index}>
                  <img src={item.icon} alt={item.name}/>
                  <span>{item.name}</span>
                </p>)}
              </div> :
              <>
                {
                props.storeLocator
                  ? <div className={s.heading}>The Best Car Services Await You</div>
                  : <h1 className={s.heading}>{`Experience The Best Car Services In ${props.selectedRegion !== null ? props.selectedRegion.name : ''} ${props.city?.name === 'Kuala Lumpur' ? 'Kuala Lumpur (KL)' : props.city?.name}`}
                      {/* <span className={s.cityName}> {props.city?.name}</span> */}
                    </h1>
              }
              {/* <h1 className={s.heading}>The Best Car Services Await You</h1> */}
              <div className={s.subHeading}>
                Get instant quotes for your car service
                {/* <span onClick={() => setCitySelectorShow(true)} className={s.cityName}>{props.city?.name}</span> */}
              </div> 
              </>  
            }
              <div className={classNames(s.containerBox, s.cursorPointer)} onClick={() => {setCitySelectorShow(true)}}>
                  <span>
                      {props.city?.name}
                    </span>
                    <img
                      loading="lazy"
                      src="/icons/ic_expand_more.svg"
                      alt="Select your car"
                      className={s.selectCarExpand}
                    />
              </div> 
              <div
                className={classNames(s.containerBox, s.cursorPointer)}
                onClick={() => {
                  retailSelectCarEvent(props?.user?.id, 'homepage');
                  setCarSelection(true);
                }}
              >
                <span>
                  {props.cardetails?.brand
                && props.cardetails?.model
                && props.cardetails?.fuel
                    ? `${getBrandName(props)} ${getModelName(props)}, ${getFuelName(props)}`
                    : 'SELECT YOUR CAR'}
                </span>
                <img
                  loading="lazy"
                  src="/icons/ic_expand_more.svg"
                  alt="Select your car"
                  className={s.selectCarExpand}
                />
              </div>

              {/* {(props.isCountry === 'India' || props.isMalaysiaRequest) ?  */}
                <div className={s.containerBox}>
                  <input
                    type="tel"
                    name="mobile"
                    value={inputs.mobile}
                    placeholder="ENTER MOBILE NUMBER"
                    onChange={handleInputChange}
                  />
                </div> 
                {/* :
                null
              } */}

              {/* <Link to="/services"> */}
              <div
                onClick={() => {handlerCheckPricesFree()}}
                className={classNames(s.containerBox, s.redBox)}
                style={{
                  cursor: submitting ? 'unset' : 'pointer',
                  opacity: submitting ? '0.5' : '1'
                }}
                // onClick={submitting ? () => {} : formSubmit}
                role="button"
              >
                <span className={s.checkPriceText}>{submitting ? 'Please wait..' : 'CHECK PRICES FOR FREE'}</span>
                {props.enquiryPageRender ? <span className={s.shine} ></span> : null}
              </div>
              {/* <div className={s.truText}>
                By continuing, you agree to GoMechanic's <a href="/terms" className={s.termsPrivacyLink}>Terms & Conditions</a> & 
                <a href="/privacy" className={s.termsPrivacyLink}> Privacy Policy.</a>
              </div> */}
              {/* </Link> */}
            </div>

            <div className={s.containerBlack}>
              {/* <div className={s.ratingContainer}> */}
              {/*  <div className={s.ratingItem}> */}
              {/*    <div className={s.ratingText}> */}
              {/*      <img loading='lazy' */}
              {/*        className={s.startIcon} */}
              {/*        src="https://cdn.zeplin.io/5da0780780829f083448125a/assets/CDB53621-BDD3-4E69-9114-AC32FB9AB1FA.svg" */}
              {/*      /> */}
              {/*      <span>4.5 / 5</span> */}
              {/*    </div> */}
              {/*    <span className={s.subHeadingRating}> */}
              {/*      Based on 500+ reviews */}
              {/*    </span> */}
              {/*  </div> */}
              {/*  <div className={s.ratingItem}> */}
              {/*    <span>2 Million +</span> */}
              {/*    <span className={s.subHeadingRating}> */}
              {/*      Cars serviced and repaired */}
              {/*    </span> */}
              {/*  </div> */}
              {/* </div> */}
              <Rating />
            </div>
          </>
        ) : null}
      </DesktopSideFloater>
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PcHomeForm);
