// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2sXVG{-webkit-box-shadow:0 4px 5px 0 rgba(0,0,0,.12);box-shadow:0 4px 5px 0 rgba(0,0,0,.12);background-color:#1d1d1b;display:-ms-flexbox;display:flex;padding:10px 17px;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center}.EY5ak>img{width:38px;height:38px;-o-object-fit:cover;object-fit:cover}.EY5ak>span{font-size:13px;font-weight:500;font-stretch:normal;font-style:normal;letter-spacing:.12px;text-align:center;color:#fff}._2CbRF{width:17px;height:17px;-o-object-fit:cover;object-fit:cover}._3je4r{font-size:12.96px;font-size:.81rem;font-weight:400}._3je4r div{padding:.1rem}._2x9b4{letter-spacing:.11px;margin-top:7px}._14vqx,._2x9b4{font-weight:500;color:#fff}.Ho-d8{height:16px;height:1rem}._2b5dj{border-radius:5px;border:1px solid #e73c33;font-size:14px;font-weight:700;padding:.5rem;letter-spacing:1.6px;letter-spacing:.1rem;text-decoration:none;cursor:pointer;color:#fff;background-color:#e73c33}.-aOs-{display:-ms-flexbox;display:flex}._3je4r ._1cOWo{padding-top:.2rem;padding-left:.2rem}._3SBbO{width:71px;height:12px;font-size:14px;font-weight:700;font-stretch:normal;font-style:normal;line-height:1.58;letter-spacing:.11px;text-align:left;color:#fff}", ""]);
// Exports
exports.locals = {
	"container": "_2sXVG",
	"titleAndLogo": "EY5ak",
	"closeBtn": "_2CbRF",
	"textContain": "_3je4r",
	"fontWeight": "_2x9b4",
	"code": "_14vqx",
	"star": "Ho-d8",
	"install": "_2b5dj",
	"displayFlex": "-aOs-",
	"starNumber": "_1cOWo",
	"goMechanic": "_3SBbO"
};
module.exports = exports;
