import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { PulseLoader } from 'react-spinners';
import s from './FullPageLoader.css';
import { connect } from 'react-redux';
;
const FullPageLoader = (props) => {
  useStyles(s);
  return (
    <>
      <div style={{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: '#FFF',
        zIndex: 50000,
        overflow: 'hidden'
      }}
      >
        {props?.device?.toLowerCase() !== 'mobile' ? <PulseLoader color="#e03e2f" size={17} margin={5}/> :
        <PulseLoader color="#e03e2f" size={15} margin={3}/>}
        {/* <img 
          className={s.loadingRotate} 
          // src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Checkout/Loader%20(1).gif" 
          src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/Website/gif-loader.gif"
          alt="Loader" 
        /> */}

      </div>
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FullPageLoader);

