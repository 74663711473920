import HttpHeader from '../httpHeader';
import Global from '../../global';
import { Http } from '../../modules/http';

export const createEnquiryLead = (payload) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.api_get}v1/oauth/leads/create_new_lead_no_auth`;
  return HttpHandler.post(
    url,
    payload,
    headers,
  );
};
