/* eslint-disable react/jsx-filename-extension */
import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { setActiveServiceType } from '../../actions/serviceType';
import setUserCar from '../../actions/cardetails';
import generate from '../../serverRoutes/urlGenerators/newUrl';
import history from '../../history';
import s from './main.css';
import upArrow from './arrow1.png';
import downArrow from './arrow2.png';
import { isPaytmBrowser } from '../../utilities';
import Link from '../Link';
import { selectRegion } from '../../actions/selectRegion';
import autoUtmHistory from '../../_services/autoUtmHistory';

const FooterPage = (props) => {
  useStyles(s);

  const [expand, setExpand] = useState(0);

  const handleServiceChange = (event) => {

  };

  function expandDiv(index) {
    setExpand(index);
    if (index == expand) {
      setExpand(-2);
    }
  }

  const linkGeneratorForRegion = (value) => ((props.page && props.page === 'service')
    ? `${generate({
      city: props.city,
      subCity: value,
      serviceType: props.serviceType,
      brand: (props.cardetails && props.cardetails?.brand) ? props.cardetails?.brand : null,
      model: (props.cardetails && props.cardetails?.model) ? props.cardetails?.model : null,
      fuel: (props.cardetails && props.cardetails?.fuel) ? props.cardetails?.fuel : null,
    })}`
    : `${generate({
      city: props.city,
      subCity: value,
    })}`);

  return (
    <div className={s.root}>
      {!props.exportpage ? (
        <div className={s.gridContainer}>

          <div className={s.item}>
            <div className={s.flexCol} onClick={() => expandDiv(1)}>
              <h4 className={s.heading}>
                ABOUT US
              </h4>
              { expand == 1
                ? <img loading="lazy" src={upArrow} className={s.image} alt="Up Arrow" />
                : <img loading="lazy" src={downArrow} className={s.image} alt="Down Arrow" />}

              <div className={expand == 1 ? s.valuesBox1 : s.valuesBox2}>
                {props.footer?.aboutUs?.map((value, index) => {
                  if (props.showAccesoriesFooter) {
                    return (
                      <a key={index} className={s.cDetails} href={value.link}>
                        <p className={s.text}>{ value.name }</p>
                      </a>
                    );
                  }
                  return (!value.accessories ? (
                    <a key={index} className={s.cDetails} href={value.link}>
                      <p className={s.text}>{ value.name }</p>
                    </a>
                  ) : null);
                })}
              </div>
            </div>
          </div>

          <div className={s.item}>
            <div className={s.flexCol} onClick={() => expandDiv(2)}>
              <h4 className={s.heading}>
                OUR SERVICES
              </h4>
              { expand == 2
                ? <img loading="lazy" src={upArrow} className={s.image} alt="Up Arrow" />
                : <img loading="lazy" src={downArrow} className={s.image} alt="Down Arrow" /> }
              <div className={expand === 2 ? s.valuesBox1 : s.valuesBox2}>
                {props.footer?.servicesType?.map((service) => (
                    <a 
                    key={service.name}
                    href={generate({
                      city: props.city,
                      subCity: props.selectedRegion,
                      serviceType: service,
                      brand: props.cardetails?.brand,
                      model: props.cardetails?.model,
                      fuel: props.cardetails?.fuel,
                    })}
                      className={s.text}
                      onClick={
                        (e) => {
                        // e.preventDefault();
                        // props.action(setActiveServiceType(service));
                        // autoUtmHistory(history).push(`${generate({
                        //   city: props.city,
                        //   subCity: props.selectedRegion,
                        //   serviceType: service,
                        //   brand: props.cardetails?.brand,
                        //   model: props.cardetails?.model,
                        //   fuel: props.cardetails?.fuel,
                        // })}`);
                      }
                      }
                    >
                      {service.name}
                    </a>
                ))}
              </div>
            </div>
          </div>

          <div className={s.item}>
            <div className={s.flexCol} onClick={() => expandDiv(3)}>
              <h4 className={s.heading}>
                LUXURY BRANDS
              </h4>
              { expand == 3
                ? <img loading="lazy" src={upArrow} className={s.image} alt="Up Arrow" />
                : <img loading="lazy" src={downArrow} className={s.image} alt="Down Arrow" /> }
              <div className={expand == 3 ? s.valuesBox1 : s.valuesBox2}>
                {props.footer?.luxuryBrands?.map((brand) => (
                  <a 
                  key={brand.name}
                  href={generate({
                    city: props.city,
                    subCity: props.selectedRegion,
                    serviceType: props.servicesTypesList[0],
                    brand,
                    // model: props.cardetails?.model,
                    // fuel: props.cardetails?.fuel,
                  })}
                    className={s.text}
                    onClick={(e) => {
                      e.preventDefault();
                      // props.action(setActiveBrand(brand));
                      props.action(setActiveServiceType(props.servicesTypesList[0]));
                      props.action(setUserCar({
                        brand,
                        model: null,
                        fuel: null,
                      }));
                      if (props.setCarSelectionShow) props.setCarSelectionShow(true);
                      autoUtmHistory(history).push(`${generate({
                        city: props.city,
                        subCity: props.selectedRegion,
                        serviceType: props.servicesTypesList[0],
                        brand,
                        // model: props.cardetails?.model,
                        // fuel: props.cardetails?.fuel,
                      })}`, {
                        showCarSelection: true,
                      });
                    }}
                  >
                    { brand.name}
                  </a>
                ))}
              </div>
            </div>
          </div>

          <div className={s.item}>
            <div className={s.flexCol} onClick={() => expandDiv(4)}>
              <h4 className={s.heading}>
                POPULAR BRANDS
              </h4>
              { expand == 4
                ? <img loading="lazy" src={upArrow} className={s.image} alt="Up Arrow" />
                : <img loading="lazy" src={downArrow} className={s.image} alt="Down Arrow" /> }
              <div className={expand == 4 ? s.valuesBox1 : s.valuesBox2}>
                {props.footer?.popularBrands?.map((brand) => (
                  <a 
                  key={brand.name}
                  href={generate({
                    city: props.city,
                    subCity: props.selectedRegion,
                    serviceType: props.servicesTypesList[0],
                    brand,
                    // model: props.cardetails?.model,
                    // fuel: props.cardetails?.fuel,
                  })}
                    className={s.text}
                    onClick={(e) => {
                      e.preventDefault();
                      // props.action(setActiveBrand(brand));
                      props.action(setActiveServiceType(props.servicesTypesList[0]));
                      props.action(setUserCar({
                        brand,
                        model: null,
                        fuel: null,
                      }));
                      if (props.setCarSelectionShow) props.setCarSelectionShow(true);
                      autoUtmHistory(history).push(`${generate({
                        city: props.city,
                        subCity: props.selectedRegion,
                        serviceType: props.servicesTypesList[0],
                        brand,
                        // model: props.cardetails?.model,
                        // fuel: props.cardetails?.fuel,
                      })}`);
                    }}
                  >
                    {brand.name}
                  </a>
                ))}
              </div>
            </div>
          </div>

          <div className={s.item}>
            <div className={s.flexCol} onClick={() => expandDiv(5)}>
              <h4 className={s.heading}>
                POPULAR AREAS NEAR YOU
              </h4>
              { expand == 5
                ? <img loading="lazy" src={upArrow} className={s.image} alt="Up Arrow" />
                : <img loading="lazy" src={downArrow} className={s.image} alt="Down Arrow" /> }
              <div className={expand == 5 ? s.valuesBox1 : s.valuesBox2}>
                {props.regions?.map((value) => (
                  <Link
                    key={value.name}
                    to={linkGeneratorForRegion(value)}
                    onClick={(e) => {
                      e.preventDefault();
                      // console.log(props.page)
                      props.action(selectRegion(value));
                      autoUtmHistory(history).push(linkGeneratorForRegion(value));
                    }}
                  >
                    <p className={s.text}>
                      Garage near me in
                      {' '}
                      {value.name}
                    </p>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <div className={classNames(s.gridContainerFor3, s.bgBlue)}>

        <div className={s.flexCol}>

          <div className={`${s.flexRow} ${s.websiteLogo}`}>
            <img loading="lazy" src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/WHITE.png" className={s.footerLogo} alt="Gomechanic" />
          </div>

          {props.isNigerianRequest ? (
            <div className={s.pl1rem}>
              <p className={s.infoHeadings}>2A Sunday Adigun St, Oregun, Ikeja, Nigeria</p>
            </div>
          ) : props.isMalaysiaRequest ? (
            <div className={s.pl1rem}>
              <p className={s.infoHeadings}>56-3 Jalan PJU 5/20D The Strand, Kota Damansara,</p>
              <p className={s.infoHeadings} style={{ marginTop: '10px' }}>47810 Petaling Jaya, Selangor, Malaysia</p>
            </div>
          ) : (
            <div className={s.pl1rem}>
              <p className={s.infoHeadings}>7th Floor, Tower A, Spaze iTech Park,</p>
              <p className={s.infoHeadings} style={{ marginTop: '10px' }}>Sohna - Gurgaon Rd, Block S, Sector 49,</p>
              <p className={s.infoHeadings} style={{ marginTop: '10px' }}>Gurugram, Haryana 122018</p>
            </div>
          )}
          {!isPaytmBrowser() && (
            <div className={classNames(s.flexRow, s.marginTopIcons, s.justifyCenterChange)}>
              <a target="_blank" href={props.isMalaysiaRequest ? "https://www.facebook.com/gomechanic.my/" : "https://www.facebook.com/gomechanic.in/"}><div className={s.facebook} /></a>
              <a target="_blank" href="https://twitter.com/gomechanicindia"><div className={s.twitter} /></a>
              <a target="_blank" href={props.isMalaysiaRequest ? "https://instagram.com/gomechanic.my" : "https://www.instagram.com/gomechanic.in/"}><div className={s.instagram} /></a>
              <a target="_blank" href={props.isNigerianRequest ? ('https://api.whatsapp.com/send?phone=2347020700617&text=&source=&data=') : ('https://api.whatsapp.com/send?phone=918800049798&text=Hi&source=&data=')}><div className={s.whatsApp} /></a>
              <a target="_blank" href="https://www.youtube.com/channel/UCGfHTDcSpvHVORkNdRwfdyQ"><div className={s.youtube} /></a>
            </div>
          )}

        </div>

        {/* <div className={classNames(s.flexRow, s.pl1rem, s.pbinfo)}> */}
        {/*  <div className={s.infoHeadingsValues}> */}
        {/*    <div className={classNames(s.flexRow, s.pbinfoItem)}> */}
        {/*      <div className={s.email} /> */}
        {/*      <span className={classNames(s.infoHeadings, s.pbinfoItemBetween)}>Email Id</span> */}
        {/*    </div> */}
        {/*    <div className={classNames(s.flexRow, s.pbinfoItem)}> */}
        {/*      <div className={s.phoneNumber} /> */}
        {/*      <span className={classNames(s.infoHeadings, s.pbinfoItemBetween)}>Phone Number</span> */}
        {/*    </div> */}
        {/*    <div className={classNames(s.flexRow, s.pbinfoItem)}> */}
        {/*      <div className={s.working} /> */}
        {/*      <span className={classNames(s.infoHeadings, s.pbinfoItemBetween)}>Working Days</span> */}
        {/*    </div> */}
        {/*    <div className={classNames(s.flexRow, s.pbinfoItem)}> */}
        {/*      <div className={s.timer} /> */}
        {/*      <span className={classNames(s.infoHeadings, s.pbinfoItemBetween)}>Hours of Operation</span> */}
        {/*    </div> */}
        {/*  </div> */}

        {/*  <div className={s.infoHeadingsValues2}> */}
        {/*    <p className={classNames(s.infoHeadings, s.paddin)}> Info@goMechanic.in</p> */}
        {/*    <p className={classNames(s.infoHeadings, s.paddin)}>8398970970</p> */}
        {/*    <p className={classNames(s.infoHeadings, s.paddin)}>Monday - Sunday</p> */}
        {/*    <p className={classNames(s.infoHeadings, s.paddin)}>7:00am - 9:00pm(IST)</p> */}
        {/*  </div> */}
        {/* </div> */}

        <div className={s.footerInfoContainer}>
          {/* <div className={s.displayFlex}>
            <div className={s.email} />
            <span className={s.contactText}>Info@goMechanic.in</span>
        </div>
        <div className={s.displayFlex}>
            <div className={s.phoneNumber} />
            <span className={s.contactText}>8398970970</span>
        </div>
        <div className={s.displayFlex}>
            <div className={s.working} />
            <span className={s.contactText}>Monday - Sunday</span>
        </div>
        <div className={s.displayFlex}>
            <div className={s.timer} />
            <span className={s.contactText}>7:00AM - 9:00PM(IST)</span>
        </div> */}

          <div className={s.footerInfo}>
            <span className={classNames(s.footerInfoItemTitle, s.fottercCDetailsminwidth)}>
              <div className={s.email} />
              <h5 style={{
                margin: '0',
              }}
              >
                Email
              </h5>
              {' '}

            </span>
            <span className={s.footerInfoItemDesc}>
                <a href={props.exportpage ? "mailto:rahul.bhambri@gomechanic.in " : "mailto:info@gomechanic.in"} style={{ textDecoration: 'none', color: 'white'}}>{props.exportpage ? 'rahul.bhambri@gomechanic.in' : 'Info@goMechanic.in'}</a>
              </span>
            
          </div>
          <div className={s.footerInfo}>
            <span className={classNames(s.footerInfoItemTitle, s.fottercCDetailsminwidth)}>
              <div className={s.phoneNumber} />
              <h5 style={{
                margin: '0',
              }}
              >
                Phone Number
              </h5>
              {' '}

            </span>
            {props.exportpage ? (
              <span>
                <a className={s.cDetails} href='tel:8800889881'>                  
                  +91 88008 89881
                </a>
              </span>
            ) : (
              <span>
                <a className={s.cDetails} href={props.isNigerianRequest ? 'tel:07020700617' : props.isMalaysiaRequest ? 'tel:+60392121789' : 'tel:8398970970'}>
                  {/* {props.isNigerianRequest ? '07020700617' : props.isMalaysiaRequest ? '+6039-2121 789' : '8398970970'} */}
                  {props.isNigerianRequest ? '07020700617' : props.isMalaysiaRequest ? '+6039-2121 789' : '9388893888'}
                </a>
              </span>
            )}
          </div>
          <div className={s.footerInfo}>
            <span className={classNames(s.footerInfoItemTitle, s.fottercCDetailsminwidth)}>
              <div className={s.working} />
              <h5 style={{
                margin: '0',
              }}
              >
                Working Days
              </h5>
            </span>
            <span className={s.footerInfoItemDesc}>Monday - Sunday</span>
          </div>
          <div className={s.footerInfo}>
            <span className={classNames(s.footerInfoItemTitle, s.fottercCDetailsminwidth)}>
              <div className={s.timer} />
              <h5 style={{
                margin: '0',
              }}
              >
                Working Hours
              </h5>
            </span>
            <span className={s.footerInfoItemDesc}>
              {props.isNigerianRequest ? '7:00AM - 9:00PM(WAT)' : props.isMalaysiaRequest ? '10:00AM - 7:00PM (MYT)' : '7:00AM - 9:00PM(IST)'}
              {' '}
            </span>
          </div>
        </div>

        {!(props.isNigerianRequest || isPaytmBrowser()) && (
        <div className={classNames(s.flexCol)}>

          {/* <span className={s.downloadApp}>
           Download the App!
         </span> */}
          {/* <div className={classNames(s.pl1rem,s.downloadAppText)}> */}
          {/*    <span> */}
          {/*      Download the App! */}
          {/*  </span> */}
          {/* </div> */}
          {!props.exportpage ? (
            <div className={classNames(s.flexDownloadApp, s.mlr1rem, s.justifySpaceB)}>
              <a
                className={classNames(s.downloadButton, s.flexRow, s.justifySpaceB)}
                target="_blank"
                href="https://play.google.com/store/apps/details?id=gomechanic.retail&hl=en_IN"
              >
                <img loading="lazy" alt="Gomechanic" src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/FooterPage/playstore.png" className={s.downloadPlayImg} />
              </a>

              <a
                className={classNames(s.downloadButton, s.flexRow, s.justifySpaceB)}
                target="_blank"
                href="https://apps.apple.com/in/app/gomechanic-car-service/id1498891908"
              >
                <img loading="lazy" src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/FooterPage/appstore.png" className={s.downloadIosImg} alt="App store" />
              </a>
            </div>
          ) : null}
        </div>
        )}
      </div>

      <div className={classNames(s.gridContainerFor3, s.pb1r, s.bgBlue)} style={{
        gridTemplateColumns: '1fr',
      }}>
        <div className={classNames(s.flexRow, s.marginTopcr, s.justifyCenter)}>
          <div className={s.copyright} />
          <div className={s.copyRightText}>2016-{`${new Date().getFullYear()}`} Service Easy Technology Private Limited</div>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterPage);
