import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './DropDownMenu.scss';

const DropDownMenu = (props) => {
  useStyles(s);
  return (
    <>
      <div className={s.container}>
        <div className={s.item} style={{ ...props.style }}>
          {props.parentString}
        </div>
        <div className={s.dropDown}>
          <div className={s.triangle} />
          {props.children}
        </div>
      </div>
    </>
  );
};

export default DropDownMenu;
