import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './css/Homepage.css';
import DesktopHeaderMain from './DesktopHeaderMain/DesktopHeaderMain';

const HomepagePcHeader = (props) => {
  useStyles(s);
  return (
    <>
      {/*<div className={s.pcHeader}>*/}
      {/*  <div>*/}
      {/*    <div className={s.newTrending}>*/}
      {/*      <span>New</span>*/}
      {/*    </div>*/}
      {/*    <div className={s.offerText}>*/}
      {/*      Get the GoMechanic App for iOS and Android for exclusive offers!*/}
      {/*    </div>*/}
      {/*  </div>*/}

      {/*  <div*/}
      {/*    style={{*/}
      {/*      flexWrap: 'wrap',*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <div className={s.helpline}>24x7 Helpline</div>*/}
      {/*    <div className={s.helplineCallBox}>8398970970</div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {!props.withoutMain && <DesktopHeaderMain noService />}
    </>
  );
};

export default HomepagePcHeader;
