export const getBrandName = (store) => store.cardetails.brand.name;

export const getModelName = (store) => store.cardetails.model.name;

export const getFuelName = (store) => store.cardetails.fuel.name;

export const getModelImage = (store) => store?.cardetails?.model?.image_path;

export const getCartCarModelImage = (store) => (store?.carForCart?.model?.image_path ?? getModelImage(store));

export const getCartCarBrandName = (store) => (store.carForCart && store.carForCart.brand ? store.carForCart.brand.name : getBrandName(store));

export const getCartCarModelName = (store) => (store.carForCart && store.carForCart.model ? store.carForCart.model.name : getModelName(store));

export const getCartCarFuelName = (store) => (store.carForCart && store.carForCart.fuel ? store.carForCart.fuel.name : getFuelName(store));
