// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3QfUP{margin-top:6px;width:100%}._3f-An,.n81pp{background-color:#eaeaea;padding:16px 11px;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center;width:94%}.n81pp{width:100%}.LITgL{font-size:17px;font-weight:600;font-stretch:normal;font-style:normal;line-height:1.12;letter-spacing:.15px;text-align:left;color:#282828}._2FHWM{width:15.2px;height:9.4px;-o-object-fit:contain;object-fit:contain}._3mY1o,.U565a,._2FHWM{-webkit-transition:-webkit-transform .5s;transition:-webkit-transform .5s;-o-transition:transform .5s;transition:transform .5s;transition:transform .5s,-webkit-transform .5s}._3mY1o,.U565a{background-color:#f6f6f6;font-size:15px;font-weight:400;font-stretch:normal;font-style:normal;line-height:1.53;letter-spacing:.14px;text-align:left;color:#4a4a4a;-webkit-transform:scaleY(0);-ms-transform:scaleY(0);transform:scaleY(0);-webkit-transform-origin:top;-ms-transform-origin:top;transform-origin:top;height:0;padding:0;width:93%;overflow:hidden}.U565a{width:99.4%}._3f-An[aria-expanded=true]+._3mY1o{-webkit-transform:scaleY(1);-ms-transform:scaleY(1);transform:scaleY(1);padding:7px 13px 13px 14px;height:100%}._3f-An[aria-expanded=true]>._2FHWM{-webkit-transform:rotate(180deg);-ms-transform:rotate(180deg);transform:rotate(180deg)}", ""]);
// Exports
exports.locals = {
	"questionAnswerContainer": "_3QfUP",
	"questionContainer": "_3f-An",
	"questionContainer2": "n81pp",
	"questionText": "LITgL",
	"questionControl": "_2FHWM",
	"answer": "_3mY1o",
	"answer2": "U565a"
};
module.exports = exports;
