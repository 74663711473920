import HttpHeader from '../httpHeader';
import { Http } from '../../modules/http';
import Global from '../../global';
import { randomGenerator } from '../../utilities';

export const verifyOtp = (number, otp) => {
  const Headers = new HttpHeader();
  const HttpHandler = new Http();
  const ApiManager = new Global();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.api_get}verify_login`;
  return HttpHandler.post(url, { number, otp }, headers);
};

export const verifyOtpV2 = (number, otp) => {
  const Headers = new HttpHeader();
  const HttpHandler = new Http();
  const ApiManager = new Global();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.api_get}v2/verify_login`;
  return HttpHandler.post(url, { number, otp }, headers);
};

export const sendOtp = (number, isAccessories) => {
  const Headers = new HttpHeader();
  const HttpHandler = new Http();
  const ApiManager = new Global();
  const randomNumber = randomGenerator(5);

  const headers = Headers.get_token_extra_headers(number, randomNumber);
  // const url = `${ApiManager.urls.api_get}send_otp/`;
  const url = `${ApiManager.urls.api_get}v2/send_otp`;
  return HttpHandler.post(url, { number, source: isAccessories ? 'accessories' : 'website', random_id: randomNumber }, headers);
};
