import HttpHeader from '../httpHeader';
import { Http } from '../../modules/http';
import Global from '../../global';

export const fetchTimeSlots = (cityId) => {
  const Headers = new HttpHeader();
  const HttpHandler = new Http();
  const ApiManager = new Global();

  
  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.api_get}v4/oauth/customer/get_time_slots?city_id=${cityId}&channel=Website`;
  return HttpHandler.get(url, headers);
};
