import HttpHeader from '../httpHeader';
import { Http } from '../../modules/http';
import Global from '../../global';

export const getPaymentOffers = (amount) => {
  const Headers = new HttpHeader();
  const HttpHandler = new Http();
  const ApiManager = new Global();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.api_get}v3/oauth/customer/get_payment_offers?total_amount=${amount}`;
  // const url = `/api/v3/oauth/customer/get_payment_offers?total_amount=${amount}`;
  return HttpHandler.get(url, headers);
};
