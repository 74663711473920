/*
 Author : Rishabh Tyagi
 This component will contain the "Monsoon Services" section as in
 https://app.zeplin.io/project/5da0780780829f083448125a/screen/5da07e90a303922fd8070ede
*/

import React, { useState, useEffect } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { connect } from 'react-redux';
import s from './css/MonsoonResponsive.css';
import { setActiveServiceType } from '../../../actions/serviceType';
import generate from '../../../serverRoutes/urlGenerators/newUrl';
import history from '../../../history';
import PlaceholderImage from '../../PlaceholderImages/PlaceholderImage';
import autoUtmHistory from '../../../_services/autoUtmHistory';
import { tapOnMonsoonList, tapCuratedCustomRepairs, retailBannerClickEvent } from '../../../gtm/gtmServices';
import Link from '../../Link/Link';

const MonsoonServiceCarousel = (props) => {
  useStyles(s);
  const [ data ,setData ] = useState({});

  const quryStr = (key) => {
    let anch = props.deeplink
   return decodeURIComponent((anch).replace(new RegExp(`^(?:.*[&\\?]${encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&')}(?:\\=([^&]*))?)?.*$`, 'i'), '$1'))
  }

  const catId = Number(quryStr('category_id'));
  const pageName = String(quryStr('pagename')); 

  const serviceTypeLink = props.servicesTypesList.find(
    (item) => { 
      if(item.id === catId)  {
        return true;
      }
      else {
        return false;
      }
    }
  )

  return (
      <Link
        key={props.key}
        className={props.popularServices == 1 ? s.padding : props.carPartsSection ? s.carPartsPadding : s.container}
        onClick={(e) => {
          e.preventDefault();
          // if(props.inDetail === 1) {
          //   if(props.serviceData.id === data.id ) {
          //     props.action(setMonsoonData(data));
          //     autoUtmHistory(history).push(`${generate({
          //       city: props.city,
          //       service: data,
          //       brand: props.cardetails?.brand,
          //       model: props.cardetails?.model,
          //       fuel: props.cardetails?.fuel,
          //     })}`,data);
          //     tapOnMonsoonList({
          //       item_id: props.serviceTypeIndex,
          //       item: props.serviceData.name,
          //     });
          //   }
          // }
          retailBannerClickEvent(
            props?.user?.id, 'homepage', null, null, null, props.index, props.home.monsoonData.title,
          );
          if(pageName === 'AMC_FRAGMENT') {
            autoUtmHistory(history).push('/miles-membership');
          }
          else {
            const serviceType = props.servicesTypesList.find(
              (item) => { 
                if(pageName === 'SOS' && item.id === 50){
                  return true;
                }
                else if(item.id === catId && pageName != 'SOS' )  {
                  return true;
                }
                else {
                  return false;
                }
              }
            ) 
            console.log(pageName,'serviceType',serviceType)
            props.action(setActiveServiceType(serviceType || null));
            autoUtmHistory(history).push(`${generate({
              city: props.city,
              serviceType: serviceType || null,
              brand: props.cardetails?.brand,
              model: props.cardetails?.model,
              fuel: props.cardetails?.fuel,
            })}`);
            if(props.curated) {
              tapCuratedCustomRepairs({
                user_id: props.user?.id  ,
                custom_repair_name: props.serviceText, 
                deeplink: (`${generate({
                  city: props.city,
                  serviceType: serviceType || null,
                  brand: props.cardetails?.brand,
                  model: props.cardetails?.model,
                  fuel: props.cardetails?.fuel,
                })}`),
                page_name: 'homepage'
              });
            } else {
              tapOnMonsoonList({
                category_id: catId,
                category: props.serviceData.name,
              });
            }
          }
        }}
        to={pageName === 'AMC_FRAGMENT' ? '/miles-membership' : 
        `${generate({
          city: props.city,
          serviceType: serviceTypeLink || null,
          brand: props.cardetails?.brand,
          model: props.cardetails?.model,
          fuel: props.cardetails?.fuel
        })}`
        }
      >
        <PlaceholderImage
          placeHolderImage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
          loading="lazy"
          src={props.articleImage}
          className={props.popularServices == 1 ? props.isMalaysiaRequest ? s.popularServicesImageMly : s.popularServicesImage : props.carPartsSection ? props.tag ? s.partsTagImgDesktop : s.partsImgDesktop : s.gridImage}
          alt={props.altTag ? props.altTag : "Monsoon"}
        />
        {props.tag && <div className={s.newTagDesk}>{props?.tag}</div>}
        <div className={props.popularServices == 1 ? s.textDiv : props.carPartsSection ? s.textDiv : s.hide}>
          <div className={props.tag ? s.headingNewTagDesk : s.monsonText}>{props.serviceText}</div>
        </div>
    </Link>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MonsoonServiceCarousel);
