// export const possibleSections = {
//   APP_DOWNLOAD: 'APP_DOWNLOAD',
//   OUR_PARTNERS: 'OUR_PARTNERS',
//   SERVICE_GUIDE: 'SERVICE_GUIDE',
//   FOOTER: 'FOOTER'
// };

// export const defaultDisplayConfig = {
//   [possibleSections.APP_DOWNLOAD]: true,
//   [possibleSections.OUR_PARTNERS]: true,
//   [possibleSections.SERVICE_GUIDE]: true,
//   [possibleSections.FOOTER]: true,
// };

// export const nigeriaDisplayConfig = {
//   [possibleSections.APP_DOWNLOAD]: false,
//   [possibleSections.OUR_PARTNERS]: false,
//   [possibleSections.SERVICE_GUIDE]: true,
//   [possibleSections.FOOTER]: true,
// };
// export const paytmDisplayConfig = {
//   [possibleSections.APP_DOWNLOAD]: false,
//   [possibleSections.OUR_PARTNERS]: false,
//   [possibleSections.SERVICE_GUIDE]: true,
//   [possibleSections.FOOTER]: false,
// };

// export const phonepeDisplayConfig = {
//   [possibleSections.APP_DOWNLOAD]: false,
//   [possibleSections.OUR_PARTNERS]: false,
//   [possibleSections.SERVICE_GUIDE]: true,
//   [possibleSections.FOOTER]: false,
// };


export const checkDisplayConfig = (section, displayConfigurations) => {
  // set to true if key does not exist
  if (Object.keys(displayConfigurations).includes(section)) {
    return !!(displayConfigurations[section]);
  }
  return true;
};
