// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1uhFQ{width:90px;height:30px;border-radius:2px;border:1px solid hsla(0,0%,60.8%,.5);background-color:#fff;-ms-flex-item-align:center;align-self:center;margin-top:17px}._1UV36{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;vertical-align:middle;-ms-flex-align:center;align-items:center}._3Vm2S{font-size:14px;font-weight:600;font-stretch:normal;font-style:normal;line-height:1.36;letter-spacing:.13px;text-align:left;color:#4a4a4a;margin-left:14px;margin-top:6px}", ""]);
// Exports
exports.locals = {
	"seeMore": "_1uhFQ",
	"faq": "_1UV36",
	"buttonText": "_3Vm2S"
};
module.exports = exports;
