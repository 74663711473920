// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._13SfF{max-width:450px;max-width:28.125rem;height:0;position:sticky;left:calc(100% - 30rem);top:110px;top:6.875rem;-o-object-fit:contain;object-fit:contain;border-radius:.125rem;background-color:#fff;z-index:3}._1jgox,._3r7DG{height:-webkit-fit-content;height:-moz-fit-content;height:fit-content}._3r7DG{-webkit-box-shadow:0 -.25rem .875rem 0 rgba(0,0,0,.15),0 .0625rem .625rem 0 rgba(0,0,0,.12),0 .25rem .9375rem .3125rem rgba(0,0,0,.2);box-shadow:0 -.25rem .875rem 0 rgba(0,0,0,.15),0 .0625rem .625rem 0 rgba(0,0,0,.12),0 .25rem .9375rem .3125rem rgba(0,0,0,.2)}._1U6J0{background-color:#fff}@media screen and (max-width:1200px) and (min-width:1023px){._13SfF{left:calc(100% - 29.5rem)}}", ""]);
// Exports
exports.locals = {
	"container": "_13SfF",
	"heightFit": "_1jgox",
	"shadow": "_3r7DG",
	"containerNoSticky": "_1U6J0"
};
module.exports = exports;
