import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './MaterialInput.css';

const MaterialInput = (props) => {
  useStyles(s);
  const inputChangeHandler = props.onChange ? props.onChange : () => { console.log('no onchange handler passed'); };
  const placeholder = props.placeholder ? props.placeholder : 'Sample';
  return (
    <>
      <div className={s.mainContainer}>
        <div className={s.container} style={props.style}>
          <input
            className={s.input}
            {...props.inputProps}
            onChange={inputChangeHandler}
            placeholder={placeholder}
          />
          <div className={s.label}>{placeholder}</div>
        </div>
        {props.rightSideText
          ? (
            <span
              onClick={props.rightSideTextClick ? props.rightSideTextClick : () => false}
              className={s.rightSideText}
              style={{...(props.rightSideTextStyle ? props.rightSideTextStyle : {})}}
            >
              {props.rightSideText}
            </span>
          )
          : null}
      </div>
    </>
  );
};

export default MaterialInput;
