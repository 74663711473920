import React from 'react';
import { connect } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './index.css';
import ExtendedWarrantyItem from './ExtendedWarrantyItems';
import { getCartCarModelImage } from '../../../_services/carDetailGetters';
import history from '../../../history';

function ExtendedWarranty(props) {
  const { data } = props;
  useStyles(s);
  return (
    <div className={s.ExtendedWarrantyWrapper}>
      <div className={s.arrowRow}>
        <img
          src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/retail%20warranty%20page/backIcon.svg"
          onClick={() => history.goBack()}
          alt="back"
        />
      </div>
      <h3>{data?.title}</h3>
      <h4>{data?.sub_title}</h4>
      <section>
        {data?.bullets?.map((item, index) => <ExtendedWarrantyItem
          item={{ warning: 'warning', text: item, index : index }}
        />)}
        <img src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/Website/Warranty/Hero.svg" className={s.img1} alt={'car image background'} />
        <img src={getCartCarModelImage(props)} alt='car image'/>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(ExtendedWarranty);
