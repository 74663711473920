import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames';
import s from './index.css';
import ClaimsAvailable from '../ClaimsAvailable';
import { getCartCarModelImage } from '../../../_services/carDetailGetters';

function ActiveCoverage(props) {
  const {data} = props; 
  useStyles(s);
  const [actionButtons, setActionButtons] = useState([]);
  const [activeAction, setActiveAction] = useState();

  useEffect(() => {
    let tempActionButtons = data?.warranty_user_data?.map((warranty, index) => ({ title: warranty?.coverage_data?. tab_title, selected: index === 0, index }))
    setActionButtons(tempActionButtons);
    setActiveAction(tempActionButtons?.[0]);
  }, [data])

  let activeTabData = data?.warranty_user_data?.[activeAction?.index] ?? null;
  
  if(!activeTabData) return null;

  return (
    <div className={s.coverageWrapper}>
      <h3>{data?.coverage_title}</h3>
      {actionButtons?.length > 1 ? 
        <div className={s.tabsContainer}>
          {actionButtons.map((action, index) => {
            return (
              <div
                className={classNames(
                  action.selected ? s.tabActive : s.tabInactive,
                )}
                onClick={() => {
                  setActionButtons(
                    actionButtons.map((item, i) => ({
                      ...item,
                      selected: index === i,
                      index: i
                    })),
                  );
                  setActiveAction({...action})
                }}
              >
                {action.title}
              </div>
            );
          })}
        </div>
      : null
      }

      <section className={s.activeCoverageComponent}>
        <div className={s.activeCoverageInnerComponent}>
          <div className={s.upperBox}>
            <strong className={s.text1}>{activeTabData?.coverage_data?.title}</strong>
            <div className={s.text2}>{activeTabData?.coverage_data?.subtitle}</div>
          </div>
          <div className={s.lowerBox}>
            <div>
              <div className={s.text2} style={{ fontWeight: '600' }}>
                {activeTabData?.coverage_data?.car_name}
              </div>
              <div className={s.text3}>{activeTabData?.coverage_data?.registration_no}</div>
            </div>
            <img
              className={s.carImage}
              src={getCartCarModelImage(props)}
            />
          </div>
        </div>
        <div className={s.validityText}>{activeTabData?.coverage_data?.expiry_text}</div>
      </section>
      {activeTabData?.claims_available > 0 && <ClaimsAvailable data={activeTabData ?? {}}/>}
    </div>
  );
}

const mapStateToProps = (state) => {
  return state;
}

export default connect(mapStateToProps)(ActiveCoverage);
