import { SET_SELECTED_ADDR_ID } from '../constants';

const setSelectedAddressId = (value) => ({
  type: SET_SELECTED_ADDR_ID,
  payload: {
    value,
  },
});

export default setSelectedAddressId;
