import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import classnames from 'classnames';
import s from './DesktopSideFloater.css';

const DesktopSideFloater = (props) => {
  useStyles(s);
  return (
    !props.isSideFloaterActive ? (<div className={classnames(s.container, props.heightFitContent ? s.heightFit : null)} style={props.style ? { ...props.style } : {}}>
      <div
        className={props.noShadow ? null : s.shadow}
        style={props.shadowStyle ? { ...props.shadowStyle } : {}}
      >
        {props.children}
      </div>
      </div>
      ):<div className={s.containerNoSticky}>
        {props.children}
    </div>
  );
};

export default DesktopSideFloater;
