import HttpHeader from '../httpHeader';
import { Http } from '../../modules/http';
import Global from '../../global';

export const fetchWorkshopsNearYou = ({latitude= 30.7333, longitude= 76.7794, id= 13}) => {
  const Headers = new HttpHeader();
  const HttpHandler = new Http();
  const ApiManager = new Global();


  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.api_workshop}workshop/garages/?latitude=${latitude}&radius=50000&location_id=${id}&longitude=${longitude}`
  return HttpHandler.get(url, headers);
};
