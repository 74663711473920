// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".j5ITv{position:fixed;opacity:0;background-color:#071c2c;height:100%;width:100%;z-index:16;-webkit-transition:opacity 1s;-o-transition:opacity 1s;transition:opacity 1s;top:0;left:0;display:none}._10FnR{opacity:.74;display:block}._1AByQ{height:100vh;width:500px;width:31.25rem;z-index:17;background-color:#fff;position:fixed;top:0;right:0;margin-right:-32rem;-webkit-transition:margin-right .7s;-o-transition:margin-right .7s;transition:margin-right .7s}._20Bnb{margin-right:0}", ""]);
// Exports
exports.locals = {
	"overlay": "j5ITv",
	"overlayAppear": "_10FnR",
	"container": "_1AByQ",
	"containerAppear": "_20Bnb"
};
module.exports = exports;
