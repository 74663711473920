import React, { useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { connect } from 'react-redux';
import HideOnPC from '../../../_wrappers/HideOnPC';
import s from './MarketingHeader.css';

const MarketingHeader = (props) => {
  useStyles(s);
  const [show, setShow] = useState(true);

  return (
    <>
      {!props.isNigerianRequest && show ? (
        <HideOnPC>
          <div className={s.container}>
            {/* <div className={s.mainContent}> */}
              <div className={s.closediv}>
                <img
                  loading="lazy"
                  // onClick={() => props.action(setMarketingHeaderText(null))}
                  onClick={() => {
                    setShow(false);
                    if (props.hideMarketingHeader) props.hideMarketingHeader();
                  }}
                  src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/Website/close-icon.png"
                  className={s.closeBtn} alt="Close"
                />
              </div>
              <div className={s.titleAndLogo}>
                <img loading="lazy" alt="Gomechanic" src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/Website/top-banner-logo.png" className={s.logo} />
              </div>
              <div className={s.textContain}>
                {/* <span>{props.marketingHeaderText}</span> */}
                <div className={s.goMechanic}>
                  GoMechanic
              </div>
                <div className={s.fontWeight}>
                Use Code GOMECH50 on the
              </div>
                <div className={s.code}>
                app & get UP TO 50% OFF.
              </div>
                {/* <div className={s.displayFlex}> */}
                {/* <img loading="lazy"  className= {s.star} src={star} alt="Star rating" />
                    <div className={s.starNumber}>(5364)</div> */}
                {/* </div> */}
              </div>
            {/* </div> */}
            <div>
              <a
                className={s.install}
                target="_blank"
                href="https://gomechanic.app.link/app_install"
              >
                INSTALL
              </a>
            </div>
          </div>
        </HideOnPC>
      ) : null}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketingHeader);
