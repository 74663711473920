import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './index.css';
import ExtendedWarranty from './ExtendedWarranty';
import ActiveCoverage from './ActiveCoverage';
import Insights from './Insights';
import BookClaim from './BookClaim'
import FAQ from './FAQ';
import Toast from '../Toast/Toast';
import { ClipLoader } from 'react-spinners';
import { getWarrantyClaimData } from '../../_services/api/getWarrantyClaimApi';
import { getFaqs } from '../../_services/api/getWarrantyListingApi';

function ClaimWarranty(props) {
  useStyles(s);
  const [activeLoader, updateActiveLoader] = useState(false);
  const [claimData, setClaimData] = useState({});
  const [faqsdata, setFaqsData] = useState({});
  const [toastConfig, setToastConfig] = useState({
    show: false,
    text: '',
  });

  const carExists = props.cardetails?.brand && props.cardetails?.model && props.cardetails?.fuel;

  useEffect(() => {
    let categoryId = 47;
    getFaqs(categoryId).subscribe((res) => {
      if(res.status == true) {
        setFaqsData(res.data.list);
        // console.log("data get",res);
      }
    }, (err) => {
      setToastConfig({ ...toastConfig, show: true, text: err.message });
    });
  },[]);

  useEffect(() => {
    if(carExists) {
      updateActiveLoader(true);
      getWarrantyClaimData(props.user?.id, props.cardetails?.fuel?.car_type_id, props.city?.id, props.userSelectedCarId).subscribe((res) => {
        if(res.status == true) {
          setClaimData(res);
          updateActiveLoader(false);
        }
      }, (err) => {
        setToastConfig({ ...toastConfig, show: true, text: err.message });
      });
    } else {
      console.log("car is not setting");
    }
  },[props.cardetails, props.city, props.user, props.userSelectedCarId]);

  return (<>
    {activeLoader ? 
      <div className={s.spinnerDiv}>
        <ClipLoader 
          size={40}
          color="#e73c33"
        />
      </div>
    : 
    <div className={s.ClaimWarrantyWrapper}>
      <ExtendedWarranty data={claimData?.data?.extended_warranty}/>
      <ActiveCoverage data={claimData?.data ?? {}}/>
      
      <Insights data={claimData?.data?.warranty_insights}/>
      <FAQ faqs={faqsdata}/>
      <BookClaim data={claimData?.data?.warranty_button}/>
    </div>
    }
    {toastConfig.show ? (
      <Toast
        hideHandler={setToastConfig}
        text={toastConfig.text}
      />
    ) : null}
  </>
  );
}

const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(ClaimWarranty);
