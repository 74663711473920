import HttpHeader from '../httpHeader';
import Global from '../../global';
import { Http } from '../../modules/http';

export const warrantyBookClaimCheckout = (body) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.api_get}v1/oauth/customer/warranty_book_claim`;
  return HttpHandler.post(
    url,
    body,
    headers,
  );
};
