import HttpHeader from '../httpHeader';
import { Http } from '../../modules/http';
import Global from '../../global';

export const sendAppLinkAPI = (mobile) => {
  const Headers = new HttpHeader();
  const HttpHandler = new Http();
  const ApiManager = new Global();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.api_get}get_applink?mobile=${mobile}`;
  return HttpHandler.get(url, headers);
};
