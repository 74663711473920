import { SET_FAQS_FOR_CITY } from '../constants';

export function setFaqs(value) {
  return {
    type: SET_FAQS_FOR_CITY,
    payload: {
      value,
    },
  };
}
