import * as yup from 'yup';

const schema = yup.object().shape({
  mobile: yup
    .number()
    .min(1000000000)
    .max(9999999999),
});

export default schema;
