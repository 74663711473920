// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".U82WB{position:relative;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;height:inherit}.l4eOt{font-size:medium;font-weight:500;font-stretch:normal;font-style:normal;letter-spacing:.15px;letter-spacing:.0094rem;text-align:left;color:#fff;cursor:pointer}._38bO5{position:absolute;top:-20px;top:-1.25rem;background-color:red;margin-left:calc(100% - 2.5rem);width:20px;width:1.25rem;height:20px;height:1.25rem;-webkit-clip-path:polygon(50% 50%,0 100%,100% 100%);clip-path:polygon(50% 50%,0 100%,100% 100%)}._37OpB{-webkit-box-shadow:0 -.25rem .875rem 0 rgba(0,0,0,.15),0 .0625rem .625rem 0 rgba(0,0,0,.12),0 .25rem .9375rem .3125rem rgba(0,0,0,.2);box-shadow:0 -.25rem .875rem 0 rgba(0,0,0,.15),0 .0625rem .625rem 0 rgba(0,0,0,.12),0 .25rem .9375rem .3125rem rgba(0,0,0,.2);-webkit-transition:all .8s;-o-transition:all .8s;transition:all .8s;opacity:0;top:44.8px;top:2.8rem;right:-12px;right:-.75rem;z-index:-1;border:.0625rem solid hsla(0,0%,60.8%,.4);border-top:.125rem solid #e02020;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;min-width:250px}._37OpB,._37OpB:before{position:absolute;background-color:#fff}._37OpB:before{content:\"\";top:-17px;top:-1.0625rem;margin-left:calc(100% - 2.5rem);width:20px;width:1.25rem;height:20px;height:1.25rem;-webkit-clip-path:polygon(50% 50%,0 100%,100% 100%);clip-path:polygon(50% 50%,0 100%,100% 100%);z-index:16}._37OpB:hover{opacity:1;z-index:85}.U82WB:hover ._37OpB{opacity:1;z-index:85;display:block}", ""]);
// Exports
exports.locals = {
	"container": "U82WB",
	"item": "l4eOt",
	"triangle": "_38bO5",
	"dropDown": "_37OpB"
};
module.exports = exports;
