/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { connect } from 'react-redux';
import s from '../components/variables.css';
import { setDeviceType } from '../actions/device';
import { deviceTypes } from '../_services/device';

const HideOnPC = (props) => {
  useStyles(s);

  if (props.isCrawlerRequest) return <div className={s.hideOnPc}>{props.children}</div>;
  return (
    <>
      {props.device !== deviceTypes.DESKTOP ? (
        <div className={s.hideOnPc} style={props.style}>{props.children}</div>
      ) : null}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    setDeviceAction: (value) => dispatch(setDeviceType(value)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HideOnPC);
