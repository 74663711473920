// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2-TwK{padding:0;display:grid;overflow:hidden}@-webkit-keyframes _3q8En{0%{margin-left:100%}to{margin-left:0}}@keyframes _3q8En{0%{margin-left:100%}to{margin-left:0}}._111w0{margin-top:20px;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;padding:10px;border:1px solid hsla(0,0%,60.8%,.5)}._39WSu{font-size:14px;font-weight:500;font-stretch:normal;font-style:normal;letter-spacing:.13px;text-align:left;color:#4a4a4a;margin-left:12px}._111w0>input,._111w0>input:focus{border:none;outline:none;width:100%}._1iYKv{-ms-flex-pack:justify;justify-content:space-between;font-size:16px;font-weight:700;font-stretch:normal;font-style:normal;letter-spacing:.14px;text-align:left;color:#282828}._1iYKv,._2M4Og{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}._1JO6V{margin-right:15px}._1JO6V,.R9ZWn{width:12px;height:12px}._3qhBa{-ms-flex:1 1;flex:1 1;display:-ms-flexbox;display:flex;overflow:auto;height:400px}.mY_6a{margin-top:30px;margin-bottom:15px;display:grid;grid-template-columns:repeat(3,minmax(80px,1fr));grid-row-gap:24px;width:100%;-ms-flex-align:baseline;align-items:baseline;overflow-y:auto;overflow-x:hidden}._1XCbN{margin-bottom:20px;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;-ms-flex:1 1;flex:1 1;cursor:pointer;-webkit-transition:-webkit-transform .2s;transition:-webkit-transform .2s;-o-transition:transform .2s;transition:transform .2s;transition:transform .2s,-webkit-transform .2s}._1XCbN:hover{-webkit-transform:scale(1.1);-ms-transform:scale(1.1);transform:scale(1.1)}._2z52s{width:88px;height:60px;-o-object-fit:contain;object-fit:contain}._25YEm{margin-top:10px;font-size:13px;font-weight:500;font-stretch:normal;font-style:normal;letter-spacing:.12px;text-align:left;color:#282828}", ""]);
// Exports
exports.locals = {
	"container": "_2-TwK",
	"searchBox": "_111w0",
	"searchInput": "_39WSu",
	"modalTitleContainer": "_1iYKv",
	"titleBackBtn": "_2M4Og",
	"backButton": "_1JO6V",
	"closeIcon": "R9ZWn",
	"root": "_3qhBa",
	"mainContainer": "mY_6a",
	"childrenContainer": "_1XCbN",
	"childImage": "_2z52s",
	"childName": "_25YEm",
	"marginAnimation": "_3q8En"
};
module.exports = exports;
