import HttpHeader from '../httpHeader';
import { Http } from '../../modules/http';

export const getServiceGuide = (cityId, serviceSlug, brandSlug) => {
  const Headers = new HttpHeader();
  const HttpHandler = new Http();
  let path = '';
  if (typeof window !== 'undefined') {
    path = window.location.pathname;
    let pathArr = path.split("/");
    pathArr = pathArr.filter(item=>!!item);
    if( pathArr.length>3 ) pathArr.splice(-1,1);
    path = `/${pathArr.join("/")}`;
  }
  const headers = Headers.get_token_headers();
  let queryStr = `cityId=${cityId}`;
  let url;

  if (serviceSlug) queryStr = `${queryStr}&serviceSlug=${serviceSlug}`;
  if (serviceSlug && brandSlug) queryStr = `${queryStr}&brandSlug=${brandSlug}`;
  url = `/api/v1/get-service-guide?${queryStr}&path=${path}`;
  // const url = `/api/v1/get-service-guide/?cityId=${cityId}${serviceSlug ? `&serviceSlug=${serviceSlug}` : ''}${brandSlug ? `&brandSlug=${brandSlug}` : ''}&path=${path}`;
  // const url =  `/api/v1/get-service-guide/?cityId=${cityId}${serviceSlug ? `&serviceSlug=${serviceSlug}` : ''}${brandSlug ? `&brandSlug=${brandSlug}` : ''}&path=/${citySlug}/${serviceSlug}/${brandSlug}`;


  return HttpHandler.get(url, headers);
};
