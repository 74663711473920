// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2wbaY{width:100%;overflow:auto;-ms-scroll-snap-type:mandatory;scroll-snap-type:mandatory}._2wbaY,._3Uw6p,.AOIPe{display:-ms-flexbox;display:flex}._3Uw6p,.AOIPe{-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:start;justify-content:flex-start;vertical-align:middle;-ms-flex-align:center;align-items:center}.AOIPe{margin-left:0;margin-top:-6px}._2wbaY::-webkit-scrollbar{display:none}._2OTPb{-webkit-text-emphasis:none;text-emphasis:none}.tGVWJ{padding:1.875rem 1.25rem 1.875rem 0}.tGVWJ,._28n3g{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;-ms-flex-wrap:nowrap;flex-wrap:nowrap}._28n3g{margin-top:10px}._2WvTH{padding:0 7px}._1sJhO{-webkit-transform:rotate(180deg)}._1sJhO,._1V7xh{width:24px;height:24px;cursor:pointer}._1V7xh{-o-object-fit:contain;object-fit:contain;margin-left:10px}@media only screen and (min-width:700px){._2wbaY{width:100%;margin-top:-5px;margin-left:6px}.AOIPe{margin-top:0}}", ""]);
// Exports
exports.locals = {
	"container": "_2wbaY",
	"flex": "_3Uw6p",
	"flex2": "AOIPe",
	"normalDiv": "_2OTPb",
	"flexScroll": "tGVWJ",
	"flexScroll2": "_28n3g",
	"customPadding": "_2WvTH",
	"leftArrow": "_1sJhO",
	"rightArrow": "_1V7xh"
};
module.exports = exports;
