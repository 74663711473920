// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2rv7W{position:relative;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center}._2UQnF{font-size:medium;font-weight:700;font-stretch:normal;font-style:normal;letter-spacing:.15px;letter-spacing:.0094rem;text-align:left;color:#fff;cursor:pointer}._2cjQJ{position:absolute;top:-20px;top:-1.25rem;background-color:red;margin-left:calc(50% - 1.25rem);width:20px;width:1.25rem;height:20px;height:1.25rem;-webkit-clip-path:polygon(50% 50%,0 100%,100% 100%);clip-path:polygon(50% 50%,0 100%,100% 100%)}.ZCWjf{-webkit-box-shadow:0 -.25rem .875rem 0 rgba(0,0,0,.15),0 .0625rem .625rem 0 rgba(0,0,0,.12),0 .25rem .9375rem .3125rem rgba(0,0,0,.2);box-shadow:0 -.25rem .875rem 0 rgba(0,0,0,.15),0 .0625rem .625rem 0 rgba(0,0,0,.12),0 .25rem .9375rem .3125rem rgba(0,0,0,.2);-webkit-transition:all .8s;-o-transition:all .8s;transition:all .8s;opacity:0;top:32px;top:2rem;z-index:-1;border:.0625rem solid hsla(0,0%,60.8%,.4);border-top:.125rem solid #e02020;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;min-width:150px}.ZCWjf,.ZCWjf:before{position:absolute;background-color:#fff}.ZCWjf:before{content:\"\";top:-17px;top:-1.0625rem;margin-left:calc(50% - 1.25rem);width:20px;width:1.25rem;height:20px;height:1.25rem;-webkit-clip-path:polygon(50% 50%,0 100%,100% 100%);clip-path:polygon(50% 50%,0 100%,100% 100%);z-index:16}.ZCWjf:hover,._2UQnF:hover+.ZCWjf{opacity:1;z-index:85}", ""]);
// Exports
exports.locals = {
	"container": "_2rv7W",
	"item": "_2UQnF",
	"triangle": "_2cjQJ",
	"dropDown": "ZCWjf"
};
module.exports = exports;
