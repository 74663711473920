/*
 Author : Rishabh Tyagi
 This component will contain the "Monsoon Services" section as in
 https://app.zeplin.io/project/5da0780780829f083448125a/screen/5da07e90a303922fd8070ede
*/

import React, { useState, useEffect } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { connect } from 'react-redux';
import s from './css/MonsoonResponsive.css';
import { setActiveServiceType } from '../../../actions/serviceType';
import generate from '../../../serverRoutes/urlGenerators/newUrl';
import history from '../../../history';
import PlaceholderImage from '../../PlaceholderImages/PlaceholderImage';
import autoUtmHistory from '../../../_services/autoUtmHistory';
import { tapOnMonsoonList, tapCuratedCustomRepairs, retailBannerClickEvent } from '../../../gtm/gtmServices';

const MobileMonsoonServiceCarousel = (props) => {
  useStyles(s);
  const [ data ,setData ] = useState({});
  const carExists = props.cardetails?.brand && props.cardetails?.model && props.cardetails?.fuel;

  const quryStr = (key) => {
    let anch = props.deeplink
   return decodeURIComponent((anch).replace(new RegExp(`^(?:.*[&\\?]${encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&')}(?:\\=([^&]*))?)?.*$`, 'i'), '$1'))
  }

  const catId = Number(quryStr('category_id'));
  const pageName = String(quryStr('pagename')); 

  // useEffect(() => {
  //   if(props.carId && carExists) {
  //     getServiceById(
  //       props.serviceTypeIndex,
  //       props.serviceData.id,
  //       props.carId,
  //       props.city?.id,
  //     ).subscribe((res) => {
  //       // console.log("re cfd dfdvv df f", res.data);
  //       setData(res.data)
  //     })
  //   }
  // }, [props.serviceData, props.cardetails, props.city]);

  return (
    <div 
      key={props.keyId}
      onClick={(e) => {
        e.preventDefault();
        retailBannerClickEvent(
          props?.user?.id, 'homepage', null, null, null, props.index, props.home.monsoonData.title,
        );
        if(pageName === 'AMC_FRAGMENT') {
          autoUtmHistory(history).push('/miles-membership');
        }
        else {
          const serviceType = props.servicesTypesList.find(
            (item) => { 
              if(pageName === 'SOS' && item.id === 50){
                return true;
              }
              else if(item.id === catId && pageName != 'SOS' )  {
                return true;
              }
              else {
                return false;
              }
            }
          ) 
          props.action(setActiveServiceType(serviceType || null));
          autoUtmHistory(history).push(`${generate({
            city: props.city,
            serviceType: serviceType || null,
            brand: props.cardetails?.brand,
            model: props.cardetails?.model,
            fuel: props.cardetails?.fuel,
          })}`);
          if(props.curated) {
            tapCuratedCustomRepairs({
              user_id: props.user?.id  ,
              custom_repair_name: props.serviceText, 
              deeplink: (`${generate({
                city: props.city,
                serviceType: serviceType || null,
                brand: props.cardetails?.brand,
                model: props.cardetails?.model,
                fuel: props.cardetails?.fuel,
              })}`),
              page_name: 'homepage'
            });
          } else {
            tapOnMonsoonList({
              category_id: catId,
              category: props.serviceData.name,
            });
        ``}
        }
      }}
    >
      <PlaceholderImage
        placeHolderImage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
        className={props.carPartsSection ? props.tag ? s.carPartsTagImg : s.carPartsImg : s.monsoonImg}
        loading="lazy"
        src={props.articleImage}
        alt={props.altTag ? props.altTag : "Monsoon"}
      />
      {props.tag && <div className={s.newTag}>{props?.tag}</div>}
      <div className={props.tag ? s.headingNewTag : s.monsoonHeading}>{props.serviceText}</div>
    </div>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileMonsoonServiceCarousel);
