// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1PX23{width:100%;border-collapse:collapse}._1AOcI{font-size:18px;font-size:1.125rem;font-weight:600;font-stretch:normal;font-style:normal;line-height:1.06;letter-spacing:.16px;letter-spacing:.01rem;text-align:left;color:#282828;opacity:1}._31F2F{background-color:#f6f6f6}._2-e8a>tr>td{color:#4a4a4a}._2-e8a,._2-e8a>tr>td{font-size:15px;font-size:.9375rem;font-weight:500;font-stretch:normal;font-style:normal;line-height:1.41;letter-spacing:.15px;letter-spacing:.0094rem;text-align:left;padding:1.125rem 1.0625rem;border:.0625rem solid hsla(0,0%,60.8%,.5)}._2-e8a,._1B_A4{background-color:#fff}._1WRX-{height:51px;height:3.1875rem;max-width:700px;max-width:43.75rem;font-size:13px;font-size:.8125rem;font-weight:400;font-stretch:normal;font-style:normal;line-height:1.46;letter-spacing:.12px;letter-spacing:.0075rem;text-align:left;color:#4a4a4a}.smFrW{width:100px;width:6.25rem}._1B_A4>tr>td{font-weight:400;text-align:left;color:#282828;padding:.875rem 1.0625rem;border:.0625rem solid hsla(0,0%,60.8%,.5)}._38NYD,._1B_A4>tr>td{font-size:15px;font-size:.9375rem;font-stretch:normal;font-style:normal;line-height:1.4;letter-spacing:.14px;letter-spacing:.0088rem}._38NYD{cursor:pointer;font-weight:500;position:relative;text-align:right}.a1S41{padding-top:10px}@media only screen and (min-width:700px){._1AOcI{font-size:18px;font-weight:600;font-stretch:normal;font-style:normal;line-height:1.06;letter-spacing:.16px;text-align:left;color:#282828;opacity:1}}@media only screen and (max-width:482px){._1B_A4>tr>td{word-break:break-word;text-align:center;padding:10px 5px}._2-e8a>tr>td{text-align:center;padding:10px 2px}._3COPo{padding-top:10px}}", ""]);
// Exports
exports.locals = {
	"table": "_1PX23",
	"heading2": "_1AOcI",
	"row": "_31F2F",
	"heading": "_2-e8a",
	"mainBody": "_1B_A4",
	"dateText": "_1WRX-",
	"col": "smFrW",
	"collapse": "_38NYD",
	"priceTableBottom": "a1S41",
	"tableExpansionStatus": "_3COPo"
};
module.exports = exports;
