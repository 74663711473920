import React, { useState, useEffect } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { connect } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import s from './LoginSectionDesktop.css';
import LoginUpperBar from '../../LoginPage/LoginUpperBar';
import MaterialInput from '../../MaterialInput/MaterialInput';
import CountryMaterialInput from '../../MaterialInput/CountryMaterialInput';
import RedBarMiddleContent from '../../RedBarMiddleContent/RedBarMiddleContent';
import { sendOtp, verifyOtp } from '../../../_services/api/OTPs';
import { saveLoginDetails } from '../../../logInUtilities/logInUtilities';
import {
  loginGtmEvent, initiateAccLoginGtmEvent, getAccOtpGtmEvent, resendAccOtpGtmEvent, retailInitiateLogin, retailGetOtpEvent, retailLoginEvent,
} from '../../../gtm/gtmServices';
import Toast from '../../Toast/Toast';
import { isMalaysiaRequest } from '../../../utilities';
import secure from '../../AccessoriesLoginPopUp/security.png';
// import { addMoengageScript } from '../../../accessoriesUtility';

const LoginSectionDesktop = (props) => {
  const steps = {
    PHONE_NUMBER_SCREEN: 1,
    OTP_SCREEN: 2,
    ENTER_DETAILS_SCREEN: 3,
  };

  const [toastConfig, setToastConfig] = useState({
    show: false,
    text: '',
  });

  const [step, setStep] = useState(steps.PHONE_NUMBER_SCREEN);
  const [otp, setOtp] = useState('');
  // var [maxLength, setMaxLength] = useState(props.isNigerianRequest ? 11 : 10);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    if (isMalaysiaRequest()) {
      if (props.lead && props.lead.mobile
        && (!(isNaN(props.lead.mobile)))
        && (props.lead.mobile.length === 9 || props.lead.mobile.length === 10 || props.lead.mobile.length === 11) && !phoneNumber.length) {
        setPhoneNumber(props.lead.mobile);
      }
    } else if (props.lead && props.lead.mobile
        && (!(isNaN(props.lead.mobile)))
        && props.lead.mobile.length === 10 && !phoneNumber.length) {
      setPhoneNumber(props.lead.mobile);
    }
    // if (props.accessories) initiateAccLoginGtmEvent('Header Login');
    retailInitiateLogin(props.useFor || 'sidebar');
  }, []);

  const editPhoneNumber = () => {
    // setPhoneNumber('');
    setStep(steps.PHONE_NUMBER_SCREEN);
    if (props.setStep) {
      props.setStep(steps.PHONE_NUMBER_SCREEN);
    }
  };

  const sendOtpFunction = () => {
    retailGetOtpEvent(props.useFor || 'sidebar', phoneNumber);
    setWaiting(true);
    sendOtp(phoneNumber, props.accessories).subscribe(
      (res) => {
        setWaiting(false);
        setStep(steps.OTP_SCREEN);
        if (props.setStep) {
          props.setStep(steps.OTP_SCREEN);
        }
        props.setToastConfig({
          show: true,
          text: `OTP sent to ${phoneNumber}`,
          showTick: true,
          time: 1500,
        });
      }, (err) => { console.error(err); setWaiting(false); },
    );
  };

  const onClickHandlerGenerator = () => {
    if (step === steps.PHONE_NUMBER_SCREEN) {
      return () => {
        if (isMalaysiaRequest()) {
          if (isNaN(phoneNumber) || !(phoneNumber.length == 9 || phoneNumber.length == 10 || phoneNumber.length == 11)) {
            setToastConfig({ ...toastConfig, show: true, text: 'Please enter phone number' });
          } else {
            sendOtpFunction();
          }
        } else if (isNaN(phoneNumber) || !(phoneNumber.length === 10)) {
          setToastConfig({ ...toastConfig, show: true, text: 'Please enter phone number' });
        } else {
          if (props.accessories) getAccOtpGtmEvent('Header Login');
          sendOtpFunction();
        }
      };
    }

    if (step === steps.OTP_SCREEN) {
      return () => {
        if (isNaN(otp) || otp.length !== 4) {
          alert('Check otp');
        } else {
          setWaiting(true);
          verifyOtp(phoneNumber, otp).subscribe(
            (res) => {
              // setStep(steps.ENTER_DETAILS_SCREEN);
              // if (props.accessories) addMoengageScript();
              setWaiting(false);
              if (res.status) {
                props.setConfirmation && props.setConfirmation(true);
                saveLoginDetails({ ...res, is_logged_in: true });
                props.setToastConfig({
                  show: true,
                  text: 'Logged In',
                  showTick: true,
                  time: 1500,
                });
                retailLoginEvent(
                  res.data.id,
                  props.useFor || 'sidebar',
                  phoneNumber,
                  true,
                  false,
                );
                if (props.accessories) {
                  loginGtmEvent({
                    phoneNumber,
                    success: true,
                    fire_screen: props.accessories,
                    user_id: res.data.id,
                  });
                }
                if (props.disappear) props.disappear();
                if (props.onLoggedIn) props.onLoggedIn();
              } else {
                props.setToastConfig({
                  show: true,
                  text: 'Wrong OTP',
                  showTick: false,
                  time: 1500,
                });
              }
            }, () => {
              setWaiting(false);
              retailLoginEvent(
                null,
                props.useFor || 'sidebar',
                phoneNumber,
                false,
                false,
              );
              if (props.accessories) { loginGtmEvent({ phoneNumber, success: false }); }
              props.setToastConfig({
                show: true,
                text: 'Verification Failed!',
                showTick: false,
                time: 1500,
              });
            },
          );
        }
      };
    }

    if (step === steps.ENTER_DETAILS_SCREEN) {
      return () => { };
    }
    return () => { };
  };

  const backClickHandlerGenerator = () => {
    if (step === steps.PHONE_NUMBER_SCREEN) {
      return () => { };
    }

    if (step === steps.OTP_SCREEN) {
      return () => setStep(steps.PHONE_NUMBER_SCREEN);
    }

    if (step === steps.ENTER_DETAILS_SCREEN) {
      return () => setStep(steps.PHONE_NUMBER_SCREEN);
    }
    return () => { };
  };

  const getUpperBar = (logo = true, noBackButton = false) => (
    <>
      {step === steps.PHONE_NUMBER_SCREEN && (
        <LoginUpperBar
          onBackClick={props.disappear}
          title="Login"
          subtitle="Enter your phone number to continue"
          logo={logo}
          noBackButton={noBackButton}
        />
      )}

      {step === steps.OTP_SCREEN && (
        <LoginUpperBar
          onBackClick={backClickHandlerGenerator()}
          title="Verification"
          subtitle={`OTP sent to ${phoneNumber}`}
          logo={logo}
          noBackButton={noBackButton}
        />
      )}

      {step === steps.ENTER_DETAILS_SCREEN && (
        <LoginUpperBar
          onBackClick={backClickHandlerGenerator()}
          title="Enter Details"
          subtitle="Enter your name and email"
          logo={logo}
          noBackButton={noBackButton}
        />
      )}
    </>
  );

  useStyles(s);
  const continueBtnText = () => {
    if (step === steps.PHONE_NUMBER_SCREEN) {
      return props.beforeSendOtpText || 'CONTINUE';
    }
    if (step === steps.OTP_SCREEN) {
      return props.afterSendOtpText || 'CONTINUE';
    }
    return 'CONTINUE';
  };
  const additionalPropsForPhone = (step === steps.OTP_SCREEN) ? {
    rightSideText: 'Change',
    rightSideTextClick: editPhoneNumber,
  } : {};
  return (
    <>
      {props.useFor !== 'checkout' ? getUpperBar(props.useFor !== 'popuplogin', props.useFor === 'popuplogin', step, phoneNumber) : null}
      <div className={s.formSection} style={{ ...(props.parentStyle ? props.parentStyle : {}) }}>
        <form
          onSubmit={(e) => e.preventDefault()}
          style={
            props.useFor === 'popuplogin' ? {
              padding: '1rem 1rem 0.5rem 1rem',
            } : {}
          }
        >
          {step === steps.PHONE_NUMBER_SCREEN && (
            <>
              <CountryMaterialInput
                inputProps={{
                  type: 'tel',
                  maxLength: isMalaysiaRequest() ? '11' : '10',
                  value: phoneNumber,
                }}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                  // if (e.target.value >= 10 && props.accessories) initiateAccLoginGtmEvent();
                  if (props.setPhoneNumber) props.setPhoneNumber(e.target.value);
                }}
                placeholder="Phone number"
              />
            </>
          )}

          {/* OTP SCREEN -- start */}
          {step === steps.OTP_SCREEN && (
            <>
              <CountryMaterialInput
                inputProps={{
                  type: 'tel',
                  maxLength: isMalaysiaRequest() ? '11' : '10',
                  value: phoneNumber,
                  disabled: true,
                }}
                placeholder="Phone number"
                rightSideText="Edit"
                rightSideTextClick={editPhoneNumber}
              />
              <MaterialInput
                inputProps={{
                  type: 'tel',
                  maxLength: 4,
                  value: otp,
                }}
                rightSideText="Resend OTP"
                rightSideTextClick={() => {
                  if (props.accessories) resendAccOtpGtmEvent('Header Login');
                  sendOtpFunction();
                }}
                onChange={(e) => {
                  setOtp(e.target.value);
                }}
                placeholder="One Time Password"
              />
            </>
          )}
          {/* OTP SCREEN -- end */}

          {step === steps.ENTER_DETAILS_SCREEN && (
            <>
              <>
                <CountryMaterialInput
                  inputProps={{
                    type: 'tel',
                    maxLength: isMalaysiaRequest() ? '11' : '10',
                  }}
                  placeholder="Phone number"
                />
                <MaterialInput
                  inputProps={{
                    type: 'tel',
                    maxLength: 4,
                  }}
                  placeholder="One Time Password"
                />
                <MaterialInput
                  inputProps={{
                    type: 'tel',
                    maxLength: 4,
                  }}
                  placeholder="Name"
                />
                <MaterialInput
                  inputProps={{
                    type: 'tel',
                    maxLength: 4,
                  }}
                  placeholder="Email Id"
                />

                <MaterialInput
                  inputProps={{
                    type: 'tel',
                    maxLength: 4,
                  }}
                  placeholder="Referral code (optional)"
                />
              </>
            </>
          )}
        </form>
        <div className={s.continueBtn}>
          <RedBarMiddleContent
            style={props.useFor === 'popuplogin' ? {
              padding: '0.8rem 0',
            } : {}}
            text={waiting ? <PulseLoader color="#FFF" size={10} /> : continueBtnText()}
            onClick={waiting ? () => false : onClickHandlerGenerator(step)}
          />

          {step === steps.ENTER_DETAILS_SCREEN ? (
            <div className={s.terms}>
              By pressing proceed, you agree to be contacted on "WhatsApp"
              regarding service delivery and T&C.
            </div>
          ) : null}
        </div>
        {props.accessories ? (
          <div className={s.footerNote}>
            <div className={s.icon}>
              <img src={secure} alt="secure" />
            </div>
            <div className={s.noteText}>
              All your data is secure within GoMechanic ecosystem
            </div>
          </div>
        ) : null}
      </div>
      {toastConfig.show ? (
        <Toast
          hideHandler={setToastConfig}
          text={toastConfig.text}
        />
      ) : null}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginSectionDesktop);
