import HttpHeader from '../httpHeader';
import { Http } from '../../modules/http';

export const getTopContentApi = (citySlug, selectedRegion) => {
    const Headers = new HttpHeader();
    const HttpHandler = new Http();

    let path = '';
  if (typeof window !== 'undefined') {
    path = window.location.pathname;
    let pathArr = path.split("/");
    pathArr = pathArr.filter(item=>!!item);
    if( pathArr.length>3 ) pathArr.splice(-1,1);
    path = `/${pathArr.join("/")}`;
  }

    const headers = Headers.get_token_headers();
    let queryStr = `city=${citySlug}`;
    let url;

    url = `/api/v1/get-top-content?${queryStr}&path=${path}`;
    return HttpHandler.get(url, headers);
};
