/* eslint-disable import/prefer-default-export */
import { SET_TIME_SELECT } from '../constants';

export function setTimeSelect(value) {
  return {
    type: SET_TIME_SELECT,
    payload: {
      value,
    },
  };
}
