import { SET_ORDER_DATE_TIME } from '../constants';

export function setOrderDateTime(value) {
  return {
    type: SET_ORDER_DATE_TIME,
    payload: {
      value,
    },
  };
}
