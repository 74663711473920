import React, { useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames';
import Modal from '../Modal/Modal';
import s from './ModalSelector.css';
import closeIcon from '../../../public/icons/close_icon_2x.png';

const ModalSelector = (props) => {
  useStyles(s);
  // Following state controls animation left to right
  const [viewByMargin, SetViewByMargin] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => SetViewByMargin(true), 200);
  //   return () => console.log('unmount');
  // }, []);

  return (
    <>
      <Modal
        noOverlay={props.noOverlay}
        height={props.height ? props.height : null}
        desktopCarSelection={props.desktopCarSelection}
        stopBackgroundScroll={props.stopBackgroundScroll}
        onOverlayClick={() => {
          if (props.hide) {
            props.hide();
          }
        }}
      >
        <div
          className={classNames(
            s.container,
          )}
          style={
          props.noOverlay
            ? {
              height: '100%',
              display: 'grid',
              gridTemplateRows: 'min-content min-content auto',
            }
            : {}
        }
        >
          <div className={s.modalTitleContainer}>
            <div className={s.titleBackBtn}>
              {!props.noBackButton
            && (
            <img
              loading="lazy"
              src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/CartViewer/69BB3456-EB2C-4B6B-A1F0-DC9CA44A3B77.svg"
              className={s.backButton}
              onClick={props.backClickHandler}
              alt="Back Button"
            />
            )}
              <span>{props.title}</span>
            </div>
            {(props.accessoriesCar || (props.initialCarDetails.brand && props.initialCarDetails.model
              && props.initialCarDetails.fuel && props.initialCarDetails.fuel.car_type_id))
              && (
              <img
                loading="lazy"
                onClick={props.hide}
                className={s.closeIcon}
                src={closeIcon}
                alt="Close"
              />
            )}
          </div>

          <div className={s.searchBox}>
            <img loading="lazy" src="/icons/search.svg" alt="Search" />
            <input
              className={s.searchInput}
              placeholder={props.searchPlaceholder}
              onChange={props.onSearchChange}
            />
          </div>

          {props.children}
        </div>
      </Modal>
    </>
  );
};

export default ModalSelector;
