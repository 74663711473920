// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2Qhj5{list-style:none;padding:0 1rem;-webkit-box-sizing:border-box;box-sizing:border-box;-ms-flex-direction:column;flex-direction:column;margin:0}._2Qhj5,._2Qhj5>li>button{display:-ms-flexbox;display:flex;grid-gap:16px;gap:16px;grid-gap:1rem;gap:1rem}._2Qhj5>li>button{width:100%;background-color:#fff;border:2px solid #e6e6e6;border-radius:10px;padding:1rem;-ms-flex-pack:justify;justify-content:space-between;font-weight:400;font-size:14px;font-size:.875rem;line-height:20px;line-height:1.25rem}._2Qhj5>li>button,._2Qhj5>li>button>div{-ms-flex-align:center;align-items:center;color:#000}._2Qhj5>li>button>div{display:-ms-flexbox;display:flex;grid-gap:8px;gap:8px;grid-gap:.5rem;gap:.5rem;text-align:start;font-size:16px;font-weight:500;font-stretch:normal;font-style:normal;line-height:1.11;letter-spacing:normal}._1hIz5._2sWoa ._14jKM{background-color:#105057}._3--2h{font-size:18px;font-weight:700;font-stretch:normal;font-style:normal;line-height:1.11;letter-spacing:normal;text-align:left;color:#000}._2iuKw{height:0;overflow:hidden;-webkit-transition:height .2s ease;-o-transition:height ease .2s;transition:height .2s ease}._2FuZS{padding:1rem;font-size:14px;font-size:.875rem;color:#000;font-weight:400}._1PnFG{cursor:pointer}", ""]);
// Exports
exports.locals = {
	"accordian": "_2Qhj5",
	"accordion_item": "_1hIz5",
	"active": "_2sWoa",
	"button": "_14jKM",
	"questionWrapper": "_3--2h",
	"answer_wrapper": "_2iuKw",
	"answer": "_2FuZS",
	"control": "_1PnFG"
};
module.exports = exports;
