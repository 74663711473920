import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './CountryMaterialInput.css';
import { isMalaysiaRequest } from '../../utilities';

const CountryMaterialInput = (props) => {
  useStyles(s);
  const inputChangeHandler = props.onChange ? props.onChange : () => { console.log('no onchange handler passed'); };
  const placeholder = props.placeholder ? props.placeholder : 'Sample';
  return (
    <>
      <div className={s.mainContainer}>
        <div className={s.countryContainer} style={props.style}>
            <div className={s.subContainer}>
                <img 
                    src={isMalaysiaRequest() ? "https://gomechprod.blob.core.windows.net/websiteasset/malaysia/appDetails/malaysia.svg" : "https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/LoginPage/%20Flag%402x.png"} 
                    alt="flag"
                    className={s.flagIcon}
                />
                <div className={s.numFormat}>{isMalaysiaRequest() ? "+60" : "+91"}</div>
            </div>
          <input
            className={s.input}
            {...props.inputProps}
            onChange={inputChangeHandler}
            placeholder={placeholder}
          />
          <div className={s.countryLabel}>{placeholder}</div>
        </div>
        {props.rightSideText
          ? (
            <span
              onClick={props.rightSideTextClick ? props.rightSideTextClick : () => false}
              className={s.rightSideText}
              style={{...(props.rightSideTextStyle ? props.rightSideTextStyle : {})}}
            >
              {props.rightSideText}
            </span>
          )
          : null}
      </div>
    </>
  );
};

export default CountryMaterialInput;
