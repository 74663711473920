// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3cIwp{background-color:#e5e5e5;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;grid-gap:16px;gap:16px;grid-gap:1rem;gap:1rem;letter-spacing:.5px}._1SFHG{height:100vh;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}", ""]);
// Exports
exports.locals = {
	"claimWarrantyWrapper": "_3cIwp",
	"spinnerDiv": "_1SFHG"
};
module.exports = exports;
