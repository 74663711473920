// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2JiwZ{margin-top:1rem;padding:16px;width:100%;-webkit-box-sizing:border-box;box-sizing:border-box;background:#fff;font-weight:400}._2yJ9X{margin:0;font-weight:600;font-size:18px;line-height:24px;color:#1a1a1a}._2Nvd5{margin-top:16px;width:100%;border-radius:4px;border:1px solid #e6e6e6;-webkit-box-sizing:border-box;box-sizing:border-box}._3nTU3{padding:16px;-ms-flex-pack:justify;justify-content:space-between}._3nTU3,._1EUEX{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}._1EUEX{border-radius:0 0 4px 4px;background:#eef4fc;padding:12px;width:100%;-webkit-box-sizing:border-box;box-sizing:border-box;text-align:center;color:#5195e0;-ms-flex-pack:center;justify-content:center}._1EUEX,.E9K-E{font-size:14px;line-height:20px;font-weight:500}.E9K-E{margin:0;color:#666}._2BD1x{margin-top:12px;font-weight:700;font-size:18px;line-height:24px;color:#1a1a1a}", ""]);
// Exports
exports.locals = {
	"insightsComponent": "_2JiwZ",
	"heading": "_2yJ9X",
	"insightsBox": "_2Nvd5",
	"insightsInnerBox1": "_3nTU3",
	"insightsInnerBox2": "_1EUEX",
	"savingText": "E9K-E",
	"amount": "_2BD1x"
};
module.exports = exports;
