// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".hDRUY{position:fixed;height:100%;width:100%;background:#000;opacity:.7;z-index:36;top:0;left:0}._1UOKm{display:none}._3lUdJ{display:block}.FtWxw{position:unset!important;margin:0!important;padding:0!important;width:100%!important;-webkit-transform:none!important;-ms-transform:none!important;transform:none!important}.Fr9Ly{width:90%;-webkit-box-sizing:border-box;box-sizing:border-box}.Fr9Ly,._17MWA{position:fixed;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);padding:5%;background:#fff;opacity:1;z-index:36}._17MWA{width:80%}@media only screen and (min-width:1024px){.Fr9Ly,._17MWA{width:450px;height:inherit;overflow-y:scroll;padding:1%;-webkit-box-sizing:border-box;box-sizing:border-box}.hDRUY{background:#071c2c;opacity:.5}}._2dK17{height:auto}", ""]);
// Exports
exports.locals = {
	"overlay": "hDRUY",
	"dNone": "_1UOKm",
	"dBlock": "_3lUdJ",
	"desktopSetting": "FtWxw",
	"modal": "Fr9Ly",
	"modal2": "_17MWA",
	"heightAuto": "_2dK17"
};
module.exports = exports;
