import React, { useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import s from './main.css';
import { createLead } from '../../_services/api/leads';

const Popup = (props) => {
  useStyles(s);
  const [mobile, setMobile] = useState('');
  const [submitting, setSubmitting] = useState(false);

  return (
    <div>
      <div onClick={props.hide} className={s.overlay} />
      <div className={classNames(s.modaldialog, s.modalheight)}>

        <div className={classNames(s.modalcontent, s.leavepopup)}>
          <div className={classNames(s.modalheader, s.landingnewheader)}>
            <button type="button" onClick={props.hide} className={classNames(s.close, s.customerphonenomodal)}>&times;</button>
          </div>
          <div className={s.modalbody}>
            <div className={s.newpopupimage}>
              <img src="/graphics/new-pop-up.png" alt="Customer Executive" />
            </div>
            { !props.index  ?
            <> 
            <div className={s.giveuscontact}>2,50,000 + customers trust us with their cars</div>
            <div className={s.popuphelp}>How can we help you?</div>
            </> :
            <div className={s.flexCol}>
              <div className={ props.bikeenquirs.popUpText[props.index-1].heading2 ? s.price1 : s.price11 }><div>{props.bikeenquirs.popUpText[props.index-1].heading1} -</div><div className={s.ml}>{props.bikeenquirs.popUpText[props.index-1].price1}</div></div>
              {  props.bikeenquirs.popUpText[props.index-1].heading2 ? 
              <div className={s.price2}><div>{props.bikeenquirs.popUpText[props.index-1].heading2} -</div><div className={s.ml}>{props.bikeenquirs.popUpText[props.index-1].price2}</div></div> : null }
            </div>
             }
            <div className={classNames(s.enterphoneno, s.centerblock)}>
              <input
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
                className={(s.phone, s.centerblock)}
                type="text"
                placeholder={props.isNigerianRequest ? "7020-700-617" :"8398-970-970"}
                name="phone"
                inputMode="numeric"
                pattern="[789][0-9]{9}"
                required
                autoComplete="off"
                maxLength="10"
              />
            </div>

            <div className={classNames(s.applyphonnumber, s.centerblock)}>
              <input
                onClick={(e) => {
                  e.preventDefault();
                  if (mobile.length !== 10 || isNaN(mobile)) {
                    alert('Please check your mobile number.');
                    return;
                  }
                  setSubmitting(true);
                  createLead(undefined, props.city, mobile, props.user.name).subscribe(
                    (res) => {
                      props.hide();
                    }, (error) => {
                      alert('Falied to request callback.');
                      props.hide();
                    },
                  );
                }}
                type="submit"
                className={s.submitbuttonpopuptext}
                value={submitting ? 'Please wait...' : 'REQUEST CALL BACK'}
              />
            </div>
          </div>
        </div>

      </div>
    </div>

  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Popup);
