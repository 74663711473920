import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getPriceListApi } from '../../_services/api/getPriceListApi';
import { priceListAction } from '../../actions/priceListAction';
import s from './responsive.css';

const PricingTable = (props) => {
  useStyles(s);
  const [limit, setLimit] = useState({val: 3, set: true});
  var dateobj = new Date();
  var today = dateobj.toLocaleDateString();

  useEffect(() => {
    setLimit({...limit, val: 3});
  }, [props.priceListData])

  function compare(a, b) {
    if (a.price < b.price) {
      return -1;
    }
    if (a.price > b.price) {
      return 1;
    }
    return 0;
  }

  if (!props.priceListData || !props.priceListData.length) {
    return null;
  }
  else {
    props.priceListData.sort(compare);
  }

  return (
    <>
      <div className={s.tableContainer}>
        <table className={s.table}>
          <col className={s.col} />
          <col className={s.col} />
          <col className={s.col} />
          <thead className={s.heading}>
            <tr>
              <td>Services Type</td>
              <td>Price Starts From ({props.currency.symbol})</td>
              <td>Savings</td>
            </tr>
          </thead>

          <tbody className={s.mainBody}>
            {
              props.priceListData && (limit.set ? props.priceListData.slice(0,limit.val) : props.priceListData).map(
                (data, index) => {
                  return (
                    <tr className={s.row} key={data.id}>
                      <td>
                        {data.name}
                      </td>
                      <td>
                        {data.price}
                      </td>
                      <td>
                        {data.savings}
                      </td>
                    </tr>
                  );
                },
              )
            }
          </tbody>
        </table>
        <div className={s.priceTableBottom}>
          <span className={s.dateText}>This data was last updated on {today}</span>
          {props.priceListData.length > 3 && <div className={s.tableExpansionStatus}>
            {limit.set ? (
              <div className={s.collapse} onClick={() => {
                setLimit({...limit, set: false});
                let brandSlug = props.cardetails && props.cardetails?.brand && props.cardetails?.brand?.slug_id;
                if(props.priceListData.length < 5){
                  getPriceListApi(props.city && props.city?.sitemap_name,props.serviceType && props.serviceType.slug,brandSlug)
                .subscribe((res) => {
                  if (!!res && res.success) {
                    props.action(priceListAction(res.data || []));
                  }
                }, (error) => {
                  console.error(error);
                });
                }
              }}
              >
                SEE MORE
              </div>
            )
              : (
                <div className={s.collapse} onClick={() => {
                  setLimit({...limit, set: true});
                }}
                >
                  SEE LESS
                </div>
              )}
          </div>}
        </div>

      </div>
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PricingTable);
