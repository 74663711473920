// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2wctI{padding:1.25rem 2.1875rem;overflow:scroll}._2rFCg{margin-top:1rem;cursor:pointer}._3ZnPk{margin-top:.625rem;font-size:12px;font-weight:600;font-stretch:normal;font-style:normal;line-height:1.5;letter-spacing:.11px;text-align:center;color:#9b9b9b}.vG73s{margin-top:1.875rem}.vG73s>._2_j4p{display:-ms-flexbox;display:flex;margin:auto;width:45px;width:2.8125rem}.vG73s>._2_j4p>img{width:100%;height:auto;-o-object-fit:contain;object-fit:contain}.vG73s>._2onTJ{margin-top:10px;font-size:16px;font-size:1rem;font-weight:400;text-align:center;color:#4a4a4a}", ""]);
// Exports
exports.locals = {
	"formSection": "_2wctI",
	"continueBtn": "_2rFCg",
	"terms": "_3ZnPk",
	"footerNote": "vG73s",
	"icon": "_2_j4p",
	"noteText": "_2onTJ"
};
module.exports = exports;
