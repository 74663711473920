import { accessoriesProductClickEvent, accessoriesProductDetailsViewEvent } from './gtm/gtmServices';

export const getDiscount = (mrp, price) => (((mrp - price) / mrp) * 100).toFixed(0);

const getFilterListParam = (filter) => {
  if (filter.field_type === 'range') {
    if (filter.list.selected && (typeof (filter.list.selected.min) !== undefined || filter.list.selected.max))
      return `&min_${filter.title}=${filter.list.selected.min || filter.list[`min_${filter.title}`]}&max_${filter.title}=${filter.list.selected.max || filter.list[`max_${filter.title}`]}`;
    return '';
  } else {
    let checkedVal = '';
    const filterheader = filter.is_attribute ? `&att_${filter.title}=` : `&${filter.title}=`;;
    if (filter.list.length) {
      filter.list.forEach((fItem) => {
        if (fItem.selected) {
          if (filter.title.toLowerCase() === 'subcategories') checkedVal = `${checkedVal + fItem.key},`;
          else {
            checkedVal = `${checkedVal + encodeURIComponent(fItem.value)},`;
          }
        }
      });
    }
    if (checkedVal) {
      checkedVal = checkedVal.slice(0, -1);
      return filterheader + checkedVal;
    }
    return '';
  }
}

export const getFilterParam = (filterList) => {
  let filterParam = '';
  filterList.forEach((filter) => {
    filterParam += getFilterListParam(filter);
  });
  return filterParam;
};

export const getCategoryfromURL = (urlparam, categoryList) => {
  let category = null;
  Object.keys(categoryList).forEach((key) => {
    categoryList[key].forEach((item) => {
      if (item.slug === urlparam.category) category = item;
    });
  });
  return category;
};

export const getSegmentfromURL = (urlparam, segmentList, categoryList) => {
  let segment = null;
  Object.keys(categoryList).forEach((key) => {
    categoryList[key].forEach((item) => {
      if (item.slug === urlparam.category) segment = key;
    });
  });

  if (segment) {
    [segment] = segmentList.filter((item) => item.name === segment);
  }

  return segment;
};

export const getActiveVariantList = (variantoptions, variantList, title, value) => {
  const list = [];
  variantoptions.forEach((option) => {
    if (option.title === title) list.push(option.value);
    else {
      const tempListItem = [];
      variantList.forEach((variant) => {
        if (variant[title.toLowerCase()] === value) {
          if (variant.attributes && variant.attributes[option.title.toLowerCase()]) {
            tempListItem.push(variant.attributes[option.title.toLowerCase()]);
          } else tempListItem.push(variant[option.title.toLowerCase()]);
        } else if (variant.attributes && variant.attributes[title.toLowerCase()] === value) {
          if (variant.attributes[option.title.toLowerCase()]) {
            tempListItem.push(variant.attributes[option.title.toLowerCase()]);
          } else tempListItem.push(variant[option.title.toLowerCase()]);
        }
      });
      list.push(tempListItem);
    }
  });
  return list;
};

export const getVariant = (variantoptions, variantList, prevVarient, title, value) => {
  for (let i = 0; i < variantList.length; i += 1) {
    let index = i;
    for (let j = 0; j < variantoptions.length; j += 1) {
      if (variantoptions[j].title === title) {
        if ((variantList[i][title.toLowerCase()] && variantList[i][title.toLowerCase()] !== value)
        || ((variantList[i].attributes && variantList[i].attributes[title.toLowerCase()] && variantList[i].attributes[title.toLowerCase()] !== value))) {
          index = -1;
          break;
        }
      } else if ((variantList[i][variantoptions[j].title.toLowerCase()] && variantList[i][variantoptions[j].title.toLowerCase()] !== prevVarient[variantoptions[j].title.toLowerCase()])
      || ((variantList[i].attributes && variantList[i].attributes[variantoptions[j].title.toLowerCase()] && variantList[i].attributes[variantoptions[j].title.toLowerCase()] !== prevVarient.attributes[variantoptions[j].title.toLowerCase()]))) {
        index = -1;
        break;
      }
    }
    if (index !== -1) return variantList[index];
  }

  for (let i = 0; i < variantList.length; i += 1) {
    if ((variantList[i][title.toLowerCase()] && variantList[i][title.toLowerCase()] === value)
    || (variantList[i].attributes && variantList[i].attributes[title.toLowerCase()] && variantList[i].attributes[title.toLowerCase()] === value)) return variantList[i];
  }
};

export const getCarParameter = (accessoriesCarDetail) => {
  if (accessoriesCarDetail && accessoriesCarDetail.model && accessoriesCarDetail.year) {
    return `&car_model_id=${accessoriesCarDetail.model.id}&car_year=${accessoriesCarDetail.year}`;
  }
  return null;
};

export const getSegmentfromSlug = (segmentSlug, segmentList) => {
  if (segmentSlug) {
    const [match] = segmentList.filter((segment) => segment.slug === segmentSlug);
    if (match) return match;
  }
  return null;
};

export const getProductImpressionsList = (productList, list) => {
  const impressionsList = [];
  let i = 0;
  const limit = productList.length > 30 ? 30 : productList.length;
  while (i < limit) {
    impressionsList.push({
      id: productList[i].sku_code || '',
      name: productList[i].title,
      list_name: list,
      brand: productList[i].brand_name,
      category: `${productList[i].segment_name}/${productList[i].category_name}`,
      list_position: i + 1,
      price: productList[i].price,
    });
    i += 1;
  }
  return impressionsList;
};

export const fireProductClickEvent = (item, index, list) => {
  const product = {
    id: item.sku_code || '',
    name: item.title,
    brand: item.brand_name,
    list_position: index + 1,
    price: item.price,
  };
  accessoriesProductClickEvent({ product, list });
};

export const fireProductDetailsViewEvent = (item, variant) => {
  const product = {
    id: variant || '',
    name: item.title,
    brand: item.brand_name,
    variant,
    price: item.price,
    segment_id: item.segment_id,
    segment_name: item.segment_name,
    sub_segment_id: item.category_id,
    sub_segment_name: item.category_name,
  };
  accessoriesProductDetailsViewEvent({ product });
};

export const getCartItemList = (orderLines) => {
  const productList = [];
  let i = 0;
  const limit =  (orderLines ? (orderLines?.length > 30 ? 30 : orderLines?.length) : 0) ?? 0;
  while (i < limit) {
    productList.push({
      id: orderLines[i].sku_code,
      name: orderLines[i].product_title,
      brand: orderLines[i].brand_name,
      category: `${orderLines[i].segment_name}/${orderLines[i].category_name}`,
      price: orderLines[i].total,
      quantity: orderLines[i].quantity,
    });
    i += 1;
  }
  return productList;
};

export const getPromotionList = (promotions, position, creative) => {
  const promotionList = promotions.map((item, index) => ({
    id: item._id || index,
    name: item.product_name || item.category_name || item.segment_name || `${item.text} ${item.sub_text}`,
    creative_name: creative || `${item.banner_type}_banner`,
    creative_position: position,
  }));

  return promotionList;
};

export const sortAccessoriesCartItems = (orderLines) => {
  const cartItems = orderLines.filter((item) => !item.is_installation_service);
  if (!cartItems) return [];
  const sortedOrderLines = [];
  for (let i = 0; i < cartItems.length; i += 1) {
    const installationService = orderLines.find(
      (item) => item.installation_for_product_id && item.installation_for_product_id === cartItems[i].product_id,
    );
    if (installationService) cartItems[i].reduceMargin = true;
    sortedOrderLines.push(cartItems[i]);
    if (installationService) sortedOrderLines.push(installationService);
  }
  return sortedOrderLines;
};

export const isInstallationServiceExist = (orderLines) => {
  let flag = false;
  for (let i = 0; i < orderLines.length; i += 1) {
    if (orderLines[i].is_installation_service) {
      flag = true;
    }
  }
  return flag;
};

export const getFilterFromQueryParam = (param, filterList) => {
  const tempFilterList = JSON.parse(JSON.stringify(filterList));
  const len = tempFilterList.length;
  for (let i = 0; i < len; i += 1) {
    if (tempFilterList[i].field_type === 'range') {
      tempFilterList[i].list = {
        ...tempFilterList[i].list,
        selected : null,
      }
    } else {
      const len2 = tempFilterList[i].list.length;
      for (let j = 0; j < len2; j += 1) {
        tempFilterList[i].list[j].selected = false;
      }
    }
  }
  const paramArray = param.split('::');
  for (let pIndx = 0; pIndx < paramArray.length; pIndx += 1) {
    const pfilterData = paramArray[pIndx].split(':');
    const pfilterTitle = pfilterData[0];
    const pfilterKeys = pfilterData[1].split(',');
    for (let fIndx = 0; fIndx < tempFilterList.length; fIndx += 1) {
      if (tempFilterList[fIndx].title.toLowerCase() === pfilterTitle.toLowerCase()) {
        if (tempFilterList[fIndx].field_type === 'range') {
          tempFilterList[fIndx].list = {
            ...tempFilterList[fIndx].list,
            selected : { 
              min:pfilterKeys[0],
              max:pfilterKeys[1]
            },
          }
        } else {
          for (let k = 0; k < tempFilterList[fIndx].list.length; k += 1) {
            const index = pfilterKeys.indexOf(tempFilterList[fIndx].list[k].value.toLowerCase());
            if (index !== -1) tempFilterList[fIndx].list[k].selected = true;
          }
        }
      }
    }
  }
  return tempFilterList;
};

export const getQueryParamFromFilter = (filterList) => {
  let queryParam = '';
  const flen = filterList.length;
  for (let i = 0; i < flen; i += 1) {
    if (filterList[i].field_type === 'range') {
      if (filterList[i].list.selected)
        queryParam += filterList[i].title.toLowerCase() + ':' + filterList[i].list.selected.min + ',' + filterList[i].list.selected.max + '::'
    } else {
    const listLen = filterList[i].list.length;
    let listParam = '';
    for (let j = 0; j < listLen; j +=1) {
      if (filterList[i].list[j].selected)
        listParam = listParam + filterList[i].list[j].value.toLowerCase() + ',';
    }
    if (listParam.length) queryParam = queryParam + filterList[i].title.toLowerCase() + ':' + listParam.slice(0, -1) + '::';
    }
  }
  return queryParam.slice(0, -2);
}

export const addMoengageScript = () => {
  const scriptData = `(function(i,s,o,g,r,a,m,n){i.moengage_object=r;t={};q=function(f){return function(){(i.moengage_q=i.moengage_q||[]).push({f:f,a:arguments})}};f=['track_event','add_user_attribute','add_first_name','add_last_name','add_email','add_mobile','add_user_name','add_gender','add_birthday','destroy_session','add_unique_user_id','moe_events','call_web_push','track','location_type_attribute'],h={onsite:["getData","registerCallback"]};for(k
    in f){t[f[k]]=q(f[k])}for(k in h)for(l in h[k]){null==t[k]&&(t[k]={}),t[k][h[k][l]]=q(k+"."+h[k][l])}a=s.createElement(o);m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m);i.moe=i.moe||function(){n=arguments[0];return
    t};a.onload=function(){if(n){i[r]=moe(n)}}})(window,document,'script','https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js','Moengage')
    Moengage = moe({
    app_id:"KNK1ECDXXTG9RNPE491FOFIL",
    debug_logs: 1
    });`;
  const scriptForMoengage = document.createElement('script');
  scriptForMoengage.type = 'text/javascript';
  scriptForMoengage.innerHTML = scriptData;
  document.head.appendChild(scriptForMoengage);
};

export const setModifiedRangeValue = (list, prevfilterIndex, prevFilterTitle, selectedValue) => {
  if (list[prevfilterIndex].title === prevFilterTitle) list[prevfilterIndex].list.selected = selectedValue;
  else {
    for(let idx = 0; idx < list.length; idx += 1) {
      if (list[idx].title === prevFilterTitle)
      list[idx].list.selected = selectedValue;
    }
  }
  return list;
};

export const setModifiedMultiSelectValue = (list, prevfilterIndex, prevFilterTitle, fItemIndex, fItemValue) => {
  if (list[prevfilterIndex] && list[prevfilterIndex].title === prevFilterTitle
      && list[prevfilterIndex].list[fItemIndex] && list[prevfilterIndex].list[fItemIndex].value === fItemValue
    )
    list[prevfilterIndex].list[fItemIndex].selected = true;
  else {
    for(let idx = 0; idx < list.length; idx += 1) {
      if (list[idx].title === prevFilterTitle) {
        for ( let fidx = 0; fidx < list[idx].list.length; fidx += 1) {
          if (list[idx].list[fidx].value === fItemValue)
            list[idx].list[fidx].selected = true;
        }
      }
    }
  }
  return list;
};

export const getModifiedFilters = (newList, oldList) => {
  let modifiedList = JSON.parse(JSON.stringify(newList));
  const listLen = oldList.length;
  for (let i = 0; i < listLen; i += 1) {
    if (oldList[i].field_type === 'range') {
      if (oldList[i].list.selected) {
        modifiedList = setModifiedRangeValue(
          modifiedList, i, oldList[i].title, oldList[i].list.selected,
        );
      }
    } else {
      const flen = oldList[i].list.length;
      for (let j = 0; j < flen; j+= 1) {
        if (oldList[i].list[j].selected) {
          modifiedList = setModifiedMultiSelectValue(
            modifiedList, i, oldList[i].title, j, oldList[i].list[j].value,
          );
        }
      }
    }
  }
  return modifiedList;
};

export const getBlogsFromCategorySlug = (blogsList, categoryslug) => {
  const filteredList = blogsList.filter((blog) => blog.acc_subcategory_slug === categoryslug);
  return filteredList || [];
};

export const getBlogsFromBrandSlug = (blogsList, brandSlug) => {
  const filteredList = blogsList.filter((blog) => blog.acc_brand_name === brandSlug);
  return filteredList || [];
};

export const getUrlParamFromObject = (obj) => {
  let searchParam = '';
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i += 1) {
    searchParam += `&${keys[i]}=${encodeURIComponent(obj[keys[i]])}`;
  }
  return searchParam;
};