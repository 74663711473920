import React, { useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import WarrantyDetails from '../WarrantyDetail'
import s from './index.css';

function WarrantyItem({ item, index, style = {} }) {
  useStyles(s);
  const [showDetail, setShowDetail] = useState(false);
  return (
    <>
      {showDetail ? <WarrantyDetails data={item} handleBackOnWarrantyDetailModal={() =>setShowDetail(false)}/> : null}
      <section className={s.middleComponent} key={index} {...style}>
        <div>
          <h2 className={s.heading}>{item?.detail?.[0].heading}</h2>
          {item?.sub_categories?.[0]?.is_claimed ? <button disabled className={s.approvedGreen}>{item?.sub_categories?.[0]?.is_claimed_text}</button> : ''}
        </div>
        {(item.detail?.[0].list?.length ? item.detail?.[0].list : [])
          ?.slice(0, 3)
          ?.map((item, indexx) => (
            <div className={s.item} key={indexx}>
              <div className={s.itemDetails}>{item?.text}</div>
              {/* <img
                src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/retail%20warranty%20page/tickIcon.svg"
                alt="tick"
              /> */}
            </div>
          ))}
        <div
          className={s.seeAll}
          onClick={() => {
            setShowDetail(true);
          }}
        >
          {'See More'}
        </div>
      </section>
    </>
  );
}

export default WarrantyItem;
