// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1_1m8{display:grid;grid-template-rows:auto;margin-top:3.4375rem}._2mBsG,._3OgNF{display:-ms-flexbox;display:flex}._3OgNF{-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:start;justify-content:flex-start;-ms-flex-align:center;align-items:center;margin-right:2.8125rem}._2zMUa{padding:.625rem .9375rem;font-size:22px;font-size:1.375rem;font-weight:700;font-stretch:normal;font-style:normal;line-height:1.05;letter-spacing:.2px;letter-spacing:.0125rem;text-align:left;color:#282828}._2zMUa,._2aV85{background-color:#f1f1f1}._2aV85{width:2px;width:.125rem;height:100%}._3ibB2{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;margin-right:5rem}._3OtVI{font-size:22px;font-size:1.375rem;font-weight:600;line-height:1.05;letter-spacing:.2px;letter-spacing:.0125rem;color:#282828;margin-bottom:1.375rem}.j9kuV,._3OtVI{font-stretch:normal;font-style:normal;text-align:left}.j9kuV{font-size:15px;font-size:.9375rem;font-weight:400;line-height:1.27;letter-spacing:.14px;letter-spacing:.00875rem;color:#4a4a4a}._1ba7H{width:240px;width:15rem;margin-bottom:1.5625rem;-o-object-fit:cover;object-fit:cover;margin-left:auto}._1ba7H>img{height:96px;height:6rem;-o-object-fit:none;object-fit:none;margin-left:auto}", ""]);
// Exports
exports.locals = {
	"container": "_1_1m8",
	"item": "_2mBsG",
	"numberContainer": "_3OgNF",
	"numberItem": "_2zMUa",
	"numberLine": "_2aV85",
	"titleSubtitle": "_3ibB2",
	"title": "_3OtVI",
	"subtitle": "j9kuV",
	"image": "_1ba7H"
};
module.exports = exports;
