import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { connect } from 'react-redux';
import s from './LocationTypeSelector.css';
import { addAddress } from '../../_services/api/addressApi';
import { saveUserDetails } from '../../actions/user';

let mapRef = null;
const LocationTypeSelector = (props) => {
  useStyles(s);
  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        return 'User denied the request for Geolocation.';
      case error.POSITION_UNAVAILABLE:
        return 'Location information is unavailable.';
      case error.TIMEOUT:
        return 'The request to get user location timed out.';
      case error.UNKNOWN_ERROR:
        return 'An unknown error occurred.';
      default:
        return 'Unknown Error.';
    }
  };

  const reverseGeoCode = (position) => {
    const LatLong = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    };
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({
      location: {
        lat: LatLong.latitude,
        lng: LatLong.longitude,
      },
    }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          const userId = props.user.id;
          const data = {
            address_1: results[0].formatted_address,
            address_2: '',
            object_id: userId,
            longitude: LatLong.longitude,
            latitude: LatLong.latitude,
          };
          addAddress(data).subscribe(
            (res) => {
              props.dispatch(saveUserDetails(
                { ...props.user, address: props.user.address ? [...props.user.address, res.data] : [res.data] },
              ));
              if (props.setAddressId) props.setAddressId(res.data.id);
              if (props.hide) {
                props.hide();
              }
            }, console.error,
          );
        }
      }
    });
  };


  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(reverseGeoCode, showError);
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  return (
    <>
      <div
        style={{ display: 'none' }}
        ref={(el) => {
          mapRef = el;
        }}
      />
      <div className={s.locationTypeScreen}>
        <img className={s.image} src="/graphics/location-type-selector.svg" alt="Select your location" />
        <div className={s.flexCenter}>
          Where do you want the service?
        </div>
        <div
          className={s.btnWithBg}
          onClick={getLocation}
        >
          <img src="/graphics/gps-white.svg" />
          <span>YOUR CURRENT LOCATION</span>
        </div>
        <div
          onClick={() => {
            if (props.showSearchPage) {
              props.showSearchPage();
            }
          }}
          className={s.btnWithBg}
          style={{
            border: 'solid 1px #9b9b9b',
            backgroundColor: '#fff',
            marginTop: '1.5rem',
          }}
        >
          <img src="/graphics/search-magnifier.svg" />
          <span style={{
            color: '#4a4a4a',
          }}
          >
            SOME OTHER LOCATION
          </span>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: (action) => dispatch(action),
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationTypeSelector);
