import React from 'react';

const BodyWrapper = (props) => (
  <div
    style={{
      marginTop: props.headerHeight,
    }}
  >
    {props.children}
  </div>
);
export default BodyWrapper;
