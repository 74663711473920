import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import s from './PcHomepageNavigationBar.css';
import { HomepageSections, navigationTexts, navigationTextsNg } from '../../constants';

const PcHomepageNavigationBar = (props) => {
  useStyles(s);
  // const itemTexts = [
  //   'Our Services',
  //   'How GoMechanicWorks',
  //   'GoMechanic Benefits',
  //   'Another one',
  //   'Brands We Service',
  //   'Fleets of Cars',
  //   'A long text is here',
  // ];

  const itemTexts = Object.keys(HomepageSections);

  const [currentSelected, setCurrentSelected] = useState(0);
  return (
    <>
      <div className={s.stickyTop}>
        <div className={s.container}>
          <img
            loading="lazy"
            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/PcHomeForm/FF3733C0-2200-4562-A654-5C44240968CE.svg"
            alt="arrow"
            style={{
              transform: 'rotate(180deg)',
            }}
            className={s.arrow}
            onClick={
                 () => {
                   if (currentSelected - 1 >= 0) {
                     try {
                       document.getElementById(itemTexts[currentSelected - 1]).scrollIntoView();
                       setCurrentSelected(currentSelected - 1);
                       window.scroll(0, window.scrollY - 80);
                     } catch (e) {}
                   }
                 }
               }
          />

          <div className={s.inifinteScrollContainer}>
            <div className={s.innerContainer}>
              {itemTexts.map((item, i) => (
                <div
                  key={`${item}_NAVIGATOR`}
                  id={`${item}_NAVIGATOR`}
                  onClick={() => {
                    try {
                      document.getElementById(item).scrollIntoView();
                      setCurrentSelected(i);
                      window.scroll(0, window.scrollY - 80);
                    } catch (e) {}
                  }}
                  className={s.item}
                  style={{ cursor: 'pointer' }}
                >
                  {props.isNigerianRequest ? (navigationTextsNg[item]) : (navigationTexts[item])}
                </div>
              ))}
            </div>
          </div>

          <img
            loading="lazy"
            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/PcHomeForm/FF3733C0-2200-4562-A654-5C44240968CE.svg"
            className={s.arrow}
            alt="arrow"
            onClick={
              () => {
                if (currentSelected + 1 < itemTexts.length) {
                  try {
                    document.getElementById(itemTexts[currentSelected + 1]).scrollIntoView();
                    setCurrentSelected(currentSelected + 1);
                    window.scroll(0, window.scrollY - 80);
                  } catch (e) {}
                }
              }
            }
          />
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PcHomepageNavigationBar);
