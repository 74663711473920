import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './sidebar.css';

const CallButton = (props) => {
  useStyles(s);
  return (
    <div className={s.callButtonContainerMain}>
      <div className={s.callButtonContainer} style={{ ...props.customizeButton }}>
        {!props.callButtonHide
        && (
        <img loading='lazy'
          className={s.callIcon}
          src="https://cdn.zeplin.io/5da0780780829f083448125a/assets/FDF52D3A-DEA3-4CB3-A0CA-F6CC31EC2E85.svg"
          alt="Call Now"
        />
        )}
        <span className={s.callText}>{props.text}</span>
      </div>
    </div>
  );
};
export default CallButton;
