import React, { useState } from 'react';
import { connect } from 'react-redux';
import ModalSelector from './ModalSelector';
import s from './ModalSelector.css';
import { fuelIconProvider, slugify } from '../../utilities';
import generate from '../../serverRoutes/urlGenerators/newUrl';

import PlaceholderImage from '../PlaceholderImages/PlaceholderImage';
import defaultCarImage from './default_car.png';
import { deviceTypes } from '../../_services/device';

const FuelTypeSelector = (props) => {
  const fuelTypeSelectionHandler = (fuel) => {
    props.onFuelSelect(fuel);
    props.goToNext();
  };
  const [searchText, setSearchText] = useState('');
  const searchHandle = (e) => {
    setSearchText(e.target.value);
  };
  const search = (text) => text.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
  const fuels = !props.accessories
    ? (props.cardetails?.model?.fuel || props.cardetails?.model?.fuel_type)
    : (props.accessoriesCarDetail.model.fuel || props.accessoriesCarDetail.model.fuel_type);
  return (
    <ModalSelector
      initialCarDetails={props.initialCarDetails}
      searchPlaceholder="Search Fuel Type"
      onSearchChange={searchHandle}
      noOverlay={props.noOverlay}
      desktopCarSelection={props.desktopCarSelection}
      title="Select Fuel Type"
      hide={props.hide}
      backClickHandler={props.goToPrev}
      stopBackgroundScroll={props.device === deviceTypes.MOBILE}
    >
      <div className={s.root} style={props.noOverlay ? { height: 'auto' } : {}}>
        <div className={s.mainContainer}>
          {fuels
            .filter((fuel) => search(fuel.name))
            .map((fuel) => (
              <a
                key={fuel.name}
                href={
                  `${props.apiEndpoints.frontend_serverWithProtocol}${
                    generate({
                      city: props.city,
                      subCity: props.selectedRegion || null,
                      serviceType: props.serviceType || props.servicesTypesList[0],
                      brand: props.cardetails?.brand,
                      model: props.cardetails?.model,
                      fuel,
                    })
                  }`
                }
                style={{
                  textDecoration: 'none',
                }}

              >
                <div
                  key={slugify(fuel.name)}
                  className={s.childrenContainer}
                  style={props.noOverlay ? { marginBottom: '0' } : {}}
                  onClick={(e) => { e.preventDefault(); e.stopPropagation(); fuelTypeSelectionHandler(fuel); }}
                >
                  <PlaceholderImage
                    placeHolderImage={defaultCarImage}
                    loading="lazy"
                    src={fuelIconProvider(fuel)}
                    className={s.childImage}
                    alt={fuel.name}
                  />
                  <div className={s.childName}>{fuel.name}</div>
                </div>
              </a>
            ))}
        </div>
      </div>
    </ModalSelector>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FuelTypeSelector);