import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getTopContentApi } from '../../_services/api/getTopContent';
import replaceText from '../../_services/textTemplate/textTemplate';

const TopContent = (props) => {

  const [ topContent, setTopContent] = useState({});

  useEffect(() => {
    getTopContentApi(props.city && props.city?.sitemap_name).subscribe((res) => {
      if(!!res) {
        setTopContent(res.seo);
        // console.log("cdt top c", res.seo);
      } 
    }, (error) => {
      console.error(error);
    })
  }, [props.city])

  return (
    <>
    {(topContent && topContent.top_content) 
        ? 
        (replaceText(topContent.top_content, props, { cityReplace: 'region' })) : 
            `Choose from a wide assortment of car services from periodic car
            servicing, car care services, wheel care services, cashless
            insurance claims and much more!`
    }
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopContent);
