import React, { useState, useEffect } from 'react';
import Glide from '@glidejs/glide';

function GlideCarousel(props) {
    const [perView, SetPerView] = useState(props.perView ?? 2);
    
    useEffect(() => {
      let glideObj;
      if(props.containerId){
        glideObj = new Glide(`#${props.containerId}`, {
          type: 'carousel',
          perView,
          autoplay: !props.noAutoplay ? 5000 : null,
          breakpoints: {
            768: {
              perView: 1,
            },
          },
        }).mount();
      }
      return () => glideObj?.destroy();
    }, [props.containerId]);

  return (
    <>
    <div className="glide" id={props.containerId}>
      <div
        className="glide__track"
        data-glide-el="track"
      >
        <ul className="glide__slides" style={props.ulStyles ? props.ulStyles : {}}>
          {props.reviews?.map((review, index) => (
            <li key={index} className="glide__slide">
              {review}
            </li>
          ))}
        </ul>
      </div>

      <div>
          <div className="glide__bullets reviewsBullets" data-glide-el="controls[nav]" style={props.reviewsBullets ? props.reviewsBullets : null}>
              {props.reviews?.map(
                (_, index) => <button key={index} className="glide__bullet userReviewsBullet" data-glide-dir={`=${index}`} style={props.backgroundColor ? props.backgroundColor : null} />,
              )}
          </div>
      </div>
        
    </div>
  </>
  )
}

export default GlideCarousel;