import HttpHeader from '../httpHeader';
import { Http } from '../../modules/http';
import Global from '../../global';

export const getWarrantyClaimData = (user_id, car_id, city_id, user_car_id) => {
  const Headers = new HttpHeader();
  const HttpHandler = new Http();
  const ApiManager = new Global();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.api_get}v1/oauth/customer/warranty_summary?user_id=${user_id}&car_id=${car_id}&city_id=${city_id}&user_car_id=${user_car_id}`;
  return HttpHandler.get(url, headers);
};