import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { connect } from 'react-redux';
import s from './NewFAQ.css';

function NewFAQ(props){
  useStyles(s);
  return (
    <div key={props.key} className={s.questionAnswerContainer}>
      <div
        className={  s.questionContainer}
        aria-expanded={props.expanded}
        onClick={props.setExpanded}
      >
        <div className={s.questionText}>
          {props.question}
        </div>
        <img 
          loading='lazy' 
          src={props.arrowIcon ? props.arrowIcon : "https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/PcHomeForm/ic_expand_more.svg"}
          className={s.questionControl} 
          alt="Expand" 
        />
      </div>
      <div className={ s.answer }>
        {props.answer}
    </div>
    </div>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewFAQ);
