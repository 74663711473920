import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import ModalSelector from './ModalSelector';
import s from './ModalSelector.css';
import { getModelsByBrand } from '../../_services/api/carDetailsApi';
import { slugify } from '../../utilities';
import generate from '../../serverRoutes/urlGenerators/newUrl';
import PlaceholderImage from '../PlaceholderImages/PlaceholderImage';
import defaultCarImage from './default_car.png';
import { deviceTypes } from '../../_services/device';

const CarModelSelector = (props) => {
  const modelSelectionHandler = (model) => {
    props.onModelSelect(model);
    props.goToNext();
  };

  const [models, setModels] = useState([]);
  const [loadingModels, setLoadingModels] = useState(true);

  useEffect(() => {
    if (!props.accessories ? props.cardetails?.brand : props.accessoriesCarDetail.brand) {
      if(props.isFlipkartScreen){
        setModels(props.availableFlipkartCouponModels); 
        setLoadingModels(false);
      } else {
        setLoadingModels(true);
        getModelsByBrand(!props.accessories ? props.cardetails?.brand?.id : props.accessoriesCarDetail?.brand?.id).subscribe((res) => {
          res.data = res?.data?.map((model) => ({
            ...model,
            slug: model.name.toLowerCase().replace(/\s+/g, '-'),
          }));
          setModels(res.data ?? []);
          setLoadingModels(false);
        }, (error) => {
          setLoadingModels(false);
          console.error(error);
        });
      }
      
    } else {
      console.error('Cannot get selected brand');
    }
  }, [props.cardetails, props.accessoriesCarDetail]);

  const [searchText, setSearchText] = useState('');
  const searchHandle = (e) => {
    setSearchText(e.target.value);
  };
  const search = (text) => text.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;

  return (
    <ModalSelector
      searchPlaceholder="Search Models"
      onSearchChange={searchHandle}
      noOverlay={props.noOverlay}
      title="Select Model"
      hide={props.hide}
      desktopCarSelection={props.desktopCarSelection}
      backClickHandler={props.goToPrev}
      initialCarDetails={props.initialCarDetails}
      stopBackgroundScroll={props.device === deviceTypes.MOBILE}
    >
      <div className={s.root} 
      style={props.noOverlay && !props.fixedHeight ? { height: 'auto' } : {}}
      >
        { !loadingModels ? (
          <div className={s.mainContainer}>
            {models.length > 0 ? (
              models.filter((model) => search(model.name)).map((model) => (
                <a
                  href={
                    `${props.apiEndpoints.frontend_serverWithProtocol}${
                      generate({
                        city: props.city,
                        subCity: props.selectedRegion || null,
                        serviceType: props.serviceType || props.servicesTypesList[0],
                        brand: props.cardetails?.brand,
                        model,
                      })
                    }`
}
                  style={{
                    textDecoration: 'none',
                  }}

                >
                  <div
                    key={slugify(model.name + model.id)}
                    className={s.childrenContainer}
                    style={props.noOverlay ? { marginBottom: '0' } : {}}
                    onClick={(e) => { e.preventDefault(); e.stopPropagation(); modelSelectionHandler(model); }}
                  >
                    <PlaceholderImage
                      placeHolderImage={defaultCarImage}
                      loading="lazy"
                      src={props.isMalaysiaRequest ? model.image_path : `https://gomechprod.blob.core.windows.net/gm-retail-app/thumbnails/brand-${props.accessories ? props.accessoriesCarDetail.brand.id : props.cardetails?.brand.id}-model-${model.id}.jpeg`}
                      className={s.childImage}
                      alt={model.name}
                    />
                    <div className={s.childName}>{model.name}</div>
                  </div>
                </a>
              ))
            ) : (
              <span>Loading Models...</span>
            )}
          </div>
        ) : (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            flexWrap: 'wrap',
          }}
          >
            <BeatLoader
              size={15}
              color="#e73c33"
            />
          </div>
        )}
      </div>
    </ModalSelector>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CarModelSelector);
