import React from 'react';
import Rating from '../../Rating/Rating';
import { connect } from 'react-redux';

const RatingReviews = (props) => {
  
  let css = {
    gridRowGap: '35px',
    backgroundColor: '#FFF',
    paddingTop: '30px',
    paddingBottom: '10px'
  }
  css = props.style?{...css,...props.style}:{...css}
  var customContainer = {gridRowGap: '35px', backgroundColor : '#FFF'}

  return (
    <Rating
      ratingItems= {props.home?.ratingItems}
      customContainer={{...css}}
      customItem={{
        padding: '0px',
      }}
    />
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RatingReviews);
