import storeExport from '../clientStore';
import { getCityFromLatLong } from './api/getCityFromLatLong';
import { setPrefferedCity } from '../actions/city';
import { urlServices } from './urlServices';
import { detectCityEvent } from '../gtm/gtmServices';

const { store } = storeExport;
const detectCity = (position) => {
  const LatLong = {
    latitude: position.coords.latitude,
    longitude: position.coords.longitude,
  };
  getCityFromLatLong(LatLong).subscribe((res) => {
    if (res.success) {
      const { city, user} = store.getState();
      urlServices(store.getState().city.sitemap_name, res.city.sitemap_name);
      store.dispatch(setPrefferedCity(res.city));
      detectCityEvent(user?.id, city?.name, res.city.name);
    }
  }, console.error);
};

const detectFlipkartCity = (position) => {
  const LatLong = {
    latitude: position.coords.latitude,
    longitude: position.coords.longitude,
  };
  getCityFromLatLong(LatLong).subscribe((res) => {
    if (res.success) {
      urlServices(store.getState().city.sitemap_name, res.city.sitemap_name);
      store.dispatch(setPrefferedCity({...res.city,latitude : LatLong.latitude, longitude: LatLong.longitude}));
    }
  }, console.error);
};

const showError = (error) => {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      return 'User denied the request for Geolocation.';
    case error.POSITION_UNAVAILABLE:
      return 'Location information is unavailable.';
    case error.TIMEOUT:
      return 'The request to get user location timed out.';
    case error.UNKNOWN_ERROR:
      return 'An unknown error occurred.';
    default:
      return 'Unknown Error.';
  }
};

export const getLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(detectCity, showError);
  } else {
    console.error('Geolocation is not supported by this browser.');
  }
};

export const getFlipkartLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(detectFlipkartCity, showError);
  } else {
    console.error('Geolocation is not supported by this browser.');
  }
};