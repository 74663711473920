import React, { useEffect, useRef, useState } from 'react';
import s from './css/main.css'
import useStyles from 'isomorphic-style-loader/useStyles';
import {connect} from 'react-redux';
import history from '../../../history';
import { setActiveServiceType } from '../../../actions/serviceType';
import generate from '../../../serverRoutes/urlGenerators/newUrl';
import autoUtmHistory from '../../../_services/autoUtmHistory';

function addMarkers(map, links) {
  const infowindow = new window.google.maps.InfoWindow();
  links.forEach((link, i) => {
    const icon = {
      url: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2_hdpi.png',
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(17, 34),
      scaledSize: new window.google.maps.Size(17, 25),
    };
    const marker = new window.google.maps.Marker({
      map,
      position: link.coords,
      title: link.title,
      icon,
    });

    window.google.maps.event.addListener(marker, 'click', (function (marker, i) {
      return function () {
        infowindow.setContent(link.title);
        infowindow.open(map, marker);
      };
    }(marker, i)));
  });
}

function Map(props) {

  useStyles(s);
  const ref = useRef();
  const [map, setMap] = useState();

  useEffect(() => {
    // console.log(props);
    if(props.options && props.city){

    let center = props.city?.latitude ? {lat: props.city?.latitude, lng: props.city?.longitude } : props.options.center;

    if(window.google && !map){
      setMap(new window.google.maps.Map(ref.current, {...props.options,center, zoom: 11}));
      return;
    }

    const onLoad = () => {
      setTimeout(() =>{setMap(new window.google.maps.Map(ref.current, {...props.options,center, zoom: 11}))},1000)
    };

    if (!window.google) {
      const script = document.createElement('script');
      script.src = 'https://maps.googleapis.com/maps/api/js?key='
       + props.options.key
       + '&libraries=places';
      document.head.append(script);
      script.addEventListener('load', onLoad);
      return () => script.removeEventListener('load', onLoad);
    }
  }
  },[props.options, props.city]);
  useEffect(() => {
    let markerData = Array.isArray(props.mapProps) ? props.mapProps.map(workShop => ({coords: {lat: workShop.latitude, lng: workShop.longitude}, title: workShop.name})) : [];
    if(markerData.length && map)
      addMarkers(map, markerData);
  },[map, props.mapProps]);

  if (map && typeof onMount === 'function') onMount(map, onMountProps);

  return (
    <div className={s.mapContainer}>
      <div
        style={{ height: '100%', borderRadius: '0.5em' }}
        {...{ ref }}
      />
      <div className={s.overMap}>
        <button className={s.bookNowButton} 
          onClick={() => {
            props.action(setActiveServiceType(props.servicesTypesList[0]));
            autoUtmHistory(history).push(
              generate({
                city: props.city,
                subCity: props.selectedRegion || null,
                serviceType: props.servicesTypesList[0],
                brand: props.cardetails?.brand,
                model: props.cardetails?.model,
                fuel: props.cardetails?.fuel,
              }),
            )}}>BOOK NOW</button>
      </div>
    </div>
  );
}

Map.defaultProps = {
  options: {
    center: { lat: 28.4629575, lng: 76.9999491 },
    zoom: 11,
    disableDefaultUI: true,
    zoomControl: true,
    key:'AIzaSyCB_7DV83b0isZlTvj6C7NoReSPYHC9Kc8'
  },

};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Map);