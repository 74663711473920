import HttpHeader from '../../httpHeader';
import Global from '../../../global';
import { Http } from '../../../modules/http';

export const accessoriesSearchApi = (searchString, carTypeId, cityId) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `https://api.parts-rocket.com/api/v1/part/search_acc?type=typing&query=${searchString}`;
  return HttpHandler.get(url, headers);
};

export const globalSeacrhApi = (searchString, carTypeId, cityId, userCardId) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  // const url = `${ApiManager.urls.api_get}v2/oauth/customer/global-search/?&start=0&end=20&web=true&keyword=${searchString}${carTypeId ? `&car_id=${carTypeId}` : ''}${cityId ? `&city_id=${cityId}` : ''}${userCardId ? `&user_car_id=${userCardId}` : ''}`.trim();
  const url = `${ApiManager.urls.api_get}v3/oauth/customer/global-search/?&start=0&end=20&web=true&keyword=${searchString}${carTypeId ? `&car_id=${carTypeId}` : ''}${cityId ? `&city_id=${cityId}` : ''}${userCardId ? `&user_car_id=${userCardId}` : ''}`.trim();
  // const url = `${ApiManager.urls.api_get}v3/oauth/customer/global-search/?&start=0&end=1000&web=true&keyword=${searchString}${carTypeId ? `&car_id=${carTypeId}` : ''}${cityId ? `&city_id=${cityId}` : ''}${userCardId ? `&user_car_id=${userCardId}` : ''}`.trim();
  return HttpHandler.get(url, headers);
};

// export const getSearchTagProducts = (tag, carParam, sparam, fparam, page, prodCount) => {
//   const Headers = new HttpHeader();
//   const ApiManager = new Global();
//   const HttpHandler = new Http();

//   const headers = Headers.get_token_headers();
//   const url = `${ApiManager.urls.accessories_url}products/?global_search=${tag}${carParam || ''}${sparam || ''}${fparam || ''}${page || ''}${prodCount || ''}`;
//   return HttpHandler.get(url, headers);
// };

export const getSearchTagProducts = (searchData, carParam, sparam, fparam, page, prodCount) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();
  let searchParam = '';
  const keys = Object.keys(searchData);
  for (let i = 0; i < keys.length; i += 1) {
    searchParam += `&${keys[i]}=${encodeURIComponent(searchData[keys[i]])}`;
  }
  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}search-new/?${searchParam}${carParam || ''}${sparam || ''}${fparam || ''}${page || ''}${prodCount || ''}`;
  return HttpHandler.get(url, headers);
};

export const getSearchTagFilters = (searchData) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();
  const keys = Object.keys(searchData);
  let searchParam = '';
  for (let i = 0; i < keys.length; i += 1) {
    searchParam += `&${keys[i]}=${searchData[keys[i]]}`;
  }
  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}filters/?${searchParam}`;
  return HttpHandler.get(url, headers);
};

export const getSearchTagFiltersV2 = (tag) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}dynamic-filters/?global_search=${tag}`;
  return HttpHandler.get(url, headers);
};

export const accessoriesSearchApiV2 = (searchString) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}wild-search?query=${searchString}`;
  return HttpHandler.get(url, headers);
};

export const accessoriesTopSearchData = () => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.accessories_url}topsearch-popularsearch/`;
  return HttpHandler.get(url, headers);
};

export const accessoriesSearchApiV3 = (searchString) => {
  const Headers = new HttpHeader();
  // const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `https://api.fulfilment.gomechanic.app/api/v1/part/acc_products_search?search=${searchString}`;
  return HttpHandler.get(url, headers);
};
