import HttpHeader from '../httpHeader';
import Global from '../../global';
import { Http } from '../../modules/http';

export const servicesSearchApi = (cityId, searchString) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.api_get}v1/oauth/customer/search_services_without_car/?city_id=${cityId}&keyword=${searchString}`.trim();
  return HttpHandler.get(url, headers);
};
