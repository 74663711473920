// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2pH19{position:fixed;height:100%;width:100%;background:#000;opacity:.7;z-index:36;top:0;left:0}._2VzXV{display:none}._3MJEJ{display:block}.trJ0D{position:unset!important;margin:0!important;padding:0!important;width:100%!important;-webkit-transform:none!important;-ms-transform:none!important;transform:none!important}._3eZRJ{width:90%;-webkit-box-sizing:border-box;box-sizing:border-box}._3eZRJ,._8PTAR{position:fixed;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);padding:5%;background:#fff;opacity:1;z-index:36}._8PTAR{width:80%}@media only screen and (min-width:1024px){._3eZRJ,._8PTAR{width:450px;height:inherit;overflow-y:scroll;padding:1%;-webkit-box-sizing:border-box;box-sizing:border-box}._2pH19{background:#071c2c;opacity:.5}}.AUiI8{height:auto}", ""]);
// Exports
exports.locals = {
	"overlay": "_2pH19",
	"dNone": "_2VzXV",
	"dBlock": "_3MJEJ",
	"desktopSetting": "trJ0D",
	"modal": "_3eZRJ",
	"modal2": "_8PTAR",
	"heightAuto": "AUiI8"
};
module.exports = exports;
