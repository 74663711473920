import React, { useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './index.css';

import NewFaqAccordion from '../../NewFAQ/NewFaqAccordion';

function FAQ(props) {
  const [expanded, setExpanded] = useState(false);
  useStyles(s);
  return (
    <div className={s.faqWrapper} onClick={(e) => e.stopPropagation()}>
      <div onClick={() => setExpanded(!expanded)} data-faq-expanded={expanded}> 
        <h3>Frequently Asked Questions</h3>
        <span className={s.control}>
                {expanded ? (
                  <img
                    width={25}
                    src={
                      'https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/retail%20warranty%20page/up_arrow.png'
                    }
                    alt="up arrow"
                  />
                ) : (
                  <img
                    width={25}
                    src={
                      'https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/retail%20warranty%20page/down_arrow.png'
                    }
                    alt="down arrow"
                  />
                )}
              </span>
        </div>
      <div style={expanded ? {display: 'block'} : {display: 'none'}}>
        <NewFaqAccordion warrantyDetailsFAQ/>
      </div>
    </div>
  );
}

export default FAQ;
