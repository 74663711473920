import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './index.css';
import WarrantyItem from './WarrantyItem';
import Accordion from '../../Warranty/Accordion';

function ClaimsAvailable({data}) {
  useStyles(s);
  let faqs = [];
  faqs = data?.categories?.map(category => ({
    claimsAvailable: data?.claims_available,
    ...category,
    parentName: category?.name,
    question: category?.name,
    icon: category?.icon,
    detail: category?.sub_categories?.map(sub_cat => ({heading: sub_cat.name, list: sub_cat.wf_services?.map(val => ({text: val?.name}))}))
  }))
  faqs = faqs.map((item, index) => ({
    ...item,
    answerTag: style => (
      <WarrantyItem item={item} index={index} style={style} />
    ),
  }));

  return (
    <>
      <div style={{height: '1rem', background: '#f5f5f5', marginInline: '-1rem', marginTop: '1rem'}}></div>
      <div className={s.claimWrapper}>
        <div>
          <h3>{data?.claims_available_title}</h3> {data?.claims_available ? <div className={s.notification}>{data?.claims_available}</div> : ''}
        </div>
        <h4>{data?.claims_available_subtitle}</h4>
        <Accordion faqs={faqs} defaultOpen accordianStyle={{ padding: '0' }} />
      </div>
    </>
  );
}

export default ClaimsAvailable;

