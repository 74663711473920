/*
 Author : Patanjali Kumar

 This component will contain the "Related Blogs" section as in
https://app.zeplin.io/project/5da0780780829f083448125a/screen/5da07e90a303922fd8070ede
 */
import React from 'react';
import classNames from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './responsive.css';
import PlaceholderImage from '../PlaceholderImages/PlaceholderImage';
import carouselPlaceholderImage from './carouselPlaceholder.png';
import Link from '../Link/Link';

const CarouselContent = props => {
  useStyles(s);

  return (
    <React.Fragment key={props.keyId}>
      {props.showPopup ? props.children : null}
      <div
        className={props.popularServices == 1 ? classNames(s.padding, s.positionRelative) : classNames(s.container, s.positionRelative)}
        onClick={props.onClick}
      >
        <PlaceholderImage
          placeHolderImage={carouselPlaceholderImage}
          loading="lazy"
          src={props.articleImage}
          style={props.style || {}}
          className={
            props.popularServices == 1 ? s.popularServicesImage : s.gridImage
          }
          alt={props.altTag ? props.altTag : "Banner"}
        />
        {!!props.count &&
        <div className={s.cityCountParent}>
          <p className={s.cityCount}> +{props.count} more </p>
          <p className={s.locateUs}>LOCATE US</p>
        </div>
      }
        <div className={props.popularServices == 1 ? s.textDiv : s.hide}>
          <div className={s.capText}>{props.serviceText}</div>
          <div className={s.bottomText}>{props.serviceTextBottom}</div>
        </div>

        <div className={props.popularServices == 1 ? s.hide : s.articleDetails}>
          <div className={s.authorAndDate}>
            {props.authorName && (
              <>
                <div className={s.author}>
                  <img
                    loading="lazy"
                    src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/B0CC5D66-C6F3-489F-9A74-D483C6B6D729.svg"
                    alt="Author"
                  />
                  <span>{props.authorName}</span>
                </div>
              </>
            )}

            {props.publishDate && (
              <>
                <div className={s.date}>
                  <img
                    loading="lazy"
                    src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/24CC41AD-3911-4D46-876E-7F3D67A277FE.svg"
                    alt="Date"
                  />
                  <span>{props.publishDate}</span>
                </div>
              </>
            )}
          </div>

          {/* <a href={props.url} rel="noopener noreferrer" target="_blank"> */}
            <div className={s.articleTitle}>{props.articleTitle}</div>
          {/* </a> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CarouselContent;
