// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2szL6,.tTkai{display:grid;grid-template-columns:1fr 1fr;-ms-flex-pack:justify;justify-content:space-between}._3nM4x{display:grid;grid-template-rows:auto;grid-row-gap:.1rem;font-size:25.6px;font-size:1.6rem;font-weight:600;font-stretch:normal;font-style:normal;letter-spacing:.16px;letter-spacing:.01rem;text-align:left;color:#071c2c;justify-items:center}._3ly1Y{padding:0 1.7rem}._1RafN{display:grid;grid-template-rows:auto;grid-row-gap:.5rem;font-size:18px;font-size:1.125rem;letter-spacing:.16px;letter-spacing:.01rem;text-align:left;color:#071c2c;justify-items:center;background-color:#fff;padding:30px 1.5625rem}._1RafN,._9otr-{font-weight:600;font-stretch:normal;font-style:normal}._9otr-{font-size:11.4px;font-size:.7125rem;letter-spacing:.12px;letter-spacing:.0075rem;text-align:center;color:grey}._3xV17{width:25.6px;width:1.6rem;height:25.6px;height:1.6rem;-o-object-fit:contain;object-fit:contain;margin-right:.375rem}._1pMok{font-size:14.4px;font-size:.9rem;font-weight:700;color:#071c2c}._1pMok,._3lXcx{font-stretch:normal;font-style:normal;letter-spacing:.13px;letter-spacing:.008125rem;text-align:left}._3lXcx{font-size:14px;font-size:.875rem;font-weight:400;color:green}._10Jgh{border-right:2px solid grey}._1esHs{color:green}._1GEca{display:-ms-flexbox;display:flex}.JakXx{padding-top:.18rem}._1xTeC{font-size:22.4px;font-size:1.4rem}._1G6OQ{text-align:center}._3M5DJ{padding:.18rem 3rem}", ""]);
// Exports
exports.locals = {
	"container": "_2szL6",
	"container2": "tTkai",
	"ratingItem": "_3nM4x",
	"pad": "_3ly1Y",
	"ratingItem2": "_1RafN",
	"subHeading": "_9otr-",
	"startIcon": "_3xV17",
	"totalRating": "_1pMok",
	"totalRating1": "_3lXcx",
	"rightBorder": "_10Jgh",
	"score": "_1esHs",
	"displayFlex": "_1GEca",
	"prt": "JakXx",
	"scoreSize": "_1xTeC",
	"textCenter": "_1G6OQ",
	"pading": "_3M5DJ"
};
module.exports = exports;
