import HttpHeader from '../httpHeader';
import Global from '../../global';
import { Http } from '../../modules/http';

export const getAddress = () => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.api_get}v1/oauth/customer/address`;
  return HttpHandler.get(url, headers);
};

export const addAddress = (addressData) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.api_get}v1/oauth/customer/address`;

  return HttpHandler.post(
    url,
    {
      ...addressData,
    },
    headers,
  );
};
