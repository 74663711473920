import { SET_REGIONS_OF_CITY } from '../constants';

export function setRegionsOfCity(value) {
  return {
    type: SET_REGIONS_OF_CITY,
    payload: {
      value,
    },
  };
}
