/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import s from './sidebar.css';
import CallButton from './CallButton';
import Link from '../Link';
import history from '../../history';
import generate from '../../serverRoutes/urlGenerators/newUrl';
import { setActiveServiceType } from '../../actions/serviceType';
import noScrollBody from '../../_services/bodyNoScrollForModals';
import chatBotDisplay from '../../_services/hideChatBot';
import { viewSideMenu } from '../../gtm/gtmServices';

const Sidebar = (props) => {
  useStyles(s);
  const [opened, setOpened] = useState(true);

  const logInButtonClick = () => {
    if (props.user && props.user.is_logged_in) {
      window.location.replace('/my-profile');
    } else {
      history.push(`/login?return=${window.location.pathname}`);
    }
  };

  const logInButtonText = () => (props.user.is_logged_in ? props.user.name : 'SIGN IN');

  useEffect(() => {
    noScrollBody(false);
    chatBotDisplay(false);
    return () => {
      noScrollBody(true);
      chatBotDisplay(true);
    };
  }, []);

  return (
    <>
      {opened ? <div onClick={props.hide} className={s.overlay} /> : null}
      <div className={classNames(s.sidebarContainer, opened ? s.opened : null)}>
        <div>
          <div className={s.signInBar}>
            <div onClick={() => {logInButtonClick(); viewSideMenu({fire_screen: 'login'})}}>
              <img
                loading="lazy"
                className={s.signInIcon}
                src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/LoginPage/DFA77A81-6B01-4189-939B-BF3664F6836C.svg"
                alt={logInButtonText()}
              />
              <span className={s.signIntext}>{logInButtonText()}</span>
            </div>

            <img
              loading="lazy"
              src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/LoginPage/AE06F0CE-9D71-4A10-BE38-38C5AA880DE7.svg"
              alt="close"
              onClick={props.hide}
            />
          </div>

          <div className={s.locationContainer}>
            {props.city && props.city?.website_icon ? (
              <>
                <span className={s.locationText}>Your selected location is</span>
                <span onClick={props.openCityPopup} className={s.locationCity}>
                &nbsp;
                  {props.city?.name}
                </span>
              </>
            ) : (
              <span onClick={props.openCityPopup} className={s.locationText}>
                You need to select a location.
              </span>
            )}
          </div>

          <div className={s.sideaBarItemContainer}>

            {/* <Link to={isUserLoggedIn() ? '/orders' : '/login?return=/'}> */}
            {/*  <div className={s.sidebarItem} onClick={() => props.hide()}> */}
            {/*    <div className={s.sidebarItemText}>My Orders</div> */}
            {/*  </div> */}
            {/* </Link> */}

            {props.user && props.user.is_logged_in && (
            <a
              href="/order-history"
              style={{
                textDecoration: 'none',
              }}
            >
              <div className={s.sidebarItem} onClick={() => {props.hide(); viewSideMenu({fire_screen: 'orders'})}}>
                <div className={s.sidebarItemText}>My Orders</div>
              </div>
            </a>
            )}

            {!props.user || !props.user.is_logged_in && (
            <Link
              to="/login?return=/order-history&type=hard"
              style={{
                textDecoration: 'none',
              }}
            >
              <div className={s.sidebarItem} onClick={() => {props.hide(); viewSideMenu({fire_screen: 'orders'})}}>
                <div className={s.sidebarItemText}>My Orders</div>
              </div>
            </Link>
            )}

            {props.user && props.user.is_logged_in && (
            <>
              <a href="/my-profile" style={{ textDecoration: 'none' }}>
                <div className={s.sidebarItem} onClick={() => {props.hide(); viewSideMenu({fire_screen: 'profile'})}}>
                  <div className={s.sidebarItemText}>My Profile</div>
                </div>
              </a>
              <a href="/goapp-money" style={{ textDecoration: 'none' }}>
                <div className={s.sidebarItem} onClick={() => {props.hide(); viewSideMenu({fire_screen: 'profile'})}}>
                  <div className={s.flexContainer}>
                    <div className={s.sidebarItemText}>GoApp Money</div>
                    <div className={s.goAppMoney}>{props.currency.symbol} {props.user && props.user.go_app_money}</div>
                  </div>
                </div>
              </a>
              <a href="/health-card" style={{ textDecoration: 'none' }}>
                <div className={s.sidebarItem} onClick={() => {props.hide(); viewSideMenu({fire_screen: 'healthcard'})}}>
                  <div className={s.sidebarItemText}>Health Card</div>
                </div>
              </a>
              <a href="/my-cars" style={{ textDecoration: 'none' }}>
                <div className={s.sidebarItem} onClick={() => {props.hide(); viewSideMenu({fire_screen: 'cars'})}}>
                  <div className={s.sidebarItemText}>My Cars</div>
                </div>
              </a>
              <a href="/manage-addresses" style={{ textDecoration: 'none' }}>
                <div className={s.sidebarItem} onClick={() => {props.hide(); viewSideMenu({fire_screen: 'address'})}}>
                  <div className={s.sidebarItemText}>Manage Addresses</div>
                </div>
              </a>
            </>
            )}

            {/* {!props.isPhonePeRequest */}
            {/* && ( */}
            {/* <Link to="/insuranceServices"> */}
            {/*  <div className={s.sidebarItem} onClick={() => props.hide()}> */}
            {/*    <div className={s.sidebarItemText}>Insurance</div> */}
            {/*  </div> */}
            {/* </Link> */}
            {/* )} */}

            <Link to={generate({
              city: props.city,
              serviceType: props.servicesTypesList[0],
            })}
            >
              <div
                className={s.sidebarItem}
                onClick={() => {
                  props.hide(); props.action(setActiveServiceType(
                    props.servicesTypesList[0],
                  ));
                  viewSideMenu({fire_screen: 'services'})
                }}
              >
                <div className={s.sidebarItemText}>Services</div>
              </div>
            </Link>

            {/* <Link
              to={generate({
                city: props.city
              })}
              style={{
                textDecoration: 'none',
              }}
            >
              <div className={s.sidebarItem} onClick={() => {window.open('https://gomechanic.in/spares/'); viewSideMenu({fire_screen: 'login'})}}>
                <div className={s.sidebarItemText}>Order Spares</div>
              </div>
            </Link> */}

            {props.user && props.user.is_logged_in && (
            <a
              style={{ textDecoration: 'none' }}
              onClick={() => {
                localStorage.clear();
                if (window.location.href.includes('/accessories')) window.location.href =  '/accessories';
                else window.location.href = '/';
              }}
            >
              <div className={s.sidebarItem} onClick={() => props.hide()}>
                <div className={s.sidebarItemText}>Logout</div>
              </div>
            </a>
            )}

            {/* <Link
            to="/accessories"
            style={{
              textDecoration: 'none',
            }}
          >
            <div className={s.sidebarItem} onClick={() => props.hide()}>
              <div className={s.sidebarItemText}>Accessories</div>
            </div>
          </Link> */}

            {/* <div className={s.sidebarItem}> */}
            {/*  <div className={s.sidebarItemText}>About Us</div> */}
            {/* </div> */}

            {/* <div className={s.sidebarItem}> */}
            {/*  <div className={s.sidebarItemText}>Blog</div> */}
            {/* </div> */}

            {/* <div className={s.sidebarItem}> */}
            {/*  <div className={s.sidebarItemText}>FAQs</div> */}
            {/* </div> */}

            {/* <div className={s.sidebarItem}> */}
            {/*  <div className={s.sidebarItemText}>Partners</div> */}
            {/* </div> */}

            {/* <div className={s.sidebarItem}> */}
            {/*  <div className={s.sidebarItemText}>Contact</div> */}
            {/* </div> */}
          </div>

          <a
            href={props.isMalaysiaRequest ? "tel:60392121789" : "tel:8398970970"}
            style={{
              textDecoration: 'none',
            }}
          >
            <CallButton text={props.isMalaysiaRequest ? "60392121789" : "8398970970"} />
          </a>
        </div>

        <div style={{ margin: '1rem' }}>
          <a
            href="https://play.google.com/store/apps/details?id=gomechanic.retail&amp;hl=en_IN"
            style={{ display: 'flex', justifyContent: 'center', marginBottom: '0.5rem' }}
          ><div  style={{ width: '10rem', height: '3rem' }}>
            <img
              alt="img"
              loading="lazy"
              src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/Website/play-store.png"
              style={{ height: '100%', width: '100%' }}
            />
            </div>
          </a>
          <a
            href="https://apps.apple.com/in/app/gomechanic-car-service/id1498891908"
            style={{ display: 'flex', justifyContent: 'center', marginBottom: '0.5rem' }}
          >
            
          <div style={{ width: '10rem', height: '3rem' }}>
            <img
              alt="img"
              loading="lazy"
              src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/Website/app-store.png"
              style={{ height: '100%', width: '100%' }}
            />
            </div>
          </a>
          <div className={s.sidePanelBottom}>{`${new Date().getFullYear()} Service Easy Technology Private Limited`}</div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
