import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames';
import s from './index.css';

function ExtendedWarrantyItem({ item }) {
  useStyles(s);
  return (
    <div className={s.itemWrapper}>
      <div
        className={classNames(
          s.dot,
          item.index === 2 ? s.danger : item.index === 1 ? s.good : s.warning,
        )}
      />
      <span>{item.text}</span>
    </div>
  );
}

export default ExtendedWarrantyItem;
