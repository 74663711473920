import React, { useState, useEffect } from 'react';
import s from './index.css';
import useStyles from 'isomorphic-style-loader/useStyles';
import noScrollBody from '../../../_services/bodyNoScrollForModals';

const WarrantyDetails = props => {
  useStyles(s);
  const [expandedList, setExpandedList] = useState([]);
  const [warrantyDetails, setWarrantyDetails] = useState({});

  useEffect(() => {
    let tempWarrantyDetails = props.data ?? {};
    setWarrantyDetails(tempWarrantyDetails)
    setExpandedList(tempWarrantyDetails?.sub_categories?.map(_ => false));
  }, []);

  useEffect(() => {
    noScrollBody(false);
    return () => {
      noScrollBody(true);
    };
  }, []);

  return (
    <>
      <section className={s.warrantyComponent}>
        <div>
        <section className={s.headerComponent}>
          <img
            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/retail%20warranty%20page/backIcon.svg"
            onClick={() => {props.handleBackOnWarrantyDetailModal()}}
            alt="back"
          />
          <span className={s.headerText}>{warrantyDetails?.parentName}</span>
        </section>

        <React.Fragment>
          <div style={{backgroundColor: '#f5f5f5'}}>
          {warrantyDetails?.claimsAvailable ? <div className={s.claimsAvailable}>
            <h3>Claims Available</h3>
            <div className={s.notification}>{warrantyDetails?.claimsAvailable}</div>
          </div> : null}
          {(warrantyDetails?.sub_categories ?? [])?.map((detail, index) => (
            <section className={s.middleComponent} key={index}>
              <div>
                <h2 className={s.heading}>{detail?.name}</h2>
                {detail?.is_claimed ? <button disabled className={s.approvedGreen}>{detail?.is_claimed_text}</button> : ''}
              </div>
              {(detail?.wf_services?.length ? detail?.wf_services : [])
                ?.slice(0, expandedList[index] ? detail?.wf_services?.length : 3)
                ?.map((item, indexx) => (
                  <div className={s.item} key={indexx}>
                    <div className={s.itemDetails}>{item?.name}</div>
                    {/* <img
                      src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/retail%20warranty%20page/tickIcon.svg"
                      alt="tick"
                    /> */}
                  </div>
                ))}
              <div
                className={s.seeAll}
                onClick={() => {
                  expandedList[index] = !(expandedList[index] ?? false);
                  setExpandedList([...expandedList]);
                }}
              >
                {detail?.wf_services?.length <= 3 ? '' : expandedList[index] ? 'See Less' : 'See More'}
              </div>
            </section>
          ))}
          </div>
        </React.Fragment>
        </div>
      </section>
    </>
  );
};

export default WarrantyDetails;
