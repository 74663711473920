import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './css/main.css';

const HomepageSection = (props) => {
  useStyles(s);
  const tagWrapper = (tag, text) => {
    if (tag === 'h1') return <h1 className={s.customHeading}>{text}</h1>;
    if (tag === 'h2') return <h2 className={s.customHeading}>{text}</h2>;
    if (tag === 'h3') return <h3 className={s.customHeading}>{text}</h3>;
    if (tag === 'h4') return <h4 className={s.customHeading}>{text}</h4>;
    if (tag === 'h5') return <h5 className={s.customHeading}>{text}</h5>;
    if (tag === 'h6') return <h6 className={s.customHeading}>{text}</h6>;
  };
  return (
    <React.Fragment key={props.keyId}>
      <div className={`${s.container} ${props.nearMe ? s.alginContainerToCenter : ''}`} id={props.htmlId}>
        <div className={props.services ? s.none : s.sectionLine} />
        <div className={s.sectionTitle} style={props.titleStyle ? { ...props.titleStyle } : {}}>
          {!props.tag && <span>{props.title}</span>}
          {props.tag && tagWrapper(props.tag, props.title)}
          {props.subtitle ? (
            <span
              style={{
                // marginRight: '30px',
                alignItems: 'center',
              }}
              className={s.font13Normal}
            >
              {props.subtitle}
            </span>
          ) : null}
        </div>
        {props.children}
      </div>
    </React.Fragment>
  );
};

export default HomepageSection;
