import history from '../history';
import storeExport from '../clientStore';
import generate from '../serverRoutes/urlGenerators/newUrl';
import autoUtmHistory from './autoUtmHistory';

export const urlServices = (find, toReplace) => {
  try {
    const prevPath = window.location.pathname;
    let newUrl = null;
    const reSlash = new RegExp(`/${find}`, 'g');
    const reDash = new RegExp(`-${find}`, 'g');

    if (prevPath.search(reSlash) !== -1) {
      newUrl = prevPath.replace(reSlash, `/${toReplace}`);
    }
    if (prevPath.search(reDash) !== -1) {
      newUrl = prevPath.replace(reDash, `-${toReplace}`);
    }

    if (newUrl !== null) {
      autoUtmHistory(history).push(newUrl);
    }
  } catch (e) {
    console.log('Could not switch url ', e);
  }
};

export const serviceUrlGenerator = (service, city = null) => (city === null
  ? `/${service.slug}`
  : `/${service.slug}/${service.slug}-${city.sitemap_name}`);

export const switchServiceType = (targetServiceType, redirect = true) => {
  try {
    // If url is 1 level, 1st level is city slug
    // 2nd level is serviceTypeSlug-CitySlug
    const { city, cardetails, selectedRegion } = storeExport.store.getState();
    if (redirect) {
      autoUtmHistory(history).push(
        generate({
          city,
          subCity: selectedRegion,
          serviceType: targetServiceType,
          brand: cardetails.brand,
          model: cardetails.model,
          fuel: cardetails.fuel,
        }),
      );
    }
  } catch (e) {
    console.error(e);
  }
};

export const switchCity = (targetCity) => {
  try {
    const { serviceType } = storeExport.store.getState();

    // If url is 1 level, 1st level is city slug
    // 2nd level is serviceTypeSlug-CitySlug

    let path = window.location.pathname;
    path = path.replace(/^\/|\/$/g, '');
    const splittedPaths = path.split('/');
    const numLevels = splittedPaths.length;
    if (!!serviceType || numLevels === 2) {
      if (splittedPaths[1] && splittedPaths[1].length > 0) {
        autoUtmHistory(history).push(
          `/${serviceType.slug}/${serviceType.slug}-${targetCity.sitemap_name}`,
        );
      } else {
        // numLevels = 0;
      }
    }

    if (numLevels === 1) {
      autoUtmHistory(history).push(`/${targetCity.sitemap_name}`);
    }
  } catch (e) {
    console.error(e);
  }
};

export const switchRegion = (region) => {
  try {
    const { serviceType, city, cardetails } = storeExport.store.getState();
    if (serviceType && city) {
      autoUtmHistory(history).push(
        generate({
          city,
          subCity: region,
          serviceType,
          brand: cardetails.brand,
          model: cardetails.model,
          fuel: cardetails.fuel,
        }),
      );
    }
  } catch (e) {
    console.error(e);
  }
};
