// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".QJBFe,._2FJb4{position:relative}.QJBFe{border:.0625rem solid hsla(0,0%,60.8%,.5);height:40px;height:2.5rem;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;padding:.875rem .9375rem;overflow:hidden}._1rdnC{position:absolute;top:40%;right:5%;cursor:pointer;font-size:12px;font-weight:600;letter-spacing:.11px;color:#071c2c}.dDLbl{position:absolute;z-index:1;height:100%;padding:0;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;pointer-events:none;font-size:15px;font-size:.9375rem;line-height:1.4;letter-spacing:.14px;letter-spacing:.00875rem;color:#9b9b9b;margin-left:.0625rem;-webkit-transition:all .3s ease-in-out;-o-transition:all .3s ease-in-out;transition:all .3s ease-in-out}._171FI,.dDLbl{font-weight:500;font-stretch:normal;font-style:normal;text-align:left;margin-top:.625rem}._171FI{font-size:17px;font-size:1.0625rem;line-height:1.41;letter-spacing:.15px;letter-spacing:.009375rem;color:#282828;outline:none;border:none;background:none}._171FI:focus{margin-top:1.25rem}._171FI::-webkit-input-placeholder{opacity:0}._171FI::-moz-placeholder{opacity:0}._171FI:-ms-input-placeholder{opacity:0}._171FI::-ms-input-placeholder{opacity:0}._171FI::placeholder{opacity:0}._171FI:focus+.dDLbl{margin-top:0;font-size:12px;font-size:.75rem;color:#9b9b9b}._171FI:not(:-moz-placeholder-shown){margin-top:1.25rem}._171FI:not(:-ms-input-placeholder){margin-top:1.25rem}._171FI:not(:placeholder-shown){margin-top:1.25rem}._171FI:not(:-moz-placeholder-shown)+.dDLbl{margin-top:0;font-size:12px;font-size:.75rem;color:#9b9b9b}._171FI:not(:-ms-input-placeholder)+.dDLbl{margin-top:0;font-size:12px;font-size:.75rem;color:#9b9b9b}._171FI:not(:placeholder-shown)+.dDLbl{margin-top:0;font-size:12px;font-size:.75rem;color:#9b9b9b}", ""]);
// Exports
exports.locals = {
	"container": "QJBFe",
	"mainContainer": "_2FJb4",
	"rightSideText": "_1rdnC",
	"label": "dDLbl",
	"input": "_171FI"
};
module.exports = exports;
