/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import ModalSelector from './ModalSelector';
import s from './ModalSelector.css';
import { slugify } from '../../utilities';

const CarYearSelector = (props) => {
  const carYearSelectionHandler = (year) => {
    props.onYearSelect(year);
    props.goToNext();
  };
  const [searchText, setSearchText] = useState('');
  const searchHandle = (e) => {
    setSearchText(e.target.value);
  };
  const search = (text) => text.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
  const start = parseInt(props.accessoriesCarDetail.model.start_year || '2001', 10);
  const end = parseInt(props.accessoriesCarDetail.model.end_year || '2020', 10);
  const yearList = [...Array(end-start+1).keys()].map((val) => `${start + val}`);
  return (
    <ModalSelector
      initialCarDetails={props.initialCarDetails}
      searchPlaceholder="Search Model Year"
      onSearchChange={searchHandle}
      noOverlay={props.noOverlay}
      desktopCarSelection={props.desktopCarSelection}
      title="Select Model Year"
      hide={props.hide}
      backClickHandler={props.goToPrev}
    >
      <div className={s.root} style={props.noOverlay ? { height: 'auto' } : {}}>
        <div className={s.mainContainer} style={{ height: 'fit-content' }}>
          {yearList
            .filter((year) => search(year))
            .map((year) => (
              <div
                key={slugify(year)}
                className={s.childrenContainer}
                style={props.noOverlay ? { marginBottom: '0', height: '3.125rem' } : {}}
                onClick={(e) => { e.preventDefault(); e.stopPropagation(); carYearSelectionHandler(year); }}
              >
                <div className={s.childName}>{year}</div>
              </div>
            ))}
        </div>
      </div>
    </ModalSelector>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CarYearSelector);
