import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getPaymentOffers } from '../../_services/api/get_payment_offers';
import s from './CouponPage.css';
import { couponApplyBtnEvent, tapApplyCouponEvent } from '../../gtm/gtmServices';

const CouponPage = (props) => {
  useStyles(s);
  const [applying, setApplying] = useState(false);
  const [couponInput, setCouponInput] = useState('');
  const [availableCouponOffers, updateAvailableCouponOffers] = useState([]);
  const [paymentOffers, setPaymentOffers] = useState([]);
  const [applying1, setApplying1] = useState(false);

  useEffect(() => {
    tapApplyCouponEvent(props?.user?.id, props?.isAMCUser, props?.cardetails?.model?.id);
  }, []);

  useEffect(() => {
    !!props.totalPrice && getPaymentOffers(
      props.totalPrice,
    ).subscribe(
      (res) => {
        const { data: { offers, payment_offers } } = res;
        updateAvailableCouponOffers(offers);
        setPaymentOffers(payment_offers);
      }, (err) => {
        console.error(err);
      },
    );
  }, [props.totalPrice]);

  return (
    <div
      className={s.container}
      style={
        props.inSideBar ? {
          height: '100%',
          width: '100%',
          position: 'relative',
        } : {}
      }
    >
      <div className={s.upperBar}>
        <img
          loading="lazy"
          className={s.backButton}
          src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/CartViewer/69BB3456-EB2C-4B6B-A1F0-DC9CA44A3B77.svg"
          alt="back"
          onClick={props.hide}
        />
        <span className={s.headerTitle}>Apply Coupon | GoApp Money</span>
      </div>

      <div className={s.internalContainer}>
        <div className={s.couponContainer}>
          <input
            className={s.couponInput}
            onChange={
              (e) => setCouponInput(e.target.value)
            }
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                if (couponInput.length === 0) return;
                setApplying(true);
                props.applyCoupon(couponInput);
              }
            }}
            value={couponInput}
            placeholder="Enter Coupon"
          />
          <span
            className={s.applyButton}
            style={couponInput.length > 0 ? { color: '#e73c33' } : {}}
            onClick={() => {
              if (couponInput.length === 0) return;
              setApplying(true);
              props.applyCoupon(couponInput);
              couponApplyBtnEvent(
                props?.user?.id, couponInput, null, props?.cardetails?.model?.id, props?.isAMCUser,
              );
            }}
          >
            {applying ? 'APPLYING...' : 'APPLY'}
          </span>
        </div>
      </div>

      {/* {((window.location.pathname === '/cartPage') || (window.location.pathname === '/cart')) ? (
        <> */}
          {props.user.is_logged_in && 
            <>
              <div className={s.availableOffers}>GoApp Money</div>
              <div className={s.applyCouponContainer}>
                <div className={s.applyCouponSubContainer}>
                  <div className={s.goMoneySubContainer}>
                    <div className={s.goAppMoneyContainer}>
                      <img
                        className={s.goAppMoney}
                        src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/OrderTracking/GoAppMoney.png"
                        alt="GoApp Money"
                      />
                      <div>
                        <div className={s.availableText}>Available Balance</div>
                        <div className={s.goAppMoneyText}>
                        {/* {!props.user.is_logged_in ? <>
                          GoApp Money will be visible once you login.
                        </> :  */}
                        <>
                        {props.currency.symbol}
                          {' '}
                          {props.user && props.user.go_app_money ? props.user.go_app_money : 0}
                        </> 
                        {/* } */}
                        </div>
                      </div>
                    </div>
                    {/* <div>{props.user.go_app_money}</div> */}
                    <div
                      className={s.applyCouponApplyButton}
                      style={couponInput.length > 0 ? { color: '#e73c33' } : {}}
                      onClick={() => {
                        // setApplying1();
                        if(!props.user?.is_logged_in)
                          props.hide();
                        else
                          props.applyGoAppMoney();
                      }}
                    >
                      {applying1 ? 'APPLYING...' : props.user?.is_logged_in ? 'APPLY' : 'Login'}
                    </div>
                  </div>
                  <div className={s.displayName}>You can use 50% of GoApp Money on an Order</div>
                </div>
              </div>
            </>
          }
          {!!availableCouponOffers.length && (
            <div className={s.availableOffers}>Available Offers</div>
          )}
          <div className={s.applyCouponContainer}>
            {availableCouponOffers && availableCouponOffers.map((offerDetails, index) => (
              <div className={s.applyCouponSubContainer}>
                <div className={s.logoContainer}>
                  <img src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Offers%20Page/icon_go_mechanic_logo.png" alt="logo" className={s.gomechLogo} />
                </div>
                <div className={s.applyCouponDetailsRetail}>
                  <h4 className={s.h4Class}>{offerDetails.description}</h4>
                  <div className={s.displayName}>{offerDetails.display_name}</div>
                  <details>
                    <summary>
                      <span>view t&c</span>
                      <img style={{ width: '12px', marginLeft: '7px' }} src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Checkout/expandArrow.png" alt="arrow" />
                    </summary>
                    {
                      offerDetails.terms && offerDetails.terms.map((term) => (<p>{term}</p>))
                    }
                  </details>
                  <hr />
                  <div className={s.couponCodeButtonContainerRetail}>
                    <div className={s.applyCouponCodeRetail}>
                      {offerDetails.code}
                    </div>
                    <div
                      className={s.applyCouponApplyButton}
                      style={couponInput.length > 0 ? { color: '#e73c33' } : {}}
                      onClick={() => {
                        setApplying1(index);
                        props.applyCoupon(offerDetails.code);
                        couponApplyBtnEvent(
                          props?.user?.id, offerDetails.code, offerDetails.coupon_type, props?.cardetails?.model?.id, props?.isAMCUser,
                        );
                      }}
                    >
                      {applying1 === index ? 'APPLYING...' : 'APPLY'}
                    </div>
                  </div>
                  {offerDetails.strip_text && (
                  <>
                    <hr />
                    <div className={s.stripText}>{offerDetails.strip_text}</div>
                  </>
                  )}
                </div>
              </div>
            ))}
          </div>
          {!!paymentOffers.length && (
            <div className={s.availableOffers}>Payment Offers</div>
          )}
          <div className={s.applyCouponContainer}>
            {paymentOffers.map((paymentOfferDetails, index) => (
              <div className={s.applyCouponSubContainer}>
                <div className={s.logoContainer}>
                  <img src={paymentOfferDetails.payment_offer_info.coupon_icon} alt="logo" className={s.gomechLogo} />
                </div>
                <div className={s.applyCouponDetailsRetail}>
                  <div className={s.paymentOfferTitle}>{paymentOfferDetails.payment_offer_info.offer_discription}</div>
                  <hr />
                  <div className={s.couponCodeButtonContainerRetail}>
                    <div className={s.applyCouponCodeRetail}>
                      {paymentOfferDetails.code}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        {/* </>
      )
        : (
          <>
            {!!availableCouponOffers.length && (
              <>
                <div className={s.availableOffers}>Available Offers</div>
                <div className={s.applyCouponContainer}>
                  {availableCouponOffers.map((offerDetails, index) => (
                    <div className={s.applyCouponSubContainer}>
                      <div className={s.couponCodeButtonContainer}>
                        <div className={s.applyCouponCode}>
                          {offerDetails.code}
                        </div>
                        <span
                          className={s.applyCouponApplyButton}
                          style={couponInput.length > 0 ? { color: '#e73c33' } : {}}
                          onClick={() => {
                            setApplying1(index);
                            props.applyCoupon(offerDetails.code);
                          }}
                        >
                          {applying1 === index ? 'APPLYING...' : 'APPLY'}
                        </span>
                      </div>
                      <div className={s.applyCouponDetails}>
                        <h3>{offerDetails.description}</h3>
                        <hr />
                        <span>{offerDetails.display_name}</span>
                        {offerDetails.terms ? (
                          <details>
                            <summary>
                              <span>view t&c</span>
                              <img style={{ width: '12px', marginLeft: '7px' }} src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Checkout/expandArrow.png" alt="arrow" />
                            </summary>
                            {
                              offerDetails.terms && offerDetails.terms.map((term) => (<p>{term}</p>))
                            }
                          </details>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            {!!paymentOffers.length && (
              <>
                <div className={s.availableOffers}>Payment Offers</div>
                <div className={s.applyCouponContainer}>
                  {paymentOffers.map((paymentOfferDetails, index) => (
                    <div className={s.applyCouponSubContainer}>
                      <div className={s.logoContainer}>
                        <img src={paymentOfferDetails.icon} alt="logo" className={s.gomechLogo} />
                      </div>
                      <div className={s.applyCouponDetailsRetail}>
                        <div className={s.paymentOfferTitle}>{paymentOfferDetails.description}</div>
                        <hr />
                        <div className={s.couponCodeButtonContainerRetail}>
                          <div className={s.applyCouponCodeRetail}>
                            {paymentOfferDetails.code}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        )} */}
    </div>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CouponPage);
