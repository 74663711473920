import { SET_SERVICE_GUIDE } from '../constants';

export function setServiceGuide(value) {
  return {
    type: SET_SERVICE_GUIDE,
    payload: {
      value,
    },
  };
}
