// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SDaVi{width:100vw;height:100vh;position:fixed;background-color:#fff;z-index:55;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}._2cnUK{margin-top:2rem;width:100vw;-o-object-fit:cover;object-fit:cover}._2bPeD{margin-top:3.5rem;position:absolute;margin-left:4.5rem;margin-right:4.5rem;font-size:24px;font-weight:700;font-stretch:normal;font-style:normal;line-height:1.33;letter-spacing:normal;text-align:center;color:#242a3c}._3R7cC,._2bPeD{display:-ms-flexbox;display:flex}._3R7cC{border-radius:4px;background-color:#e73c33;padding:1rem 3.75rem;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;margin:1rem 1rem 0;-ms-flex-align:center;align-items:center}._3R7cC>img{margin-right:.5rem}._3R7cC>span{-webkit-text-stroke:1px rgba(0,0,0,0);font-size:14px;font-weight:500;font-stretch:normal;font-style:normal;line-height:1.43;letter-spacing:normal;text-align:left;color:#fff}", ""]);
// Exports
exports.locals = {
	"locationTypeScreen": "SDaVi",
	"image": "_2cnUK",
	"flexCenter": "_2bPeD",
	"btnWithBg": "_3R7cC"
};
module.exports = exports;
