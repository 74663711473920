import HttpHeader from '../httpHeader';
import { Http } from '../../modules/http';

export const getFaqsServiceWise = (cityId, serviceSlug, brandSlug, subCategory) => {
  const Headers = new HttpHeader();
  const HttpHandler = new Http();
  let path = '';
  if (typeof window !== 'undefined') {
    path = window.location.pathname;
  }
  const headers = Headers.get_token_headers();
  let url = "";
  if(!subCategory) {
    url = `/api/v1/get-faqs-service-wise/?cityId=${cityId}&serviceSlug=${serviceSlug}&brandSlug=${brandSlug}&path=${path}`;
  } else {
    url = `/api/v1/get-faqs-service-wise/?cityId=${cityId}&serviceSlug=${subCategory}&brandSlug=${brandSlug}&path=${path}&subCategory=${subCategory}`;
  }
  return HttpHandler.get(url, headers);
};
