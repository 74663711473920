import HttpHeader from '../httpHeader';
import { Http } from '../../modules/http';

export const getPriceListApi = (citySlug, serviceSlug, brandSlug) => {
    const Headers = new HttpHeader();
    const HttpHandler = new Http();

    const headers = Headers.get_token_headers();
    let queryStr = `city=${citySlug}`;
    let url;

    if (serviceSlug && brandSlug) queryStr = `${queryStr}&brand=${brandSlug}`;
    if (serviceSlug) queryStr = `${queryStr}&service=${serviceSlug}`;

    url = `/api/v1/priceList?${queryStr}`;

    return HttpHandler.get(url, headers);
};
