import { SET_ACTIVE_SERVICE_TYPE } from '../constants';

export function setActiveServiceType(value) {
  return {
    type: SET_ACTIVE_SERVICE_TYPE,
    payload: {
      value,
    },
  };
}
