import React, { useEffect, useRef, useState } from 'react';
import image from './default_car.png';

const PlaceholderImage = (props) => {
  let attributes = {};
  if (props) {
    attributes = { ...props };
  }
  const [imageLoading, setImageLoading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const forOriginalImage = JSON.parse(JSON.stringify({ ...attributes }));
  const forReplacerImage = JSON.parse(JSON.stringify({ ...attributes }));
  const ref = useRef({ current: null });
  if (!imageLoaded) {
    if (attributes.style) {
      forOriginalImage.style.width = '0';
      forOriginalImage.style.height = '0';
    } else {
      forOriginalImage.style = { width: '0', height: '0' };
    }
    if (attributes.style) {
      if (forReplacerImage.style.display === 'none') forReplacerImage.style.display = 'unset';
    } else {
      forReplacerImage.style = { display: 'block' };
    }
    if (attributes.placeHolderImage) {
      forReplacerImage.src = attributes.placeHolderImage;
    } else {
      forReplacerImage.src = image;
    }
  }
  const triggerImageLoad = () => {
    const imageToLoad = new Image();
    setImageLoading(true);
    imageToLoad.onload = () => {
      setImageLoaded(true);
      if (props.onLoad) {
        props.onLoad();
      }
    };
    imageToLoad.src = forOriginalImage.src;
  };
  useEffect(() => {
    if (props.overrideLazyLoad && !imageLoaded && !imageLoading) {
      triggerImageLoad();
      return;
    }
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !imageLoaded && !imageLoading) {
        triggerImageLoad();
      }
    });
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);
  return (
    <>
      {imageLoaded ? (
        <img
          {...forOriginalImage}
        />
      ) : null}
      {!imageLoaded ? <img ref={ref} {...forReplacerImage} /> : null}
    </>
  );
};

export default PlaceholderImage;
