// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@media (min-width:1024px){html{font-size:13px!important}}@media (min-width:1279px){html{font-size:14px!important}}@media (min-width:1366px){html{font-size:15px!important}}@media (min-width:1440px){html{font-size:16px!important}}@media (min-width:1680px){html{font-size:17px!important}}@media (min-width:1920px){html{font-size:18px!important}}:root{--font-family-base:\"Gilroy\",sans-serif!important;--max-content-width:1000px;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px}._3JbP7{display:-ms-flexbox;display:flex}._266cD{-ms-flex-direction:row;flex-direction:row}._213Vk{-ms-flex-direction:column;flex-direction:column}.kwTdU{-ms-flex-wrap:wrap;flex-wrap:wrap}._1O9SZ{-ms-flex-pack:center;justify-content:center}.SASLT{-ms-flex-align:center;align-items:center}._7J1iv{font-weight:700}html{background-color:#f6f6f6;font-family:Gilroy,sans-serif}body,html{margin:0 auto}._1zNc7{font-size:13px;font-weight:400;font-stretch:normal;font-style:normal;letter-spacing:.12px;text-align:left;color:#4a4a4a}@media (max-width:1023px){._1KVuw,._3WGc-{display:none!important}}@media (min-width:1024px){.YjRV4,._1SV7R{display:none!important}}@media only screen and (max-width:1023px){._1btzO,._1KVuw{display:none!important}}._2En4u{background-color:#e73c33}.tODmu{-webkit-box-shadow:0 .0625rem .125rem 0 rgba(0,0,0,.12);box-shadow:0 .0625rem .125rem 0 rgba(0,0,0,.12)}._1XPkN{font-size:18px;font-size:1.125rem;font-weight:600;font-stretch:normal;font-style:normal;line-height:1.44;letter-spacing:.16px;letter-spacing:.01rem;text-align:left;color:#fff;padding:1.125rem 0}@media (min-width:1024px){._1XPkN{padding:1rem 0;font-size:1rem}._2MDUX{display:grid;grid-template-columns:3fr 1fr}}", ""]);
// Exports
exports.locals = {
	"dFlex": "_3JbP7",
	"flexRow": "_266cD",
	"flexColumn": "_213Vk",
	"flexWrap": "kwTdU",
	"justifyCenter": "_1O9SZ",
	"alignCenter": "SASLT",
	"fontBold": "_7J1iv",
	"font13Normal": "_1zNc7",
	"hideOnMobileTab": "_1KVuw",
	"hideOnTab": "_3WGc-",
	"hideOnPc": "YjRV4",
	"hideOnPcTab": "_1SV7R",
	"hideOnMobile": "_1btzO",
	"redBg": "_2En4u",
	"shadow": "tODmu",
	"text": "_1XPkN",
	"flex": "_2MDUX"
};
module.exports = exports;
