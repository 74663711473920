import React, { useEffect, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './responsive.css';
import noScrollBody from '../../_services/bodyNoScrollForModals';
import chatBotDisplay from '../../_services/hideChatBot';

export default function Modal(props) {
  useStyles(styles);
  const [shown, setShown] = useState(true);

  // useEffect(() => {
  //   setShown(true);
  // }, []);

  const additionalStyles = { ...(props.citySelector ? { height: '80%' } : {}) };
  useEffect(() => {
    if (props.stopBackgroundScroll) noScrollBody(false);
    chatBotDisplay(false);
    return () => {
      if (props.stopBackgroundScroll) noScrollBody(false);
      noScrollBody(true);
      chatBotDisplay(true);
    };
  }, []);
  return (
    <>
      {shown ? (
        <>
          {!props.noOverlay
          && (
          <div
            onClick={() => {
              if (props.onOverlayClick) {
                props.onOverlayClick();
              }
            }}
            className={classNames(
              styles.overlay,
              styles.dBlock,
            )}
          />
          ) }
          <div
            className={classNames(
              props.height == 'auto' ? styles.modal2 : styles.modal,
              styles.dBlock,
              props.noOverlay ? styles.desktopSetting : null,
              props.desktopCarSelection ? styles.heightAuto : null,
            )}
            style={{ ...(props.noPadding ? { padding: '0px' } : {}), ...additionalStyles }}
          >
            {props.children}
          </div>
        </>
      ) : null}
    </>
  );
}

Modal.propTypes = {
  noPadding: PropTypes.bool.isRequired,
};
