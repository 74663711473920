/* eslint-disable react/jsx-filename-extension */
/**
 * Author: Patanjali Kumar <patanjalikumar@gomechanic.in>
 * Supervision : Mr. Chandra Chowdhury <chandrachowdhury@gomechanic.in>
 *
 * Please try to read code thoroughly before contacting for any query.
 *
 * React
 * Zeplin URL : https://app.zeplin.io/project/5da0780780829f083448125a/screen/5da07a1885f67a23e56666a9
 *
 * This file contains code for city selector modal for desktop
 *
 * Copyright ©  2019-present GoMechanic, LLC. All rights reserved.
 *
 */

import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import s from './CitySelectorDesktop.css';
import { setPrefferedCity } from '../../actions/city';
import { selectRegion } from '../../actions/selectRegion';
import { getLocation } from '../../_services/detectLocation';
import generate from '../../serverRoutes/urlGenerators/newUrl';
import history from '../../history';
import noScrollBody from '../../_services/bodyNoScrollForModals';
import autoUtmHistory from '../../_services/autoUtmHistory';
import { fetchAllCities } from '../../_services/api/fetchAllCities';
import { setAllCities } from '../../actions/setAllCities';
import { getCityCarousels } from '../../_services/api/getCityCarousel';
import { setCityCarousels } from '../../actions/setCityCarousels';
import { isMalaysiaRequest } from '../../utilities';
import { changeCityEvent } from '../../gtm/gtmServices';

const CitySelectorDesktop = (props) => {
  useStyles(s);
  const [height, SetHeight] = useState(false);
  useEffect(
    () => {
      noScrollBody(false);
      
      !props.allCities?.length && fetchAllCities().subscribe(res => {
        props.action(setAllCities(res?.data))
      }, (error) => {
        console.log(error);
      })

      return () => {
        noScrollBody(true);
      };
    },
    [],
  );
  useEffect(() => {
    SetHeight(true);
    return () => SetHeight(false);
  }, []);
  return (
    <>
      <div className={s.overlay} onClick={() => props.hide()} />
      <div className={classNames(props.isMalaysiaRequest ? s.containerMly : s.container, height ? props.isMalaysiaRequest ? s.heightSetMly : s.heightSet : null, props.storeLocator ? s.storeDetailCityContainer : null, props.isMalaysiaRequest ? s.selectorWidth : null)}>
        <div className={props.isMalaysiaRequest ? s.citySelectorHeaderMly : s.citySelectorHeader} style={{marginBottom: '1.5rem'}}>
          <div>
            <div className={s.chooseCity}>Choose Your City</div>
            <div className={s.chooseCitySubtitle}>
              This would help us in providing you quick service.
            </div>
          </div>
          <img
            loading="lazy"
            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/AE06F0CE-9D71-4A10-BE38-38C5AA880DE7.svg"
            alt="close"
            className={s.closeBtn}
            onClick={props.hide}
          />
        </div>

        <div style={{overflow: 'auto', height: '65vh', display: 'flex', flexDirection: 'column', width: '100%'}}>
          <div className={s.countryContainer}>
            <img 
              src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/Website/Rectangle%203%402x.png" 
              alt="india" 
              className={s.flags}
            />
            <div className={s.countryName}>India</div>
          </div>
          <div className={s.sectionLine} />
        <div
          className={s.cityNamesContainer}
          style={props.accessories ? {
            alignItems: 'baseline',
          } : {height: 'unset', overflow: 'unset'} }
        >
          {(props.accessories ? props.accCities : props.allCities).map((city) => (
            <div
              className={s.cityContainer}
              key={city.id}
              onClick={() => {
                // urlServices(props.city?.sitemap_name, city.sitemap_name);
                // switchCity(city);
                if (!props.accessories) {
                  changeCityEvent(props?.user?.id, props?.city?.name, city.name)
                  // if(!isMalaysiaRequest() && ['kuala-lumpur', 'selangor'].includes(city.sitemap_name)){
                  //   window.location.href = `https://malaysia.gomechanic.com/${city?.sitemap_name}`;
                  //   return;
                  // }
                  
                  const url = generate({
                    city,
                    subCity: null,
                    serviceType: props.serviceType,
                    brand: props.cardetails?.brand,
                    model: props.cardetails?.model,
                    fuel: props.cardetails?.fuel,
                    currentPath: window.location.pathname.split('/')[2],
                  });
                  autoUtmHistory(history).push(url);
                }
                getCityCarousels(props.city?.sitemap_name)
                .subscribe(res => {
                  if(res.data){
                    // console.log(res.data)
                    props.action(setCityCarousels(res.data));
                  }
                }, err => {
                  console.log(err);
                })
                props.action(setPrefferedCity(city));
                props.action(selectRegion(null));
                props.hide();
              }}
            >
              <img loading="lazy" src={city.website_icon} className={s.cityImage} alt={city.name} />
              <div className={s.cityName}>{city.name}</div>
            </div>
          ))}
        </div>
        {/* <div className={s.countryContainerMly}>
          <img 
            src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/Website/mly-flag.png" 
            alt="malaysia" 
            className={s.flags}
          />
          <div className={s.countryName}>Malaysia</div>
        </div>
        <div className={s.sectionLineMly} />
        <div
          className={s.cityNamesContainer}
          style={props.accessories ? {
            alignItems: 'baseline',
          } : {height: 'unset', overflow: 'unset', marginBottom: '1rem'}}
        >
          {(props.accessories ? props.accCities : props.allCities).filter(city => city.country == 'malaysia').map((city) => (
            <div
              className={s.cityContainer}
              key={city.id}
              onClick={() => {
                // urlServices(props.city?.sitemap_name, city.sitemap_name);
                // switchCity(city);
                if (!props.accessories) {
                  changeCityEvent(props?.user?.id, props?.city?.name, city.name)
                  // if(!isMalaysiaRequest() && ['kuala-lumpur', 'selangor'].includes(city.sitemap_name)){
                  //   window.location.href = `https://malaysia.gomechanic.com/${city?.sitemap_name}`;
                  //   return;
                  // }
                  
                  const url = generate({
                    city,
                    subCity: null,
                    serviceType: props.serviceType,
                    brand: props.cardetails?.brand,
                    model: props.cardetails?.model,
                    fuel: props.cardetails?.fuel,
                    currentPath: window.location.pathname.split('/')[2],
                  });
                  autoUtmHistory(history).push(url);
                }
                props.action(setPrefferedCity(city));
                props.action(selectRegion(null));
                props.hide();
              }}
            >
              <img loading="lazy" src={city.website_icon} className={s.cityImage} alt={city.name} />
              <div className={s.cityName}>{city.name}</div>
            </div>
          ))}
        </div> */}
        </div>
        

        <div className={s.detectLocationContainerParent}>
          <div
            className={props.isMalaysiaRequest ? s.detectLocationContainerMly : s.detectLocationContainer}
            onClick={() => {
              getLocation();
              props.hide();
            }}
          >
            <img
              loading="lazy"
              src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/B77BEBBB-3D47-469C-B25C-8B1D53DEF4EC.svg"
              alt="Detect Location"
              className={s.detectLocationImage}
            />
            <div className={s.detectLocationText}>Detect my location</div>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CitySelectorDesktop);
