import React, { useRef, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames';
import s from './index.css';
import { tapCoverageNname } from '../../../gtm/gtmServices';

function Accordion({
  faqs,
  user_id,
  car_id,
  accordianStyle = {},
  buttonStyle = {},
  answerWrapperStyle = {},
}) {
  useStyles(s);
  const [activeAccordian, setActiveAccordian] = useState(-1);

  const refList = Array(faqs?.length ?? 0)
    .fill(0)
    .map(item => useRef());

  if (!faqs?.length) return;
  return (
    <ul
      className={s.accordian}
      style={accordianStyle}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {faqs?.map(({ question, Q, answer, A, icon, answerTag }, index) => (
        <li
          key={index}
          className={classNames(
            s.accordian_item,
            activeAccordian === index ? s.active : '',
          )}
        >
          <button
            type="button"
            className={s.button}
            style={buttonStyle}
            onClick={e => {
              e.stopPropagation();
              setActiveAccordian(activeAccordian !== index ? index : -1);
              tapCoverageNname({
                user_id: user_id,
                coverage_name: question ?? Q,
                car_id: car_id,
                page_name: 'coverage details'
              })
            }}
          >
            <div className={s.questionWrapper}>
              {icon ? <img width={24} src={icon} alt="question image" /> : null}
              {question ?? Q}
            </div>
            <span className={s.control}>
              {activeAccordian === index ? (
                <img
                  width={20}
                  src={
                    'https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/retail%20warranty%20page/up_arrow.png'
                  }
                  alt="up arrow"
                />
              ) : (
                <img
                  width={20}
                  src={
                    'https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/retail%20warranty%20page/down_arrow.png'
                  }
                  alt="down arrow"
                />
              )}{' '}
            </span>
          </button>
          {answerTag ? (
            answerTag({
              // ref: refList[index],
              style:
                activeAccordian === index
                  ? {
                      ...answerWrapperStyle,
                      display: 'block',
                    }
                  : { ...answerWrapperStyle, display: 'none' },
            })
          ) : (
            <div
              className={s.answer_wrapper}
              ref={refList[index]}
              style={
                activeAccordian === index
                  ? {
                      height: refList[index]?.current?.scrollHeight,
                      ...answerWrapperStyle,
                    }
                  : { height: '0px', ...answerWrapperStyle }
              }
            >
              <div className={s.answer}>{answer ?? A}</div>
            </div>
          )}
        </li>
      ))}
    </ul>
  );
}

export default Accordion;
