import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';
import classnames from 'classnames';
import { getFaqsServiceWise } from '../../_services/api/getFaqsServiceWise';
import { setFaqs } from '../../actions/setFaqs';
import s from './css/main.css';
import { getFaqsCityWise } from '../../_services/api/getFaqsCityWise';
import NewFAQ from './NewFAQ';
import replaceText from '../../_services/textTemplate/textTemplate';
import { clickFaqEvent } from '../../gtm/gtmServices';

const NewFaqAccordion = (props) => {
  useStyles(s);
  const initialState = props.startValue || 0;
  const [openedQuestion, setOpenedQuestion] = useState(initialState);

  let faqs = [];

  const getCarModelPageFaq = () => {
    if (props.category === 'Accessories') return props.accessoriesFaq?.carModelFaq[0];
    let i;
    for (i = 0; i < props.segmentList.length; i += 1) {
      if (props.category === props.segmentList[i]?.name) break;
    }
    return props.accessoriesFaq.carModelFaq[(i + 1) % 5];
  };

  if (props.faqs && !props.accessories) {
    faqs = props.faqs;
    if (props.bikeEnquiry) faqs = props.bikeenquirs.faq;
    if (props.beatEstimate) faqs = props.home?.beatEstimate?.faq;
    if (props.gmbData && props.gmbData.faq && !props.isServicesFaq && !props.isCityFaq) faqs = props.gmbData.faq;
    if (props.offerAppPage) faqs = props.offersPage.faqs;
    if (props.serviceDetails) faqs = props.faqsData ? props.faqsData : props.faqs;
    if(props.obdFaq) {
      faqs = props?.obdDetails?.faq || [];
    }
    if (props.milesPage) {
      faqs = props?.milesProductData?.faq || [];
    }
  }

  if (props.accessories) {
    if (props.faqData) faqs = props.faqData;
    else if (props.category && props.accCar) {
      faqs = getCarModelPageFaq();
    } else faqs = props.accessoriesFaq.generalFaq;
  }

  if(props.storeFaqs)
    faqs = props.gmbData?.welcome;

  useEffect(() => {
    if (!props.accessories) {
      if (props.serviceType) {
        let brandSlug = null;
        try {
          brandSlug = props?.cardetails?.brand?.slug_id;
        } catch (e) {
          console.error(e);
        }

        getFaqsServiceWise(
          props?.city?.id,
          props?.serviceType?.slug,
          brandSlug,
          props?.subCategory
        ).subscribe((res) => {
          if (res.success) props.action(setFaqs(res.faqs));
          if (!res.success && props.subCategory) props.action(setFaqs(props.faqs));
        }, console.error);
      } else {
        getFaqsCityWise(props.city?.id).subscribe((res) => {
          if (res.success) props.action(setFaqs(res.faqs));
        }, console.error);
      }
    }
  }, [props.city, props.cardetails, props.serviceType, props.isServicesFaq]);

  if(props.warrantyDetailsFAQ){
    faqs = props?.warrantyDetails?.faq || [];
  }
  if(props.topAssist){
    faqs = props?.topAssistFaqs || [];
  }
  if(props.enquiryPage) faqs = props.enquiryFaqs ? props.enquiryFaqs : [];

  if(props.faqsData)
  faqs = props.faqsData

  return (
    <div className={classnames(s.faq, props.className ? props.className : "")}>
      {faqs?.slice(0, props.limit).map(
        (faq, index) => (
          <NewFAQ
            key={faq.Q || faq.question}
            setExpanded={() => {
              clickFaqEvent(props?.user?.id, props?.cardetails?.model?.id, props?.isAMCUser, null, index)
              setOpenedQuestion(index)
            }}
            question={props.accessories ? replaceText(faq.Q || faq.question, props, { category: props.category || null, brand: props.accCar && props.accCar.brand ? props.accCar?.brand?.name : null, model: props.accCar && props.accCar?.model ? props.accCar?.model?.name : null }) : replaceText(faq.Q || faq.question, props, { cityReplace: 'region' })}
            answer={props.accessories ? replaceText(faq.A || faq.answer, props, { category: props.category || null, brand: props.accCar && props.accCar?.brand ? props.accCar?.brand?.name : null, model: props.accCar && props.accCar?.model ? props.accCar?.model?.name : null }) : replaceText(faq.A || faq.answer, props, { cityReplace: 'region' })}
            expanded={openedQuestion === index}
            arrowIcon={props.arrowIcon}
          />
        ),
      )}
      { props.limit && props.limit != faqs.length
        ? <div className={s.seeMore} onClick={() => { props.setLimit(faqs.length); }}><div className={s.buttonText}>See More</div></div> : null }
    </div>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewFaqAccordion);
