import HttpHeader from '../httpHeader';
import { Http } from '../../modules/http';
import Global from '../../global';

export const getWarrantyListingApi = (category_id, car_id, city_id, user_car_id, user_id) => {
  const Headers = new HttpHeader();
  const HttpHandler = new Http();
  const ApiManager = new Global();

  const headers = Headers.get_token_headers();
//   const url = `${ApiManager.urls.api_get}v1/oauth/customer/warranty_listing?category_id=47&user_id=16276&sub_cat_id=117&car_id=366&city_id=1&user_car_id=928565955`;
  const url = `${ApiManager.urls.api_get}v1/oauth/customer/warranty_listing/?category_id=${category_id}&car_id=${car_id}&city_id=${city_id}&user_car_id=${user_car_id}&user_id=${user_id}`;
  return HttpHandler.get(url, headers);
};

export const getFaqs = (category_id) => {
    const Headers = new HttpHeader();
    const HttpHandler = new Http();
    const ApiManager = new Global();
  
    const headers = Headers.get_token_headers();
    const url = `${ApiManager.urls.api_get}v2/oauth/customer/get_faq/?category_id=${category_id}`;
    return HttpHandler.get(url, headers);
  };