import { SET_PRICE_LIST_DATA } from '../constants';

export function priceListAction(value) {
  return {
    type: SET_PRICE_LIST_DATA,
    payload: {
      value,
    },
  };
}
