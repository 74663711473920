import HttpHeader from '../httpHeader';
import { Http } from '../../modules/http';
import Global from '../../global';

export const additionalServiceApi = (cityId, carId, userCarId) => {
  const Headers = new HttpHeader();
  const HttpHandler = new Http();
  const ApiManager = new Global();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.api_get}v2/oauth/customer/our-additional-services/?city_id=${cityId}&version=3&car_id=${carId ? carId : 0}&user_car_id=${userCarId ? userCarId : 0}`;
  return HttpHandler.get(url, headers);
};
