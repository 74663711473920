import { SET_SELECTED_REGION } from '../constants';

export function selectRegion(value) {
  return {
    type: SET_SELECTED_REGION,
    payload: {
      value,
    },
  };
}
