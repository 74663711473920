import React, { useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import s from './HeaderBar.css';
import CitySelectorModal from '../CitySelectorModal/CitySelectorModal';
import Sidebar from '../Sidebar/Sidebar';
import SelectorMain from '../ModalSelectors/SelectorMain';
import Link from '../Link';
import generate from '../../serverRoutes/urlGenerators/newUrl';
import SearchBoxMobile from './SearchBoxMobile';
import history from '../../history';
import { gomIcon, tapSideMenu, tapSearchGtmEvent, openSelectCityModalEvent } from '../../gtm/gtmServices';
import autoUtmHistory from '../../_services/autoUtmHistory';
import HeaderCart from '../HeaderCart/HeaderCart';
import { setAccessoriesVehicleType } from '../../actions/accessoriesVehicleType';

const HeaderBarMain = (props) => {
  const [viewCitySelector, SetView] = useState(false);
  const [sidebarView, SetSideBarView] = useState(false);
  const [viewVehicleSelector, SetVehicleSelectorView] = useState(false);
  const [carSelectorShow, SetCarSelectorShow] = useState(false);
  const [search, setSearch] = useState(false);

  const logoClick = () => {
    if (props.accessoriesCar) {
      autoUtmHistory(history).push('/accessories');
    } else {
      gomIcon('header', props?.cardetails?.model?.id, props?.user?.id);
      autoUtmHistory(history).push(
        `${generate({
          city: props.city,
        })}`,
      );
    }
  };

  useStyles(s);

  const isCarAvailable = () => {
    if (props.accessoriesCar) {
      return !!(
        props.accessoriesCarDetail.brand
        && props.accessoriesCarDetail.model
        && props.accessoriesCarDetail.year
      );
    }
    return !!(
      props.cardetails?.brand
      && props.cardetails?.model
      && props.cardetails?.fuel
    );
  };

  function searchBtn() {
    setSearch(true);
  }
  function closeSearch() {
    setSearch(false);
  }

  return (
    <>
      <div
        className={classNames(s.mainBar, s.mainBarRoot, s.hideOnPcTab)}
        style={
          props.noSticky
            ? {
              position: 'unset',
            }
            : null
        }
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            loading="lazy"
            className={s.backButton}
            src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/menu.svg"
            alt="back"
            onClick={() => {SetSideBarView(true); tapSideMenu()}}
          />
          {!props.accessoriesCar ? (
            <div onClick={logoClick}>
              {props.isNigerianRequest ? (
                <img
                  loading="lazy"
                  onClick={() => SetCarSelectorShow(true)}
                  alt="Gomechanic"
                  className={
                    !isCarAvailable() ? s.brandIconNg : s.brandIconGoMechLogo
                  }
                  src={
                isCarAvailable()
                  ? 'https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/HeaderBar/E8C92954-9D92-4849-99A6-30726E3E7815.png'
                  : '/icons/logo/FINA-LOGO-45-01.png'
              }
                />
              )
                : (
                  <img
                    loading="lazy"
                    onClick={() => SetCarSelectorShow(true)}
                    className={!isCarAvailable() ? s.brandIcon : s.brandIconGoMechLogo}
                    alt="Gomechanic"
                    src={
                isCarAvailable()
                  ? 'https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/HeaderBar/E8C92954-9D92-4849-99A6-30726E3E7815.png'
                  : 'https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/512x512.png'
              }
                  />
                )}
            </div>
          ) : (
            <>
              {props.isNigerianRequest ? (
                <img
                  loading="lazy"
                  onClick={() => SetCarSelectorShow(true)}
                  alt="Gomechanic"
                  className={
                    !isCarAvailable() ? s.brandIconNg : s.brandIconGoMechLogo
                  }
                  src={isCarAvailable()
                    ? 'https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/HeaderBar/E8C92954-9D92-4849-99A6-30726E3E7815.png'
                    : '/icons/logo/FINA-LOGO-45-01.png'}
                />
              )
                : (
                  <img
                    loading="lazy"
                    onClick={!isCarAvailable() ? () => SetCarSelectorShow(true) : () => logoClick()}
                    className={!isCarAvailable() ? s.brandIcon : s.brandIconGoMechLogo}
                    alt="Gomechanic"
                    src={isCarAvailable()
                      ? 'https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/HeaderBar/E8C92954-9D92-4849-99A6-30726E3E7815.png'
                      : '/icons/logo/512x512.png'}
                  />
                )}
            </>
          )}
          <span
            className={s.textBrand}
            onClick={() => SetCarSelectorShow(true)}
          >
            {isCarAvailable()
              ? props.accessoriesCar
                ? props.accessoriesCarDetail.model.name
                : props.cardetails?.model?.name
              : ''}
          </span>
          <span className={s.textFuel} onClick={() => SetCarSelectorShow(true)}>
            {isCarAvailable()
              ? props.accessoriesCar
                ? props.accessoriesCarDetail.year
                : props.cardetails?.fuel?.name
              : ''}
          </span>
        </div>
        {!props.nofucntions ? (
          <div className={s.displayFlex}>
            {!props.vehicleSelector ? (
              <div
                className={classNames(s.mainBarCityContainer, s.mr1rem)}
                onClick={() => {
                  openSelectCityModalEvent(props?.user?.id, props.city?.name);
                  SetView(true);
                }}
              >
                <span className={s.mainBarCity}>
                  {props.city && props.city?.website_icon
                    ? props.city?.name
                    : 'Select City'}
                </span>
                <img
                  loading="lazy"
                  src="/icons/ic_expand_more.svg"
                  className={s.mainBarCityExpand}
                  alt="Call Now"
                />
              </div>
            ) : (
              <div
                className={classNames(s.mainBarCityContainer, s.mr1rem)}
                onClick={() => { SetVehicleSelectorView(!viewVehicleSelector); }}
              >
                <span className={s.mainBarCity}>
                  {props.accessoriesVehicleType}
                </span>
                <img
                  loading="lazy"
                  src="/icons/ic_expand_more.svg"
                  className={s.mainBarCityExpand}
                  alt="Call Now"
                />
                {viewVehicleSelector ? (
                  <ul className={s.vehicelDropDown}>
                    <li onClick={() => props.action(setAccessoriesVehicleType('Car'))}>Car</li>
                    <li onClick={() => {
                      props.action(setAccessoriesVehicleType('Bike'));
                      autoUtmHistory(history).push('/accessories/bike-accessories');
                    }}
                    >
                      Bike
                    </li>
                  </ul>
                ) : null}
              </div>
            )}
            {/* {!props.isPaytmRequest
          && (
          <a href={props.isNigerianRequest ? 'tel:7020700617' : 'tel:8398970970'}>
            <div className={classNames(s.mainBarCallButton, s.mr1rem)}>
              <img
                loading="lazy"
                src="/icons/ic_call.svg"
                className={s.mainBarCallButtonImage}
                alt="Call Now"
              />
            </div>
          </a>
          )} */}
            {props.search ? null
              : (
                <img
                  onClick={() => {searchBtn(); tapSearchGtmEvent(props?.user?.id, props?.cardetails?.model?.id ,'search')}}
                  style={{ marginRight: '1rem' }}
                  alt="Gomechanic"
                  src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/DesktopHeaderMain/Group%20106.svg"
                />
              )}
            {props.accessoriesCar ? (
              <HeaderCart />
            ) : null}
          </div>
        ) : null}
        {/* {search ? <SearchBoxMobile close={() => closeSearch()} /> : null} */}
      </div>
      {search
        ? (
          <SearchBoxMobile
            searchTag={props.searchTag || null}
            accessories={!!props.accessoriesCar}
            close={() => closeSearch()}
          />
        )
        : null}
      {viewCitySelector ? (
        <CitySelectorModal
          hide={() => SetView(false)}
          accessories={props.accessoriesCar}
        />
      ) : null}

      {carSelectorShow ? (
        <SelectorMain
          hide={() => SetCarSelectorShow(false)}
          accessoriesCar={!!props.accessoriesCar}
        />
      ) : null}

      {sidebarView ? (
        <Sidebar
          openCityPopup={() => {
            SetSideBarView(false);
            openSelectCityModalEvent(props?.user?.id, props.city?.name);
            SetView(true);
          }}
          hide={() => SetSideBarView(false)}
        />
      ) : null}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBarMain);
