// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3W3ok{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;background-color:#fff;padding:1rem 0 1rem 1rem}._1w879{padding-bottom:1rem}._3W3ok h3{font-weight:600;font-size:18px;font-size:1.125rem;line-height:24px;line-height:1.5rem;color:#253021;margin:0}._3W3ok h4{margin:0;font-weight:400;font-size:14px;font-size:.875rem;line-height:20px;line-height:1.25rem;color:rgba(0,0,0,.6)}._3W3ok section{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:distribute;justify-content:space-around;grid-gap:16px;gap:16px;grid-gap:1rem;gap:1rem;position:relative;padding:2rem 0 1rem;background-color:#fafdfa}._3W3ok section ._1L2yo{position:absolute;bottom:0;right:0;height:100%;z-index:0}._3W3ok section img:last-child{width:100px;position:absolute;right:15px;bottom:10px}", ""]);
// Exports
exports.locals = {
	"ExtendedWarrantyWrapper": "_3W3ok",
	"arrowRow": "_1w879",
	"img1": "_1L2yo"
};
module.exports = exports;
