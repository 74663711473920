import React, { useEffect } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { connect } from 'react-redux';
import styles from './main.css';
import Modal from '../Modal/Modal';
// import City from "../../_services/city"
import { setPrefferedCity } from '../../actions/city';
import { selectRegion } from '../../actions/selectRegion';
import history from '../../history';
import { getLocation } from '../../_services/detectLocation';
import generate from '../../serverRoutes/urlGenerators/newUrl';
import noScrollBody from '../../_services/bodyNoScrollForModals';
import autoUtmHistory from '../../_services/autoUtmHistory';
import { fetchAllCities } from '../../_services/api/fetchAllCities';
import { setAllCities } from '../../actions/setAllCities';
import { isMalaysiaRequest } from '../../utilities';
import { changeCityEvent } from '../../gtm/gtmServices';

const CitySelectorModal = (props) => {
  useStyles(styles);
  // const city = new City();
  // useEffect(
  //   ()=>{
  //     console.log('run');
  //     city.cityDetails().subscribe((val) =>{
  //       console.log(val)
  //     },(err) =>{
  //       console.log(err)
  //     })
  //     // tryit();
  //
  //   }, []
  // );
  useEffect(
    () => {
      noScrollBody(false);
      !props.allCities?.length && fetchAllCities().subscribe(res => {
        props.action(setAllCities(res?.data))
      }, (error) => {
        console.log(error);
      })
      return () => {
        noScrollBody(true);
      };
    },
    [],
  );

  return (
    <>
      <Modal citySelector>
        <div className={styles.citySelectorModal}>
          <div className={styles.flexContainer}>
            <div className={styles.cityTitle}>Choose Your City</div>
            <img
              loading="lazy"
              src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/AE06F0CE-9D71-4A10-BE38-38C5AA880DE7.svg"
              alt="close"
              className={styles.closeBtn}
              onClick={props.hide}
            />
          </div>
          <div className={styles.helpertext}>
            This would help us in providing you quick service.
          </div>
          
            <div className={styles.subcontainer}>
              <div className={styles.countryContainer}>
                <img 
                  src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/Website/Rectangle%203%402x.png" 
                  alt="india" 
                  className={styles.flags}
                />
                <div className={styles.countryName}>India</div>
              </div>
              <div className={styles.sectionLine} />
              <div
            className={styles.cityNamesContainer}
            style={props.accessories ? {
              display: 'grid',
              width: '100%',
              gridTemplateColumns: '1fr 1fr 1fr',
              alignItems: 'baseline',
              justifyContent: 'space-between',
            } : {height: 'unset', overflow: 'unset'}}
          >
            {(props.accessories ? props.accCities : props.allCities).map((city) => (
              <div
                className={styles.cityContainer}
                key={city.id}
                onClick={() => {
                  // urlServices(props.city?.sitemap_name, city.sitemap_name);
                  // switchCity(city);
                  changeCityEvent(props?.user?.id, props?.city?.name, city.name)
                  if (!props.accessories) {
                    // if(!isMalaysiaRequest() && ['kuala-lumpur', 'selangor'].includes(city.sitemap_name)){
                    //   window.location.href = `https://malaysia.gomechanic.com/${city?.sitemap_name}`;
                    //   return;
                    // }
                    const url = generate({
                      city,
                      subCity: null,
                      serviceType: props.serviceType,
                      brand: props.cardetails?.brand,
                      model: props.cardetails?.model,
                      fuel: props.cardetails?.fuel,
                      currentPath: window.location.pathname.split('/')[2],
                    });
                    autoUtmHistory(history).push(url);
                  }
                  props.action(setPrefferedCity(city));
                  props.action(selectRegion(null));
                  props.hide();
                }}
              >
                <img loading="lazy" src={city.website_icon} className={styles.cityImage} alt={city.name} />
                <div className={styles.cityName}>{city.name}</div>
              </div>
            ))}
          </div>
          
          {/* <div className={styles.sectionLineMly} />
          <div
            className={styles.cityNamesContainerMlyCity}
            style={props.accessories ? {
              display: 'grid',
              width: '100%',
              gridTemplateColumns: '1fr 1fr 1fr',
              alignItems: 'baseline',
              justifyContent: 'space-between',
            } : {height: 'unset', overflow: 'unset'}}
          >
            {(props.accessories ? props.accCities : props.allCities).filter(city => city.country == 'malaysia').map((city) => (
              <div
                className={styles.cityContainer}
                key={city.id}
                onClick={() => {
                  // urlServices(props.city?.sitemap_name, city.sitemap_name);
                  // switchCity(city);
                  changeCityEvent(props?.user?.id, props?.city?.name, city.name)
                  if (!props.accessories) {
                    // if(!isMalaysiaRequest() && ['kuala-lumpur', 'selangor'].includes(city.sitemap_name)){
                    //   window.location.href = `https://malaysia.gomechanic.com/${city?.sitemap_name}`;
                    //   return;
                    // }
                    const url = generate({
                      city,
                      subCity: null,
                      serviceType: props.serviceType,
                      brand: props.cardetails?.brand,
                      model: props.cardetails?.model,
                      fuel: props.cardetails?.fuel,
                      currentPath: window.location.pathname.split('/')[2],
                    });
                    autoUtmHistory(history).push(url);
                  }
                  props.action(setPrefferedCity(city));
                  props.action(selectRegion(null));
                  props.hide();
                }}
              >
                <img loading="lazy" src={city.website_icon} className={styles.cityImage} alt={city.name} />
                <div className={styles.cityName}>{city.name}</div>
              </div>
            ))}
          </div> */}
            </div>
          
          <div
            className={styles.detectLocationContainer}
            onClick={() => {
              getLocation();
              props.hide();
            }}
          >
            <img
              loading="lazy"
              src="https://cdn.zeplin.io/5da0780780829f083448125a/assets/B77BEBBB-3D47-469C-B25C-8B1D53DEF4EC.svg"
              alt="Detect Location"
              className={styles.detectLocationImage}
            />
            <div className={styles.detectLocationText}>Detect my location</div>
          </div>
        </div>
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CitySelectorModal);
