import HttpHeader from '../httpHeader';
import Global from '../../global';
import { Http } from '../../modules/http';

export const getMonsoonService = (cityId, carId) => {
    const Headers = new HttpHeader();
    const ApiManager = new Global();
    const HttpHandler = new Http();
  
    const headers = Headers.get_token_headers();
    const url = `${ApiManager.urls.api_get}v4/oauth/customer/weather-services?city_id=${cityId}&car_id=${carId}`;
    return HttpHandler.get(url, headers);
};