import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './css/main.css';

const GomechWorkItem = (props) => {
  useStyles(s);
  return (
    <>
      <div className={s.item}>
        <div className={s.numberContainer}>
          <div className={s.numberItem}>
            {props.number}
          </div>
          {!props.noLine && <div className={s.numberLine} /> }
        </div>

        <div className={s.titleSubtitle}>
          <div className={s.title}>
            {props.title}
          </div>

          <div className={s.subtitle}>
            {props.subtitle}
          </div>
        </div>

        <div className={s.image}>
          {props.image && <img src={props.image} alt="Work" />}
        </div>

      </div>

    </>
  );
};

export default GomechWorkItem;
