import HttpHeader from '../httpHeader';
import Global from '../../global';
import { Http } from '../../modules/http';

export const createLead = (car_type_id, cityObj, phoneNumber, userName = '',utm_source, source_id = 15) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.api_get}v1/oauth/leads/create_new_lead`;
  return HttpHandler.post(
    url,
    {
      name: userName,
      car_type_id,
      mobile: phoneNumber,
      location: cityObj.name,
      utm_source,
      source_id,
      // latitude,
      // longitude
    },
    headers,
  );
};

export const createLeads = (payload) => {
  const Headers = new HttpHeader();
  const ApiManager = new Global();
  const HttpHandler = new Http();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.api_get}v1/oauth/leads/create_new_lead`;
  return HttpHandler.post(
    url,
    payload,
    headers,
  );
};
