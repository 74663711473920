/*
 Author : Rishabh Tyagi

 This component will contain the "Related Blogs" section as in
https://app.zeplin.io/project/5da0780780829f083448125a/screen/5da07e90a303922fd8070ede
 */
import React from 'react';
import classNames from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './css/main.css';
import PlaceholderImage from '../../PlaceholderImages/PlaceholderImage';
import Link from '../../Link/Link';

const GoGuranteeSection = props => {
  useStyles(s);

  return (
    <React.Fragment key={props.keyId}>
      {props.showPopup ? props.children : null}
      <div
        className={props.popularServices == 1 ? classNames(s.padding, s.positionRelative) : classNames(s.container, s.positionRelative)}
        onClick={props.onClick}
        style={{backgroundColor: props.bgColor}}
      >
        {/* <PlaceholderImage
          placeHolderImage="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Admin/carouselPlaceholder.png"
          loading="lazy"
          src={props.articleImage}
          style={props.style || {}}
          className={
            props.popularServices == 1 ? s.popularServicesImage : s.gridImage
          }
          alt={props.altTag ? props.altTag : "Banner"}
        />  */}
        <div className={s.guaranteeTitle}>{props.guaranteeTitle}</div>
      </div>
    </React.Fragment>
  );
};

export default GoGuranteeSection;
