import React, { useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames';
import s from './main.css';
import HideOnTabPC from '../../../_wrappers/HideOnTabPC';
import HideOnMobile from '../../../_wrappers/HideOnMobile';
import { sendAppLinkAPI } from '../../../_services/api/sendAppLink';
import { downloadAppGtmEvent, getAppLinkEvent, tapDownloadFromStoreAppEvent } from '../../../gtm/gtmServices';
import Toast from '../../Toast/Toast';
import { isMalaysiaRequest } from '../../../utilities';

const AppDetails = (props) => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    show: false,
    text: '',
  });

  const sendLink = () => {
    const inputValidator = mobileNumber.toString(10);
    if (isNaN(mobileNumber) || mobileNumber.length !== 10 || !inputValidator.match(/^[56789]\d{9}$/)) {
      setToastConfig({ ...toastConfig, show: true, text: 'Mobile number not valid.' });
    } else {
      getAppLinkEvent(
        props?.user?.id, mobileNumber, props?.cardetails?.model?.id
      );
      setLoading(true);
      sendAppLinkAPI(mobileNumber).subscribe(
        (res) => {
          setLoading(false);
          setMobileNumber('');
          setToastConfig({ ...toastConfig, show: true, text: 'You\'ll receive the link shortly.' });
        },
        (err) => {
          setLoading(false);
        },
      );
    }
  };

  const sendLinkMly = () => {
    if (isNaN(mobileNumber) || !(mobileNumber.length == 9 || mobileNumber.length == 10 || mobileNumber.length == 11)) {
      setToastConfig({ ...toastConfig, show: true, text: 'Mobile number not valid.' });
    } else {
      getAppLinkEvent(
        props?.user?.id, mobileNumber, props?.cardetails?.model?.id
      );
      setLoading(true);
      sendAppLinkAPI(mobileNumber).subscribe(
        (res) => {
          setLoading(false);
          setMobileNumber('');
          setToastConfig({ ...toastConfig, show: true, text: 'You\'ll receive the link shortly.' });
        },
        (err) => {
          setLoading(false);
        },
      );
    }
  };
  const handleInputChange = (event) => {
    if (isNaN(event.target.value)) return;
    setMobileNumber(event.target.value);
  };

  useStyles(s);
  return (
    <>
      <div
        style={{ marginTop: '1.875rem' }}
        className={`${s.responsiveContainerDownloadApp} ${props.nearMe ? s.alignContainerCenter : ''}`}
      >
        <HideOnTabPC>
          <div className={s.downloadAppContent}>
            {isMalaysiaRequest() ? 'Choose and book a seamless car service experience and get upto RM 30 off with the GoMechanic App' : `Choose and book a seamless car ${props.accessoriesEnquiry ? 'accessories' : 'service'} experience and get upto Rs 750 off with the GoMechanic App`}
          </div>
        </HideOnTabPC>
        <div className={s.downloadApp}>
          <img
            style={{
              width: '299px',
              height: '353px',
              objectFit: 'cover',
            }}
            loading="lazy"
            src={isMalaysiaRequest() ? 'https://gomechprod.blob.core.windows.net/websiteasset/malaysia/appDetails/malay.png' : props.accessoriesEnquiry ? 'https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/Website/png%20paytm.png' : 'https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/Website/gm-app-download-update.jpg'}
            alt="Download GoMechanic App"
          />
        </div>

        <div className={s.downloadResponsive}>
          {/* <div className={s.downloadResponsiveContent}> */}
          <HideOnMobile>
            <div className={s.downloadAppContent}>
              {isMalaysiaRequest() ? 'Choose and book a seamless car service experience and get upto RM 30 off with the GoMechanic App' : `Choose and book a seamless car ${props.accessoriesEnquiry ? 'accessories' : 'service'} experience and get upto Rs 750 off with the GoMechanic App`}
            </div>
          </HideOnMobile>
          {/* </div> */}
          <div className={classNames(s.flexDownloadApp, s.mlr1rem, s.justifySpaceB)}>
            <a
              className={classNames(s.downloadButton, s.flexRow, s.justifySpaceB)}
              target="_blank"
              href="https://play.google.com/store/apps/details?id=gomechanic.retail&hl=en_IN"
              onClick={() => { 
                downloadAppGtmEvent({ app: 'android' });
                tapDownloadFromStoreAppEvent(
                  props?.user?.id, 'play_store', props?.cardetails?.model?.id
                );
              }}
              rel="noreferrer"
            >
              <img loading="lazy" src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/Website/play-store.png" className={s.downloadPlayImg} alt="Play store" />
            </a>

            <a
              className={classNames(s.downloadButton, s.flexRow, s.justifySpaceB)}
              target="_blank"
              href="https://apps.apple.com/in/app/gomechanic-car-service/id1498891908"
              onClick={() => { 
                downloadAppGtmEvent({ app: 'ios' });
                tapDownloadFromStoreAppEvent(
                  props?.user?.id, 'app_store', props?.cardetails?.model?.id
                );
              }}
              rel="noreferrer"
            >
              <img loading="lazy" src="https://gomechprod.blob.core.windows.net/gomech-retail/gomechanic_assets/Website/app-store.png" className={s.downloadIosImg} alt="App store" />
            </a>
          </div>
          <div className={s.appLinkForm}>
            <div className={s.mobileInputContainer}>
              <img
                loading="lazy"
                src={isMalaysiaRequest() ? 'https://gomechprod.blob.core.windows.net/websiteasset/malaysia/appDetails/malaysia.svg' : 'https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/AppDetails/F330B59C-DA83-4500-A7B2-A0D858252D88.svg'}
                className={s.flag}
                alt="Input"
              />

              <input
                onChange={handleInputChange}
                type="tel"
                value={mobileNumber}
                className={s.mobileInput}
                placeholder="Mobile Number"
                maxLength={isMalaysiaRequest() ? 11 : 10}
              />
            </div>
            <div onClick={isMalaysiaRequest() ? sendLinkMly : sendLink} className={s.getAppLink}>{loading ? 'Sending...' : 'GET APP LINK'}</div>
          </div>
        </div>
      </div>
      {toastConfig.show ? (
        <Toast
          hideHandler={setToastConfig}
          text={toastConfig.text}
        />
      ) : null}
    </>
  );
};

export default AppDetails;
