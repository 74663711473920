import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './Skeleton.css';

const CarouselSkeleton = (props) => {
  useStyles(s);
  return (
    <>
      <div
        key={props.keyId}
        className={s.bannerPost}
        style={{
          flexDirection: 'row',
          width: '100%',
        }}
      >
        
        <div className={s.carouseSkeletonlImage}>

        </div>
      </div>
    </>
  );
};

export default CarouselSkeleton;
