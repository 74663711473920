import React, { useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './index.css';
import { connect } from 'react-redux';
import BookYourClaim from './bookYourClaim';
import { tapBookClaim } from '../../../gtm/gtmServices';

function CartAction(props) {
  useStyles(s);

  const { data } = props;

  const [showBookClaimModal, setBookClaimModal] = useState(false);

  return (
    <>
    {showBookClaimModal ?
    <BookYourClaim
      hide={() => {
        setBookClaimModal(false);
        const z = document.getElementsByTagName('body');
        z[0].style.overflow = 'scroll';
      }}
    />
    : null
    }
    <div className={s.actionWrapper}>
      <button disabled={data?.disable_claim_button ?? true} className={data?.disable_claim_button ?? true ? s.disableBtn : null} type="button" onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setBookClaimModal(true);
          tapBookClaim({
            user_id: props.user?.id,
            page_name: "warrantySummary",
          })
        }}>
        <div>
            <span>{data?.button_text}</span>
        </div>
      </button>
    </div>
    </>
  );
}

function mapStateToProps(state) {
  return state;
}


function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CartAction);
