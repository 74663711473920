import React from 'react';
import Layout from '../../../components/Layout';
import HomePage from '../../../components/Homepage/Homepage';
import MobileHomePage from '../../../components/Homepage/MobileHomePage';
import ClaimWarranty from '../../../components/ClaimWarranty'
import { viewHomePageEvent } from '../../../gtm/gtmServices';

const title = 'Homepage';

export const HomePageObj = {
  chunks: ['homepage'],
  title,
  component: (
    <Layout marketingHeaderShow>
      <HomePage />
    </Layout>
  ),
  chatbotControl: 'show',
};

export const MobileHomePageObj = {
  chunks: ['homepage'],
  title,
  component: (
    <Layout marketingHeaderShow>
      <MobileHomePage />
    </Layout>
  ),
  // component: <ClaimWarranty />,
  chatbotControl: 'show',
};

const action = (context, params) => {
  // console.log('context = print ', JSON.stringify(context.device))
  // const temporaryObj = {};
  // const routeDetails = routeValidatorCity(context.store, params);
  // if (!routeDetails.validated) {
  //   try {
  //     const currentStore = context.store.getState();
  //     if (currentStore.city) {
  //       temporaryObj.redirect = `/${currentStore.city.sitemap_name}`;
  //     }
  //   } catch (e) {
  //     console.log(`Could not redirect to city page. ${e}`);
  //   }
  // }
  // console.log('context', context?.store?.getState()?.device)

  /* Please be careful before editing, HomePageObj is conditionally returned on city page
   in services route folder */
   const store = context?.store?.getState();
   viewHomePageEvent(store?.user?.id, store?.cardetails?.model?.id);
   const obj = store?.device?.toLowerCase() === 'mobile' ? {...MobileHomePageObj} : {...HomePageObj};
   
  return {
    ...obj,
  };
};

export default action;
