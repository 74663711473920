import React, { useState, useEffect } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './bookYourClaim.css';
import * as Sentry from '@sentry/browser';
import classNames from 'classnames';
import { connect } from 'react-redux';
import autoUtmHistory from '../../../_services/autoUtmHistory';
import history from '../../../history';
import NewTimeSelectorV2 from '../../NewTimeSelectorV2/NewTimeSelectorV2';
import { fetchTimeSlots } from '../../../_services/api/fetchTimeSlots';
import { setOrderDateTime } from '../../../actions/orderDateTimeAction';
import { warrantyBookClaimCheckout } from '../../../_services/api/warrantyBookClaimCheckout';
import Toast from '../../Toast/Toast';
import LocationTypeSelector from '../../MobileCheckoutV2/LocationTypeSelector';
import setSelectedAddressId from '../../../actions/setSelectedAddressId';
import { clickBook } from '../../../gtm/gtmServices';

function BookYourClaim(props) {
  useStyles(s);
  const [appear, setAppear] = useState(false);
  const [timeSlotsObj, setTimeSlotsObj] = useState({
    loading: true,
    data: null,
  });
  const [toastConfig, setToastConfig] = useState({
    show: false,
    text: '',
    showTick: true,
    time: 1000,
  });
  const [confirmation, setConfirmation] = useState(false);
  const [showLocationTypeSelector, setShowLocationTypeSelector] = useState(
    false,
  );
  const { orderDateTime, TimeSelect } = props;

  useEffect(() => {
    fetchTimeSlots(props.city?.id).subscribe(
      res => {
        if (res.data) {
          setTimeSlotsObj({
            loading: false,
            data: res.data?.slot_data,
          });
          // console.log("res.data", res.data);
        }
      },
      err => {
        setToastConfig({
          ...toastConfig,
          show: true,
          text: 'Time slots cannot be loaded.',
        });
      },
    );
  }, [props.city?.id]);

  const setOrderDateTimeFn = obj => {
    props.action(setOrderDateTime(obj));
  };

  useEffect(() => {
    if (!props.cartResolved || !props.user.is_logged_in) {
      return;
    }
    const savedAddresses = props.user.address || [];
    // noScrollBody(false);
    if (savedAddresses.length > 0) {
      if (!selectedAddressId) {
        setAddressId(savedAddresses[0].id);
      }
    } else {
      setShowLocationTypeSelector(true);
    }
  }, [props.user, props.cartResolved]);

  useEffect(() => {
    const datesToShow = (timeSlotsObj.data || [])
      .filter(date => date.is_date_enable)
      .map(slot => ({
        dateValue: slot.date,
        date: new Date(slot.date).getDate(),
        day: new Date(slot.date).getDay(),
        value: slot.date,
        timeSlots: slot.time_slots,
        // .filter((timeSlot) => timeSlot.is_enable)
      }));
    if (timeSlotsObj.data) {
      setOrderDateTimeFn({
        date: datesToShow[0].dateValue,
        time: datesToShow[0].timeSlots.filter(timeSlot => timeSlot.is_enable)[0]
          .time,
        timeSlots: datesToShow[0].timeSlots,
        mode: 0,
        // amount: datesToShow[0].timeSlots.filter((timeSlot) => timeSlot.is_enable)[0].amount,
      });
    }
  }, [timeSlotsObj]);

  useEffect(() => {
    setTimeout(() => setAppear(true), 100);
  }, []);

  const hideHandler = () => {
    setAppear(false);
    setTimeout(() => props.hide(), 1000);
  };

  const savedAddresses = props.user?.address || [];
  const setAddressId = id => props.action(setSelectedAddressId(id));
  const { selectedAddressId } = props;
  const addressId = selectedAddressId;

  useEffect(() => {
    if (confirmation) {
      createOrder(getCheckoutObject());
    }
  }, [confirmation]);

  const initialQueryString =
    (props.initialQueryString && props.initialQueryString.length
      ? `?${props.initialQueryString}`
      : null) || '';

  function orderTracking(orderData, checkoutResponse) {
    const orderId = checkoutResponse.data.order_id;
    let [currency, transaction_id] = [null, null];
    try {
      currency = isMalaysiaRequest() ? 'MYR' : 'INR';
      transaction_id = orderId;
    } catch (e) {
      console.error(e);
    }
    autoUtmHistory(history).replace(`/order/${orderId}${initialQueryString}`);
  }

  const getCheckoutObject = () => ({
    address_id: addressId,
    car_id: props.cardetails?.fuel?.car_type_id,
    channel: 'Website',
    city_id: props.city?.id,
    latitude: props.user.address.reduce(
      (a, addr) => (addr.id === addressId ? addr.latitude : a),
      null,
    ),
    lead_source: 'website',
    longitude: props.user.address.reduce(
      (a, addr) => (addr.id === addressId ? addr.longitude : a),
      null,
    ),
    pick_date: props.orderDateTime.date,
    time_slot: props.orderDateTime.time,
    user_car_id: props.userSelectedCarId,
  });

  async function createOrder(orderObjectIncoming) {
    let orderObject = orderObjectIncoming;
    warrantyBookClaimCheckout(orderObject).subscribe(
      res => {
        if (!res.status) Sentry.captureException(res);
        orderTracking(orderObject, res);
      },
      err => {
        Sentry.captureException(err);
        console.log(JSON.stringify(err));
        setToastConfig({
          ...toastConfig,
          show: true,
          text: 'Issue on Create Order',
        });
        console.error;
      },
    );
  }

  return (
    <>
      {showLocationTypeSelector ? (
        <LocationTypeSelector
          setAddressId={id => {
            setAddressId(id);
          }}
          hide={() => {
            setShowLocationTypeSelector(false);
          }}
          showSearchPage={() => {
            setShowLocationTypeSelector(false);
            autoUtmHistory(history).push('/add-new-address');
          }}
        />
      ) : null}
      <div
        onClick={hideHandler}
        className={classNames(s.overlay, appear ? s.overlayAppear : null)}
      />
      <div
        className={classNames(s.box, appear ? s.boxAppear : null)}
        style={props.margin && props.margin}
      >
        <div className={classNames(s.topBarFix, s.topBarFix1)}>
          <div className={s.titleContainer}>
            <div className={s.titleText}>Book Your Claim</div>
            <img
              loading="lazy"
              onClick={hideHandler}
              src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/retail%20warranty%20page/cross.png"
              className={s.closeBtn}
              alt="Close"
            />
          </div>
        </div>
        <div className={s.mainContent}>
          <div
            className={s.addressScreen}
            style={{ marginTop: '1rem', marginBottom: '1rem' }}
          >
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#fff8f1',
                boxShadow:
                  '0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0.0625rem 0 rgba(0, 0, 0, 0.12), 0 0 0.0625rem 0 rgba(0, 0, 0, 0.2)',
              }}
            >
              <div className={s.root}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    alt="img"
                    src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Checkout/noun_location_2512138.png"
                    className={s.locationImg}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '0 1rem',
                    }}
                  >
                    <span className={s.heading1}>Pickup Address</span>
                    {savedAddresses && savedAddresses.length && (
                      <div
                        className={s.lineClamp2}
                        style={{
                          fontWeight: '500',
                          fontSize: '0.9375rem',
                          display: 'flex',
                          alignItems: 'center',
                          textAlign: 'justify',
                          lineHeight: 1.6,
                          color: '#707070',
                          margin: '0.3125rem 0 0',
                        }}
                      >
                        {(() => {
                          const selectedAddrArray = [...savedAddresses].filter(
                            addr => addr.id === addressId,
                          );
                          if (selectedAddrArray.length) {
                            const item = selectedAddrArray[0];
                            return (
                              `${item.address_1}${item.address_2}`.slice(
                                0,
                                30,
                              ) + '...'
                            );
                          }
                          return '';
                        })()}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={s.addNewAddrText}
                  onClick={() => {
                    autoUtmHistory(history).push('/add-new-address');
                  }}
                >
                  <span>Change</span>
                </div>
              </div>
            </div>
          </div>

          <div className={s.subTextLine}>
            Update your exact Map Location For Hassle Free Pickups!
          </div>

          <div className={s.mainContainer}>
            <div className={s.title}>Select your preferred Date & Time</div>
            <div className={s.servicesContainer}>
              <NewTimeSelectorV2 slots={timeSlotsObj.data} source />
            </div>
          </div>
          <div className={s.noteContent}>
            Note:{' '}
            <span className={s.claimText}>
              Claim approval will depend on inspection
            </span>
          </div>
        </div>

        <div className={s.bottomRedBar}>
          <div
            className={s.addCartText}
            onClick={() => {
              if (orderDateTime.time !== TimeSelect) {
                setToastConfig({
                  ...toastConfig,
                  show: true,
                  text: 'Please Select the Time Slot',
                });
                return;
              }
              setConfirmation(true);
              clickBook({
                user_id: props.user?.id,
                page_name: 'warranty claim checkout',
              });
            }}
          >
            Book
          </div>
        </div>
      </div>
      {toastConfig.show ? (
        <Toast hideHandler={setToastConfig} text={toastConfig.text} />
      ) : null}
    </>
  );
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: action => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BookYourClaim);
