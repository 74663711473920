import React from 'react';
import s from './index.css';
import { connect } from 'react-redux';
import useStyles from 'isomorphic-style-loader/useStyles';

const Insights = (props) => {
  useStyles(s);
  const { data } = props;

  return (
    <>
      <section className={s.insightsComponent}>
        <h1 className={s.heading}>{data?.title}</h1>
        <div className={s.insightsBox}>
          <div className={s.insightsInnerBox1}>
            <div style={{ display: 'grid' }}>
              <div className={s.savingText}>{data?.text}</div>
              <strong className={s.amount}>{props.currency.symbol}{isNaN(Number(data?.savings_amount)) ? 0 : Number(data?.savings_amount).toLocaleString()  }</strong>
            </div>
            <img
              src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/retail%20warranty%20page/Insights.svg"
              alt="img"
            />
          </div>
          <div className={s.insightsInnerBox2}>
            {data?.savings_magnitude_text}
          </div>
        </div>
      </section>
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
      action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Insights);
