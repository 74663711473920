/**
 * Author: Patanjali Kumar <patanjalikumar@gomechanic.in>
 * Supervision : Mr. Chandra Chowdhury <chandrachowdhury@gomechanic.in>
 *
 * Please try to read code thoroughly before contacting for any query.
 *
 * React
 * Zeplin URL : https://app.zeplin.io/project/5e15ab3536e10057c47abad8/screen/5e15abb8c9a0c584152ba8b9
 *
 * This file contains code for left sidebar being used in desktop login, coupon page etc..
 *
 * Copyright ©  2019-present GoMechanic, LLC. All rights reserved.
 *
 */

import React, { useEffect, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { forkJoin } from 'rxjs';
import s from './DesktopRightSideBar.css';
import LoginSectionDesktop from './LoginSectionDesktop/LoginSectionDesktop';
import Toast from '../Toast/Toast';
import CouponPage from '../CouponPage/CouponPage';
import { makeCartAPIObject } from '../../utilities';
import { addToCartApi } from '../../_services/api/cartApis';
import { setCoupon } from '../../actions/setCoupon';
import {
  applyAccessoriesCoupon,
  removeAccessoriesCoupon,
} from '../../_services/api/accessories/cartApi';
import { getCartTotalWithAccessories } from '../../_services/cartServices';

import { setUserAccessoriesCart } from '../../actions/userAccessoriesCart';
import setToastConfigAction from '../../actions/setToastConfig';
import { cartActions, cartManipulateV3 } from '../../_services/api/serviceHandlers/megaServiceCart';
import { accessoriesAppyCouponGtmEvent, applyCouponSuccessEvent, removeCouponEvent } from '../../gtm/gtmServices';

const DesktopRightSideBar = (props) => {
  useStyles(s);
  const [appear, setAppear] = useState(false);
  const disappear = () => {
    setAppear(false);
    setTimeout(() => props.hideHandler(), 1000);
  };
  const [toastConfig, setToastConfig] = useState({
    show: false,
    text: '',
    showTick: true,
    time: 1500,
  });

  const applyCouponToAllCart = (coupon) => {
    let cartData = makeCartAPIObject([...props.cart]);
    cartData = {
      ...cartData,
      coupon_code: coupon,
      gom_discount: 0,
      subscription_used: false,
      use_gom_credits: false,
    };
    forkJoin([addToCartApi(cartData), applyAccessoriesCoupon(coupon)]).subscribe((responseList) => {
      if (!responseList[0].status && responseList[0].error
        && !responseList[1].status && responseList[1].error) {
        alert(responseList[0].error + responseList[1].error);
      }
      disappear();
      if (responseList[0].data && responseList[0].data.coupon
        && responseList[0].data.coupon.coupon_code) {
        props.dispatch(setCoupon(responseList[0].data.coupon));
      }
      if (responseList[1].data) {
        props.dispatch(setUserAccessoriesCart(responseList[1].data));
        const coupon_id = responseList[1].data.coupon_data.id;
        const user_id = props.user ? props.user.id : null;
        accessoriesAppyCouponGtmEvent({
          coupon_id, user_id,
        });
      }
    }, (err) => {
      console.error(err);
      props.dispatch(setToastConfigAction({
        show: true,
        text: (err && err.response && err.response.message) ? err.response.message : 'Failed',
      }));
      disappear();
    });
  };

  const applyCouponToAccessoriesCart = (coupon) => {
    applyAccessoriesCoupon(coupon).subscribe((res) => {
      if (!res.data && res.message) {
        props.dispatch(setToastConfigAction({
          show: true,
          text: res.message || 'Failed',
        }));
      }
      disappear();
      if (res.data) {
        props.dispatch(setUserAccessoriesCart(res.data));
        const coupon_id = res.data.coupon_data.id;
        const user_id = props.user ? props.user.id : null;
        accessoriesAppyCouponGtmEvent({
          coupon_id, user_id,
        });
      }
    }, (err) => {
      console.err(err);
      props.dispatch(setToastConfigAction({
        show: true,
        text: (err && err.response && err.response.message) ? err.response.message : 'Failed',
      }));
      disappear();
    });
  };


  const applyCouponToRetailsCart = (coupon) => {
    const onApiComplete = () => {
      disappear();
      applyCouponSuccessEvent(
        props?.user?.id, coupon, null, true, null, props?.cardetails?.model?.id, props?.isAMCUser,
      );
    };
    const onApiFailure = (error) => {
      applyCouponSuccessEvent(
        props?.user?.id, coupon, null, false, null, props?.cardetails?.model?.id, props?.isAMCUser,
      );
      props.dispatch(setToastConfigAction({
        show: true,
        text: (error && error.response && error.response.message) ? error.response.message : 'Failed',
      }));
    };
    const type = cartActions.applyCoupon;
    cartManipulateV3({
      type,
      couponToApply: coupon,
      onApiComplete,
      onApiFailure,
      // onApiInit,
    });
  };

  const applyGoAppMoneyToRetailCart = () => {
    const onApiComplete = (error) => {
      disappear();
      props.dispatch(setToastConfigAction({
        show: true,
        text: props.user.go_app_money < 1 ? 'Your GoApp Money is 0' : (error && error.message) ? error.message : 'Success',
      }));
    };
    const onApiFailure = (error) => {
      props.dispatch(setToastConfigAction({
        show: true,
        text: (error && error.message) ? error.message : 'Failed',
      }));
    };
    const type = cartActions.goAppMoney;
    cartManipulateV3({
      type,
      goAppMoneyToApply: true,
      onApiComplete,
      onApiFailure,
      // onApiInit,
    });
  };
  // const applyCouponToRetailsCart = (coupon) => {
  //   let cartData = makeCartAPIObject([...props.cart]);
  //   cartData = {
  //     ...cartData,
  //     coupon_code: coupon,
  //     gom_discount: 0,
  //     subscription_used: false,
  //     use_gom_credits: false,
  //   };
  //
  //   addToCartApi(cartData).subscribe((res) => {
  //     if (!res.status && res.error) {
  //       alert(res.error);
  //     }
  //     disappear();
  //     if (res.data && res.data.coupon && res.data.coupon.coupon_code) {
  //       props.dispatch(setCoupon(res.data.coupon));
  //     }
  //   }, (err) => {
  //     console.error(err);
  //     disappear();
  //   });
  // };

  const removeCouponFromAllCart = () => {
    let cartData = makeCartAPIObject([...props.cart]);
    cartData = {
      ...cartData,
      coupon_code: '',
      gom_discount: 0,
      subscription_used: false,
      use_gom_credits: false,
    };

    forkJoin([addToCartApi(cartData), removeAccessoriesCoupon()]).subscribe((responseList) => {
      // console.log(responseList);
      if (!responseList[0].status && responseList[0].error
        && !responseList[1].status && responseList[1].error) {
        alert(responseList[0].error + responseList[1].error);
      }
      // setRemoving(false);
      if (responseList[0].data.coupon
        && !responseList[0].data.coupon.coupon_code) {
        props.dispatch(setCoupon({}));
      }
      if (responseList[1].data) props.dispatch(setUserAccessoriesCart(responseList[1].data));
    }, (err) => {
      console.error(err);
      // setRemoving(false);
    });
  };

  const removeCouponFromAccessoriesCart = () => {
    removeAccessoriesCoupon().subscribe((res) => {
      // console.log(res);
      if (!res.status && res.error) {
        alert(res.error);
      }
      // setRemoving(false);

      if (res.data) props.dispatch(setUserAccessoriesCart(res.data));
    }, (err) => {
      console.error(err);
      // setRemoving(false);
    });
  };

  const removeCouponFromRetailsCart = () => {
    // const onApiInit = () => (setRemoving(true));
    const onApiComplete = () => disappear();
    const onAPIFailure = (error) => {
      props.dispatch(setToastConfigAction({
        show: true,
        text: (error && error.response && error.response.message) ? error.response.message : 'Failed',
      }));
    };
    const type = cartActions.applyCoupon;
    removeCouponEvent(
      props?.user?.id, props?.cart?.coupon, null, props?.cardetails?.model?.id,
    );
    cartManipulateV3({
      type,
      couponToApply: '',
      // onApiInit,
      onApiComplete,
      onAPIFailure,
    });
  };

  const removeGoAppMoneyFromRetailsCart = () => {
    const onApiComplete = () => disappear();
    const onAPIFailure = (error) => {
      props.dispatch(setToastConfigAction({
        show: true,
        text: (error && error.response && error.response.message) ? error.response.message : 'Failed',
      }));
    };
    const type = cartActions.goAppMoney;
    cartManipulateV3({
      type,
      goAppMoneyToApply: false,
      onApiComplete,
      onAPIFailure,
    });
  };

  const removeCoupon = () => {
    // setRemoving(true);
    if (!(props.userAccessoriesCart
      && props.userAccessoriesCart?.order_lines?.length > 0)) {
      removeCouponFromRetailsCart();
    } else if (!(props.cart.length > 0)) removeCouponFromAccessoriesCart();
    else removeCouponFromAllCart();
  };

  const removeGoAppMoney = () => {
    if (!(props.userAccessoriesCart
      && props.userAccessoriesCart?.order_lines?.length > 0)) {
      removeGoAppMoneyFromRetailsCart();
    }
  };

  const applyCoupon = (coupon) => {
    if (!(props.userAccessoriesCart
      && props.userAccessoriesCart?.order_lines?.length > 0)) {
      applyCouponToRetailsCart(coupon);
    } else if (!(props.cart.length > 0)) applyCouponToAccessoriesCart(coupon);
    else applyCouponToAllCart(coupon);
  };

  const applyGoAppMoney = () => {
    if (!(props.userAccessoriesCart
      && props.userAccessoriesCart?.order_lines?.length > 0)) {
      applyGoAppMoneyToRetailCart();
    }
  };

  // const removeCoupon = () => {
  //   setRemoving(true);
  //   let cartData = makeCartAPIObject([...props.cart]);
  //   cartData = {
  //     ...cartData,
  //     coupon_code: '',
  //     gom_discount: 0,
  //     subscription_used: false,
  //     use_gom_credits: false,

  //   };
  //   addToCartApi(cartData).subscribe((res) => {
  //     console.log(res);
  //     if(!res.status && res.error){
  //       alert(res.error);
  //     }

  //     setRemoving(false);

  //     if (res.data.coupon && !res.data.coupon.coupon_code) {
  //       props.dispatch(setCoupon({}));
  //     }
  //   }, (err) => {
  //     console.error(err);
  //     setRemoving(false);
  //   });
  // };


  // const applyCoupon = (coupon) => {
  //   let cartData = makeCartAPIObject([...props.cart]);
  //   cartData = {
  //     ...cartData,
  //     coupon_code: coupon,
  //     gom_discount: 0,
  //     subscription_used: false,
  //     use_gom_credits: false,
  //   };
  //   addToCartApi(cartData).subscribe((res) => {
  //     console.log(res);
  //     if(!res.status && res.error){
  //       alert(res.error);
  //     }

  //     // setCouponPage(false);
  //     disappear();
  //     if (res.data && res.data.coupon && res.data.coupon.coupon_code) {
  //       props.dispatch(setCoupon(res.data.coupon));
  //     }
  //   }, (err) => {
  //     console.error(err);
  //     // setCouponPage(false);
  //     disappear();
  //   });
  // };
  useEffect(() => {
    setTimeout(() => setAppear(true), 200);
  }, []);
  return (
    <>
      {toastConfig.show ? (
        <Toast
          hideHandler={setToastConfig}
          time={toastConfig.time}
          tickIcon={toastConfig.showTick}
          text={toastConfig.text}
        />
      ) : null}
      <div
        onClick={disappear}
        className={classNames(s.overlay, appear ? s.overlayAppear : null)}
      />

      <div
        className={classNames(s.container, appear ? s.containerAppear : null)}
      >
        {/* {!props.user || !props.user.is_logged_in || props.flipkartLogin
          ? (
            <LoginSectionDesktop
              setConfirmation={props.setConfirmation && props.setConfirmation}
              accessories={props.accessories}
              disappear={disappear}
              setToastConfig={setToastConfig}
            />
          )
          : (
            <CouponPage
              applyCoupon={applyCoupon}
              removeCoupon={removeCoupon}
              hide={disappear}
              applyGoAppMoney={applyGoAppMoney}
              removeGoAppMoney={removeGoAppMoney}
              totalPrice={getCartTotalWithAccessories(props).totalPrice}
              inSideBar
            />
          )} */}
        {!props.showCouponSection && 
          <LoginSectionDesktop 
            disappear={disappear} 
            setToastConfig={setToastConfig} 
          />
        }

        {props.showCouponSection && 
          <CouponPage 
            applyCoupon={applyCoupon} 
            removeCoupon={removeCoupon} 
            hide={disappear} 
            totalPrice={getCartTotalWithAccessories(props).totalPrice} 
            inSideBar 
            applyGoAppMoney={applyGoAppMoney}
          />
        }

        {/* {props.showCouponSection &&

        } */}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DesktopRightSideBar);
