/**

Author : Shishir
utility : to make a slider scroll or Carousel  of components by passing them in as props.content in this component
 */

import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import classNames from 'classnames';
import s from './Responsive.css';


export default function BottomSlider(props) {
  useStyles(s);

  const array = props.data;
  let container = null;
  let scrollElement = null;
  var infinite = props.infinite;


  function sideScrollLeft(element,direction,speed,distance,step){
    var scrollAmount = 0;
    { infinite == 1 ?
    scrollElement.insertBefore(scrollElement.lastChild,scrollElement.firstChild) : null }

    var slideTimer = setInterval(function(){
        element.scrollLeft -= step;
        scrollAmount += step;
        if(scrollAmount >= distance){
            window.clearInterval(slideTimer);
        }
    }, speed);
  }


  function sideScrollRight(element,direction,speed,distance,step){
    { infinite == 1 ?
    scrollElement.appendChild(scrollElement.firstChild) : null }
    var scrollAmount = 0;

    var slideTimer = setInterval(function(){
        element.scrollLeft += step;
        scrollAmount += step;
        if(scrollAmount >= distance){
            window.clearInterval(slideTimer);
        }
    }, speed);
  }


  return (
      <div className={ props.popularServices == 1 ? s.flex2 : s.flex} style = { props.style ? props.style : null}>

      {props.scrollButtons
      && (
        <div
          onClick={() => {
            sideScrollLeft(container,'right',25,300,10);
          }}
          style={props.arrowContainerStyle ? { ...props.arrowContainerStyle } : {}}
        >
          <img style={props.leftArrowStyle ? { ...props.leftArrowStyle } : {}}  className={s.leftArrow} src="/graphics/Icons/rightArrow.svg" alt="Left arrow" />
        </div>
      )}


      <div
      className={s.container}
      ref={(el) => {
        container = el;
      }}
      style={props.width && props.width}
      >
      <div
        className={ props.popularServices == 1 ?  classNames(s.flexScroll2, props.customPadding ? s.customPadding : null) : s.flexScroll}
        style={props.gridTemplateColumns? props.gridTemplateColumns : props.alignItem && props.alignItem}
        ref={(el) => {
          scrollElement = el;
        }}
      >
         {props.content}
      </div>
      </div>

      {props.scrollButtons
      && (
        <div
          onClick={() => {
            sideScrollRight(container,'right',25,300,10);
          }}
          style={props.arrowContainerStyle ? { ...props.arrowContainerStyle } : {}}
        >
          <img style={props.rightArrowStyle ? { ...props.rightArrowStyle } : {}} className={s.rightArrow} src="/graphics/Icons/rightArrow.svg" alt="Left arrow" />
        </div>
      )}
      </div>
  );
}
