/* eslint-disable react/jsx-filename-extension */
/**
 * Author: Patanjali Kumar <patanjalikumar@gomechanic.in>
 * Supervision : Mr. Chandra Chowdhury <chandrachowdhury@gomechanic.in>
 *
 * Please try to read code thoroughly before contacting for any query.
 *
 * React
 * Zeplin URL :
 *
 * This file contains code for customisable Desktop screen header
 *
 * Copyright ©  2019-present GoMechanic, LLC. All rights reserved.
 *
 */
import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import s from './css/DesktopHeaderMain.css';
import Link from '../../Link';
import CitySelectorDesktop from '../../CitySelectorDesktop/CitySelectorDesktop';
import DesktopRightSideBar from '../../DesktopRightSideBar/DesktopRightSideBar';
import { servicesSearchApi } from '../../../_services/api/servicesSearchApi';
import { accessoriesSearchApiV2, globalSeacrhApi } from '../../../_services/api/accessories/accessoriesSearchApi';
import { setActiveServiceType } from '../../../actions/serviceType';
import generate from '../../../serverRoutes/urlGenerators/newUrl';
import DropDownMenu from '../../DropDownMenu/DropDownMenu';
import history from '../../../history';
import { accessoriesSearchGtmEvent, gomIcon, openSelectCityModalEvent, searchClicked, viewSearchResults } from '../../../gtm/gtmServices';
import autoUtmHistory from '../../../_services/autoUtmHistory';
import { switchServiceType } from '../../../_services/urlServices';
import DropDownMenuNew from '../../DropDownMenuNew/DropDownMenuNew';
import { setAccessoriesVehicleType } from '../../../actions/accessoriesVehicleType';

const DesktopHeaderMain = (props) => {
  useStyles(s);

  const more = ['about Us', 'Blog', 'FAQs', 'Contact', 'Careers'];

  let refInput = null;

  const [searchText, setSearchText] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [citySelectorShow, setCitySelectorShow] = useState(false);
  const [showLoginSidebar, setShowLoginSidebar] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [onSearch, setOnSearch] = useState(new Subject());
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    const subscription = onSearch
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((searchString) => {
        if (
          props.accessories
          && searchString
          && searchString.length > 0
        ) {
          setSearching(true);
          accessoriesSearchApiV2(searchString).subscribe(
            (res) => {
              if (res.status) {
                setSearchResults(res.data);
                setSearching(false);
                setSearchTerm(searchString);
              }
            },
            (err) => {
              console.error(err);
              setSearching(false);
            },
          );
        } else if (
          props.city
          // && props.cardetails?.model
          && searchString
          && searchString.length > 0
        ) {
          setSearching(true);
          const carTypeId = props.cardetails && props.cardetails?.fuel
            ? props.cardetails?.fuel.car_type_id : null;
          const cityId = props.city?.id;
          globalSeacrhApi(searchString, carTypeId, cityId, props.user?.id).subscribe(
            (res) => {
              let results = [];
              fireSearchEvent(searchString);
              results = [...results, ...res.data.retail];
              if (Array.isArray(res.data.tb)) {
                results = [...results, ...res.data.tb];
              }
              if (res.status) setSearchResults(results);
              setSearching(false);
            },
            (err) => {
              console.error(err);
              setSearching(false);
            },
          );
        }
      });
    return () => subscription.unsubscribe();
  }, [onSearch]);

  const fireSearchEvent = (searchTerm) => {
    if (searchTerm.length) {
      viewSearchResults(
        props?.user?.id, props?.cardetails?.model?.id, searchTerm,
      );
    }
  };

  useEffect(() => {
    if (searchTerm.length) {
      const term = searchTerm;
      const car_brand = props.accessoriesCarDetail.brand ? props.accessoriesCarDetail.brand.name : null;
      const user_id = props.user && props.user?.id ? props.user.id : null;
      const pagepath = window ? window.location.href : null;
      const city = props.city ? props.city?.name : null;
      accessoriesSearchGtmEvent({
        term, car_brand, user_id, pagepath, city,
      });
    }
  }, [searchTerm]);

  const onChange = (e) => {
    // console.log(e.target.value);
    setSearchText(e.target.value);
    setSearchResults([]);
    onSearch.next(e.target.value);
  };

  useEffect(() => {
    if (props.searchTag && props.searchTag !== '') {
      setSearchText(props.searchTag);
      setSearchResults([]);
      onSearch.next(props.searchTag);
      if (refInput) {
        refInput.value = props.searchTag;
        refInput.focus();
      }
    }
  }, [props.searchTag]);

  const getAccessoriesSearch = () => {
    const searchArray = [];
    if (searchResults.categories && searchResults.categories.length) {
      searchArray.push(<div className={s.resultHeader}>Category</div>);
      searchArray.push(searchResults.categories.map((result) => (
        <div
          className={s.result}
          onClick={(e) => {
            e.preventDefault();
            autoUtmHistory(history).push(`/accessories/${result.slug}`);

            if (refInput) {
              refInput.value = result.name;
              refInput.blur();
            }
          }}
        >
          {result.name}
        </div>
      )));
    }
    if (searchResults.products && searchResults.products.length) {
      searchArray.push(<div className={s.resultHeader}>Product</div>);
      searchArray.push(searchResults.products.map((result) => (
        <div
          className={s.result}
          onClick={(e) => {
            e.preventDefault();
            autoUtmHistory(history).push(`/accessories/${result.slug}-${result.product_id}?variant=${result.sku_code}`);
            if (refInput) {
              refInput.value = result.title;
              refInput.blur();
            }
          }}
        >
          {result.title}
        </div>
      )));
    }
    return searchArray;
  };

  const logoClick = () => {
    if (props.accessories) {
      autoUtmHistory(history).push('/accessories');
    } else {
      gomIcon('header', props?.cardetails?.model?.id, props?.user?.id);
      autoUtmHistory(history).push(
        `${generate({
          city: props.city,
          subCity: props.selectedRegion || null,
        })}`,
      );
    }
  };

  const vehicleTypeHandler = (e) => {
    const vehicleType = e.target.textContent;
    props.action(setAccessoriesVehicleType(vehicleType));
    if (vehicleType === 'Bike') {
      // autoUtmHistory(history).push(`/accessories/${props.segmentList[vehicleType][0].slug}`);
      autoUtmHistory(history).push('/accessories/bike-accessories');
    }
  };

  return (
    <>
      {showLoginSidebar ? (
        <DesktopRightSideBar hideHandler={() => setShowLoginSidebar(false)} />
      ) : null}
      {citySelectorShow ? (
        <CitySelectorDesktop
          accessories={props.accessories}
          hide={() => setCitySelectorShow(false)}
        />
      ) : null}
      <div
        className={classNames(
          s.PcHeaderMain,
          props.noOpacity ? s.noOpacityBg : null,
          props.darkBg ? s.pcHeaderBg : null,
          props.isSticky ? s.sticky : null,
        )}
        style={{ ...(props.style || {}) }}
      >
        <div>
          <div
            className={s.pointer}
            onClick={logoClick}
          >
            {props.isNigerianRequest ? (
              <img
                loading="lazy"
                src="/icons/logo/white45.png"
                className={s.NgPcLogo}
                alt="Gomechanic"
              />
            ) : (
              <img
                loading="lazy"
                src={props.logoShort ? '/icons/logo/logoShort.png' : 'https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/WHITE.png'}
                style={props.logoShort ? { height: '42px', width: '42px' } : null}
                className={s.PcLogo}
                alt="Gomechanic"
              />
            )}
          </div>
          {!props.accessories ? (
            <div
              className={classNames(s.cityName, s.itemName)}
              style={props.noService ? {top: "6px"} : null}
              onClick={() => {
                openSelectCityModalEvent(props?.user?.id, props.city?.name);
                setCitySelectorShow(true)
              }}
            >
              {props.city?.name}
              <img
                loading="lazy"
                src="https://cdn.zeplin.io/5da0780780829f083448125a/assets/B1A6D933-CB2C-4A34-B9F8-EFEB993DBA05.svg"
                className={s.expandMore}
                alt="Gomechanic"
              />
            </div>
          ) : null}
        </div>
        {props.vehicleSelector ? (
          <div className={s.btnContainer}>
            <div
              className={s.headerBtn}
              onClick={vehicleTypeHandler}
              style={props.accessoriesVehicleType === 'Car' ? {
                backgroundColor: '#525050',
              } : null}
            >
              Car
            </div>
            <div
              className={s.headerBtn}
              onClick={vehicleTypeHandler}
              style={props.accessoriesVehicleType === 'Bike' ? {
                backgroundColor: '#525050',
              } : null}
            >
              Bike
            </div>
          </div>
        ) : null}
        {props.searchBar && (
          <div className={s.searchBar} style={props.logoShort ? { padding: '0.7rem 1.25rem', backgroundColor: '#ffffff', borderRadius: '1.3125rem' } : null}>
            <input
              className={props.logoShort ? classNames(s.searchInput, s.fontBlack) : s.searchInput}
              onChange={onChange}
              ref={(e) => (refInput = e)}
              placeholder={
                props.accessories ? 'Search Accessories' : 'Example: Periodic Services'
              }
              onKeyDown={(e) => {
                if (e.keyCode === 13 && props.accessories) {
                  const tag = encodeURIComponent(searchText.trim());
                  if (tag) {
                    autoUtmHistory(history).push(`/accessories/tags/${tag}`);
                  }
                }
              }}
            />
            <div className={s.searchResults}>
              <div className={s.triangle} />
              <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                {!(
                  searchResults.length > 0
                  || (searchResults.categories
                    && searchResults.categories.length)
                  || (searchResults.products && searchResults.products.length)
                ) && (
                  <div className={s.result}>
                    {searching
                      ? 'Searching...'
                      : searchText.length > 0
                        ? 'No Result Found'
                        : 'Type to search'}
                  </div>
                )}
                {props.accessories ? getAccessoriesSearch()
                  : searchResults.map((result) => (
                    <>
                      <div
                        className={s.result}
                        onClick={(e) => {
                          e.preventDefault();
                          const serviceType = null;
                          const data = { noDataExists: true, service_slug: result.service_slug || result.slug };
                          if(result.id == 7194) {
                            autoUtmHistory(history).push("/miles-membership")
                          }else {
                            autoUtmHistory(history).push((generate({
                              city: props.city,
                              subCity: props.selectedRegion,
                              service: data,
                              brand: props.cardetails?.brand,
                              model: props.cardetails?.model,
                              fuel: props.cardetails?.fuel,
                            })), data);
                          }
                          searchClicked(props?.user?.id, props?.cardetails?.model?.id, searchText, result.name);
                          
                          if (refInput) {
                            refInput.value = result.name;
                            refInput.blur();
                          }
                          return;

                          try {
                            let allServices = [];
                            const keys = Object.keys(props.servicesDetails);
                            for (let i = 0; i < keys.length; i++) {
                              const subCats = props.servicesDetails[keys[i]];
                              const services = (subCats.map((subCat) => subCat.services)).flat();
                              allServices = [...allServices, ...services];
                            }
                            const serviceForResult = allServices
                              .find((serv) => serv.id === result.id);
                            const serviceTypeToSelect = props.servicesTypesList
                              .find((serviceType) => serviceType.id === serviceForResult.retail_service_type_id);
                            props.action(
                              setActiveServiceType(
                                serviceTypeToSelect,
                              ),
                            );
                            switchServiceType(serviceTypeToSelect, true);
                          } catch (e) {

                          }
                          if (refInput) {
                            refInput.value = result.name;
                            refInput.blur();
                          }
                        }}
                      >
                        {result.name}
                      </div>
                    </>
                  ))}
              </div>
            </div>
            <img
              loading="lazy"
              src="https://cdn.zeplin.io/5da0780780829f083448125a/assets/7E39ECAC-D0C0-4F40-9668-A3CA1EDF2706.svg"
              alt="Gomechanic"
              className={s.searchIcon}
              onClick={() => {
                const tag = encodeURIComponent(searchText.trim());
                if (tag && props.accessories) {
                  autoUtmHistory(history).push(`/accessories/tags/${tag}`);
                }
              }}
            />
          </div>
        )}

        <div>
          {/* <div className={classNames(s.itemName, s.itemSpacing)}>
          <Link to="/careers">
                Careers
            </Link>
          </div> */}
          {/* {!props.isMalaysiaRequest ? 
          <div className={classNames(s.itemName, s.itemSpacing)}>
            <a
              style={{
                textDecoration: 'none',
                color: '#FFF',
              }}
              href={
                props.isNigerianRequest ? 'null' : '/spares/'
              }
              target="_blank"
            >
              <p style={{ margin: 0, fontSize: props.logoShort ? '1rem' : null }}>Spares</p>
            </a>
          </div>
          : null} */}
          <div className={classNames(s.itemName, s.itemSpacing)}>
            <a
              style={{
                textDecoration: 'none',
                color: '#FFF',
              }}
              href={
                props.isNigerianRequest ? 'https://gomechanic.in/blog' : '/blog'
              }
              target="_blank"
            >
              <p style={{ margin: 0, fontSize: props.logoShort ? '1rem' : null }}>Blog</p>
            </a>
          </div>
          <div className={classNames(s.itemName, s.itemSpacing)}>
            <DropDownMenu parentString="More" style={props.logoShort ? { fontSize: '1rem' } : null}>
              <a className={s.dropDownItem} href="/faq">
                <div className={classNames(s.itemName, s.itemNameBlack)}>
                  FAQ
                </div>
              </a>
              <a className={s.dropDownItem} href="/contact-us">
                <div className={classNames(s.itemName, s.itemNameBlack)}>
                  Contact Us
                </div>
              </a>
              <a className={s.dropDownItem} href="/terms">
                <div className={classNames(s.itemName, s.itemNameBlack)}>
                  Terms
                </div>
              </a>
              <a className={s.dropDownItem} href="/privacy">
                <div className={classNames(s.itemName, s.itemNameBlack)}>
                  Privacy
                </div>
              </a>
              <a className={s.dropDownItem} href="/offers">
                <div className={classNames(s.itemName, s.itemNameBlack)}>
                  Offers
                </div>
              </a>
              <a className={s.dropDownItem} href="/reviews">
                <div className={classNames(s.itemName, s.itemNameBlack)}>
                  Reviews
                </div>
              </a>
            </DropDownMenu>
          </div>

          {/* <div className={classNames(s.itemName, s.itemSpacing)}>
            <p
              style={{
                margin: 0,
              }}
            >
              {' '}
              Offers
              {' '}
            </p>


            <div className={s.hoverOptions} style={{ marginLeft: '-60px',marginTop: '5px' }}>
              <div style = {{marginTop:'-31px'}}>
                <img loading='lazy' src={triangle} className={s.tImage} />
                <img loading='lazy' src={upper} className={s.image} />
              </div>
              {more.map(function(text) {
                return <p className={s.optionsText}>{text}</p>;
              })}
            </div>
          </div>

          <div className={classNames(s.itemName, s.itemSpacing)}>
            <p style={{ margin: 0 }}>More</p>

            <div
              className={s.hoverOptions}
              style={{ marginLeft: '-64px', marginTop: '5px' }}
            >
               <div style = {{marginTop:'-31px'}}>
                  <img loading='lazy' src={triangle} className={s.tImage} />
                  <img loading='lazy' src={upper} className={s.image} />
               </div>

              <div style={{ marginTop: '10px' }} />
                {more.map(function(text) {
                  return <div className={s.optionsText}>{text}</div>;
                })}
            </div>
          </div> */}

          <div
            onClick={
              props.user && props.user.is_logged_in
                ? () => {}
                : setShowLoginSidebar
            }
            className={classNames(s.loginSignupBtn)}
            style={props.logoShort ? { fontSize: '1rem' } : null}
          >
            {props.user && props.user.is_logged_in ? (
              <>
                <DropDownMenuNew parentString={props.user.name} style={props.logoShort ? { fontSize: '1rem' } : null}>
                  <a className={s.dropDownItem} href="/my-profile">
                    <div className={classNames(s.itemName, s.itemNameBlack)}>
                      Profile
                    </div>
                  </a>
                  <a className={s.dropDownItem} href="/goapp-money">
                    <div className={classNames(s.flexContainer, s.itemNameBlack)}>
                      <div className={s.subText}>GoApp Money</div>
                      <div className={s.goAppMoney}>{props.currency.symbol} {props.user && props.user.go_app_money}</div>
                    </div>
                  </a>
                  <a className={s.dropDownItem} href="/order-history">
                    <div className={classNames(s.itemName, s.itemNameBlack)}>
                      Order History
                    </div>
                  </a>
                  <a className={s.dropDownItem} href="/health-card">
                    <div className={classNames(s.itemName, s.itemNameBlack)}>
                      Health Card
                    </div>
                  </a>
                  <a className={s.dropDownItem} href="/my-cars">
                    <div className={classNames(s.itemName, s.itemNameBlack)}>
                      My Cars
                    </div>
                  </a>
                  <a className={s.dropDownItem} href="/manage-addresses">
                    <div className={classNames(s.itemName, s.itemNameBlack)}>
                      Manage Addresses
                    </div>
                  </a>
                  <a
                    className={s.dropDownItem}
                    onClick={() => {
                      localStorage.clear();
                      if (window.location.href.includes('/accessories')) window.location.href = '/accessories';
                      else window.location.href = '/';
                    }}
                  >
                    <div className={classNames(s.itemName, s.itemNameBlack)}>
                      Logout
                    </div>
                  </a>
                </DropDownMenuNew>
              </>
            ) : (
              <span>Login</span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DesktopHeaderMain);
