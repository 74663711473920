import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './css/main.css';

const LoginUpperBar = (props) => {
  useStyles(s);

  return (
    <div className={s.upperBarContainer} style={props.upperBarContainerStyles ? props.upperBarContainerStyles : {}}>
      {!props.noBackButton &&
      <div className={s.backButtonContainer}>
        <img loading='lazy'
             onClick={props.onBackClick}
             src="https://cdn.zeplin.io/5da0780780829f083448125a/assets/69BB3456-EB2C-4B6B-A1F0-DC9CA44A3B77.svg"
             alt="Back Click"
        />
      </div>
      }

      <div className={s.titleCarContainer}>
        <div>
          <div className={s.upperBarTitle}>{props.title}</div>
          <div className={s.upperBarSubtitle}>{props.subtitle}</div>
        </div>
        {props.logo && (
          <div className={s.carImageContainer}>
            <img loading='lazy'
              src="/icons/logo/48x48.png"
              className={s.carImage} alt="Brand"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginUpperBar;
