/**
 * Author: Patanjali Kumar <patanjalikumar@gomechanic.in>
 * Supervision : Mr. Chandra Chowdhury <chandrachowdhury@gomechanic.in>
 *
 * Please try to read code thoroughly before contacting for any query.
 *
 * React
 * Zeplin URL : https://app.zeplin.io/project/5da0780780829f083448125a/screen/5da07a54d2d3af23439013ad
 *
 * This file contains code for How Gomechanic Works section on Homapage.
 *
 * Copyright ©  2019-present GoMechanic, LLC. All rights reserved.
 *
 */
import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { connect } from 'react-redux';
import s from './css/main.css';
import GomechWorkItem from './GomechWorkItem';

const HowGomechWorks = (props) => {
  useStyles(s);
  return (
    <>
      <div className={s.container}>
        {props.how_gomech_works?.map((how_work, i) => (
          <GomechWorkItem
            key={how_work.title}
            number={i + 1}
            title={how_work.title}
            subtitle={how_work.subtitle}
            image={how_work.image}
            noLine={i === props.how_gomech_works.length - 1}
          />
        ))}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    action: (action) => dispatch(action),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HowGomechWorks);
