import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import { connect } from 'react-redux';
import s from '../components/variables.css';
import { setDeviceType } from '../actions/device';
import { deviceTypes } from '../_services/device';

const HideOnMobile = (props) => {
  useStyles(s);
  if (props.isCrawlerRequest) return <div className={s.hideOnMobile}>{props.children}</div>;

  return (
    <>
      {props.noParent && (props.device !== deviceTypes.MOBILE) && <>{props.children}</>}
      {!props.noParent && (
        <>
          {props.device !== deviceTypes.MOBILE ? (
            <div className={s.hideOnMobile} style={props.customStyle ? {...props.customStyle} : {}}>{props.children}</div>
          ) : null}
        </>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    setDeviceAction: (value) => dispatch(setDeviceType(value)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HideOnMobile);
