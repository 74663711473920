import React from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import classNames from 'classnames';
import s from './RedBarMiddleContent.css';

const RedBarMiddleContent = (props) => {
  useStyles(s);
  return (
    <>
      <div
        className={classNames(
          s.dFlex,
          s.flexRow,
          s.justifyCenter,
          s.redBg,
          props.shadow ? s.shadow : null,
        )}
        onClick={props.onClick}
      >
        <div 
            className={classNames(
            s.text,
            props.flex ?  s.flex : null,
          )} 
          style={props.style || {}}>
        <div>{props.symbol}{props.cart}</div>
        <div>{props.text}</div>
        </div>
      </div>
    </>
  );
};

export default RedBarMiddleContent;
