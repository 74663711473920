import HttpHeader from '../httpHeader';
import { Http } from '../../modules/http';
import Global from '../../global';

export const getHomepageBannerLvl2 = (cityId, carId, userCarId, lat, long) => {
  const Headers = new HttpHeader();
  const HttpHandler = new Http();
  const ApiManager = new Global();

  const headers = Headers.get_token_headers();
  const url = `${ApiManager.urls.api_get}v2/oauth/customer/offer-banners/?lat=${lat}&long=${long}&city_id=${cityId}&version=3&car_id=${carId}&user_car_id=${userCarId}`;
  return HttpHandler.get(url, headers);
};
