// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3KvS7{width:100px;height:100px}@-webkit-keyframes _3zyLj{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg);-webkit-transform-origin:bottom left;transform-origin:bottom left}to{-webkit-transform:rotate(1turn);transform:rotate(1turn);-webkit-transform-origin:bottom left;transform-origin:bottom left}}", ""]);
// Exports
exports.locals = {
	"loadingRotate": "_3KvS7",
	"rotation": "_3zyLj"
};
module.exports = exports;
