
const chatBotDisplay = (show = true) => {
  // This is direct DOM manipulation make sure, this doesnt run on re-renders.
  if (typeof document !== 'undefined') {
    if (show) {
      const chatbotStyle = document.querySelector('style[data-for="chatbot-control"]');
      if (chatbotStyle) {
        chatbotStyle.remove();
      }
    } else if (show === false) {
      const chatbotStyle = document.querySelector('style[data-for="chatbot-control"]');
      if (!chatbotStyle) {
        const style = document.createElement('style');
        style.setAttribute('data-for', 'chatbot-control');
        style.textContent = '#tars-widget-fab, .crisp-client{display: none !important;}';
        document.head.append(style);
      }
    }
  }
};

export default chatBotDisplay;
